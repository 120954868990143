import React from 'react';
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  useColorMode,
} from '@chakra-ui/react';
import UploaderBox from '../../../components/UploaderBox';

const CareersDetails2 = () => {
  const { colorMode } = useColorMode();

  return (
    <section>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex gap-4">
          <div className="lg:w-2/3 md:w-2/3 w-full pr-4">
            <h2 className="fsize34 md:fsize28 sm-fsize20 text-black cust-textColor font-semibold">
              Career Details Name
            </h2>
            <p className="fsize18 md-fsize18 sm-fsize14 text-black lg:mt-3 mt-1 font-medium cust-textColor">
              Career sub title
            </p>
            <p className="fsize18 md-fsize18 sm-fsize14 text-black lg:mt-3 mt-1 font-medium cust-textColor">
              Career sub title 2
            </p>
            <p className="fsize16 md-fsize16 sm-fsize12 font-medium italic mt-3 text-black textColor-light">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque molestiae officia reiciendis minima commodi sunt. Quam,
              quia. Dignissimos, ab dolorum.
            </p>
            <p className="fsize16 sm-fsize12 mt-3 text-black textColor-light">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque molestiae officia reiciendis minima commodi sunt. Quam,
              quia. Dignissimos, ab dolorum.
            </p>

            <div className="pt-2">
              <p className="fsize16 sm-fsize14 mt-3 font-semibold text-black cust-textColor">
                In this role, you will get:
              </p>
              <ul className="lg:ml-10 md:ml-10 ml-6 md: mt-2">
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
              </ul>
            </div>
            <div className="pt-2">
              <p className="fsize16 sm-fsize14 mt-3 font-semibold text-black cust-textColor">
                In this role, you will get:
              </p>
              <ul className="lg:ml-10 md:ml-10 ml-6 md: mt-2">
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
                <li className="mb-1 fsize14 sm-fsize12 font-normal text-black textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam,
                  id!
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'lg:w-1/3 md:w-1/3 w-full px-4 pb-4 border1 rounded-lg lg:mt-0 md:mt-0 mt-6 themeShadow theme-bg'
                : 'lg:w-1/3 md:w-1/3 w-full px-4 pb-4 border1 rounded-lg lg:mt-0 md:mt-0 mt-6 themeShadow theme-bg'
            }
          >
            <FormControl>
              <FormLabel
                mt={4}
                className="text-black cust-textColor sm-fsize14"
              >
                First Name*
              </FormLabel>
              <Input placeholder="First name" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Last Name*
              </FormLabel>
              <Input placeholder="Last name" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Phone*
              </FormLabel>
              <Input placeholder="Phone" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Email*
              </FormLabel>
              <Input placeholder="Email" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Location*
              </FormLabel>
              <Input placeholder="Location" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Linkedin Profile
              </FormLabel>
              <Input placeholder="Linkedin Profile" className="sm-fsize12" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Employee Referral Code
              </FormLabel>
              <Input
                placeholder="Employee Referral Code"
                className="sm-fsize12"
              />
            </FormControl>
            <div className="mt-4">
              <FormLabel
                fontWeight={500}
                className="text-black cust-textColor sm-fsize14"
              >
                Resume/CV*
              </FormLabel>
              <UploaderBox accept="image/*" />
            </div>
            <div className="mt-6 text-center">
              <Button
                className={
                  colorMode === 'light'
                    ? 'bg-primary clr-fff sm-fsize14 theme-btn themeShadow theme-bg'
                    : 'clr-fff sm-fsize14 theme-btn themeShadow theme-bg'
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersDetails2;
