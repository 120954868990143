import { useColorMode } from "@chakra-ui/react";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useSelector, useDispatch } from "react-redux/es/exports";
function TermsFormat({ pageName }) {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <section>
      <div
        className={
          colorMode === "light"
            ? "bg-primary minmaxh280px theme-bg"
            : "minmaxh280px"
        }
      >
        <div className="  h-full container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-5 ">
          <h2
            id="6698440258"
            className="dynamicStyle theme-textColor lg:text-3xl md:text-2xl text-xl text-white h-full font-semibold justify-center flex items-center clr-fff cust-textColor"
          >
            {data ? data["6698440258"] : "User Agreement"}
          </h2>
        </div>
      </div>

      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-5 ">
        <ReactQuill
          id="2694505665"
          theme="bubble"
          readOnly
          className="dynamicStyle sm-fsize14 textColor-light"
          value={data ? data["2694505665"] : "User Agreement"}
        />
        {/* <p
          // dangerouslySetInnerHTML={{
          //   __html: data ? data['2694505665'] : 'User Agreement',
          // }}
          id="2694505665"
          className="dynamicStyle sm-fsize14"
        ></p> */}
      </div>
    </section>
  );
}

export default TermsFormat;
