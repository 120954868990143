import { Button, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import FeatherIcon from 'feather-icons-react';

const AboutSection46 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:w-8/12 md:w-8/12 w-full mx-auto text-center mb-10">
        <p id="7257977815"
              className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor">
          {data ? data['7257977815'] : 'Lorem Ipsum'}
        </p>
        <p
        id="1131192814"
          className={
            colorMode === 'light'
              ? 'dynamicStyle fsize18 sm-fsize14'
              : 'dynamicStyle fsize18 sm-fsize14 textColor-light'
          }
        >
          {data ? data['1131192814'] : 'Lorem ipsum dolor sit amet.'}
        </p>
      </div>
      <div className="lg:flex md:flex sm-reverse gap-10">
        <div className="flex gap-4 lg:w-3/5 md:w-3/5 w-full relative">
          <div>
            <div className="flex gap-4 mb-8">
              <div className="w-3/12">
                <p id="1056772080"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor">
                  {data ? data['1056772080'] : 'Lorem Ipsum'}
                </p>
              </div>
              <div className="w-1/12">
                <FeatherIcon
                  icon="disc"
                  className="z-10 bg-fff cust-bgColor cust-textColor rounded-full "
                  size={26}
                />
              </div>
              <div className="w-8/12">
                <p
                 id="6763300952"
                 dangerouslySetInnerHTML={{
                   __html: data
                     ? data['6763300952']
                     : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                 }}
                  className={
                    colorMode === 'light'
                      ? 'dynamicStyle lineClam4 sm-fsize14'
                      : 'dynamicStyle lineClam4 sm-fsize14 textColor-light'
                  }
                >
                 
                </p>
              </div>
            </div>
            <div className="flex gap-4 mb-8">
              <div className="w-3/12">
                <p id="5633850994"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor">
                  {data ? data['5633850994'] : 'Lorem Ipsum'}
                </p>
              </div>
              <div className="w-1/12">
                <FeatherIcon
                  icon="disc"
                  className="z-10 bg-fff cust-bgColor cust-textColor rounded-full "
                  size={26}
                />
              </div>
              <div className="w-8/12">
                <p
                 id="7966649543"
                 dangerouslySetInnerHTML={{
                   __html: data
                     ? data['7966649543']
                     : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                 }}
                  className={
                    colorMode === 'light'
                      ? 'dynamicStyle lineClam4 sm-fsize14'
                      : 'dynamicStyle lineClam4 sm-fsize14 textColor-light'
                  }
                >
                 
                </p>
              </div>
            </div>
            <div className="flex gap-4 mb-8">
              <div className="w-3/12">
                <p id="6880372188"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor">
                  {data ? data['6880372188'] : 'Lorem Ipsum'}
                </p>
              </div>
              <div className="w-1/12">
                <FeatherIcon
                  icon="disc"
                  className="z-10 bg-fff cust-bgColor cust-textColor rounded-full "
                  size={26}
                />
              </div>
              <div className="w-8/12">
                <p
                 id="5283461815"
                 dangerouslySetInnerHTML={{
                   __html: data
                     ? data['5283461815']
                     : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                 }}
                  className={
                    colorMode === 'light'
                      ? 'dynamicStyle lineClam4 sm-fsize14'
                      : 'dynamicStyle lineClam4 sm-fsize14 textColor-light'
                  }
                >
                 
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-3/12">
                <p id="9435761408"
              className="dynamicStyle fsize20 sm-fsize16 font-semibold cust-textColor">
                  {data ? data['9435761408'] : 'Lorem Ipsum'}
                </p>
              </div>
              <div className="w-1/12">
                <FeatherIcon
                  icon="disc"
                  className="z-10 bg-fff cust-bgColor cust-textColor rounded-full "
                  size={26}
                />
              </div>
              <div className="w-8/12">
                <p
                 id="9294758570"
                 dangerouslySetInnerHTML={{
                   __html: data
                     ? data['9294758570']
                     : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                 }}
                  className={
                    colorMode === 'light'
                      ? 'dynamicStyle lineClam4 sm-fsize14'
                      : 'dynamicStyle lineClam4 sm-fsize14 textColor-light'
                  }
                >
                 
                </p>
              </div>
            </div>
          </div>
          <div className="about46-vert-line md-about46-vert-line sm-about46-vert-line"></div>
        </div>
        <div className="lg:w-2/5 md:w-2/5 w-full flex items-center">
          <div id="5698585037"
            className="dynamicStyle2 h-350px w-full">
            <Image
              src={data ? getImage(data['5698585037']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-350px w-full rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection46;
