import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
  Stack,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Multiselect from 'multiselect-react-dropdown';

import axios from 'axios';
import {
  getFormSchema,
  getSchemaByFormId,
  getInputByFormId,
} from '../../../../redux/forms/forms-action';
import { useDispatch } from 'react-redux';
import { Field } from 'formik';
import { object } from 'yup';
import { forEach, values } from 'lodash';
import form from '../../../../apis/client/Forms/form';
import { useForm, Controller } from 'react-hook-form';
const FormSection1 = ({ pageName, formId, close, edit }) => {
  const dispatch = useDispatch();
  const defaultValues = { 'multiselect dropdown': [] };
  const [formFields, setForm] = useState({});
  const [change, setChange] = useState(false);
  const [getallField, setFormData] = useState('');
  const [getSchemaForm, setGetSchemaForm] = useState('');
  const [formValidation, setFormValidation] = useState({});
  const [value, setValues] = React.useState('1');
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    formFields,
  });
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const sectionName = useSelector(state => state.cms.sectionNames[pageName]);
  // let getallField = useSelector((state) => state.Forms.FormInput);
  let getFormName = useSelector(state => state.Forms.Forms);
  // let getSchemaForm = useSelector((state) => state.Forms.FormSchemaByFormId);

  let userToken = useSelector(state => state.AuthDetails.user.token);
  const [selectValue, setSelectvalue] = useState([]);

  const [options] = useState([
    { name: 'Option 1️⃣', id: 1 },
    { name: 'Option 2️⃣', id: 2 },
  ]);

  const onSelect = (selectedList, field) => {
    // Handle the select event
    let fields = { ...formFields, [field]: selectedList };
    setForm(fields);
    setValue(field, selectedList);
  };

  const onRemove = (selectedList, field) => {
    // Handle the remove event
    let fields = { ...formFields, [field]: selectedList };
    setForm(fields);
    setValue(field, selectedList);
  };

  console.log(getallField, selectValue, 'getAllField');
  useEffect(() => {
    async function mount() {
      if (formId) {
        let payload = {
          formId: formId,
        };

        let formDetails = await dispatch(getInputByFormId(payload));
        setFormData(formDetails);
        let formSchema = await dispatch(getSchemaByFormId(payload));
        setGetSchemaForm(formSchema);
      } else {
        let formSection = sectionName.filter(x => x.sectionId === '4457530739');

        if (formSection.length && formSection[0].form) {
          let payload = {
            formId: formSection[0].form,
          };

          let formDetails = await dispatch(getInputByFormId(payload));
          setFormData(formDetails);
          let formSchema = await dispatch(getSchemaByFormId(payload));
          setGetSchemaForm(formSchema);
        }
      }
    }
    mount();
  }, [dispatch]);
  console.log(formFields, getSchemaForm, 'from value');
  //getallField[0].field.value,

  useEffect(() => {
    setValidations();
  }, [getallField]);

  const handleClear = () => {
    if (getallField) {
      let tempForm = {};
      getallField.map(x => {
        if (x.inputType != 'button') tempForm[x.value] = '';
      });
      setForm(tempForm);
    }
  };

  const onSubmit = async () => {
    let result = await trigger();
    if (result) {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      payload = { ...formFields, ...payload };

      let key = Object.keys(payload);
      let value = Object.values(payload);
      let formData = new FormData();

      console.log(payload, 'cloudpl');
      for (let i = 0; i < key.length; i++) {
        if (value[i].type && value[i].type === 'files') {
          for (let j = 0; j < value[i].files.length; j++) {
            formData.append(key[i], value[i].files[j]);
          }
        } else {
          formData.append(key[i], value[i]);
        }
      }

      let output = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
        formData,

        {
          headers: {
            'community-name': process.env.REACT_APP_COMMUNITY_NAME,
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (output && output.data) {
        handleClear();
        close(false);
      }
    }
  };

  const handleChange = async (e, field, item) => {
    let fields;
    console.log(item);
    if (item === 'file') {
      fields = { ...formFields, [field]: e.target.files[0] };
    } else if (item === 'files') {
      fields = {
        ...formFields,
        [field]: { type: 'files', files: e.target.files },
      };
    } else if (item === 'radio') {
      console.log(e, 'radio');
      setValue(field, e);
      fields = { ...formFields, [field]: e };
    } else if (item === 'checkbox') {
      console.log(e);
      setValue(field, e);
      fields = { ...formFields, [field]: e };
      // console.log('ENtered else');
    } else {
      fields = { ...formFields, [field]: e.target.value };
      // console.log('ENtered else');
    }

    setForm(fields);
  };

  // const initializeData = async () => {
  //   if (edit && Object.keys(edit).length) {
  //     let payload = {
  //       projectId: process.env.REACT_APP_ID,
  //       projectName: process.env.REACT_APP_COMMUNITY_NAME,
  //       _id: edit._id,
  //     };
  //     let formData = formFields;
  //     let keys = Object.keys(formData);
  //     let data = await dispatch(getData(edit.form, payload));
  //     console.log(keys, 'edit detail');
  //     if (data && data.result && data.result.length) {
  //       for (let i = 0; i < keys.length; i++) {
  //         formData[keys[i]] = data.result[0][keys[i]];
  //       }

  //       setForm(formData);
  //     }
  //   }
  // };

  const setValidations = async () => {
    console.log(getallField, 'fields');
    let tempValidation = {};
    if (getallField.length) {
      getallField.forEach(field => {
        if (field.inputType != 'button') {
          let options = {};
          if (field?.mandatoryField)
            options['required'] = {
              value: field?.mandatoryField,
              message: `${field.value} is required`,
            };

          if (field?.maxLength)
            options['maxLength'] = {
              value: field?.maxLength,
              message: `${field.value} must contain maximum ${field?.maxLength} characters`,
            };

          if (field?.minLength)
            options['minLength'] = {
              value: field?.minLength,
              message: `${field.value} must contain minimum ${field?.minLength} characters`,
            };

          if (field?.inputType === 'email')
            options['pattern'] = {
              value: /^\S+@\S+$/i,
              message: `Invalid email id`,
            };

          if (field?.pattern)
            options['pattern'] = {
              value: field?.pattern,
              message: `Invalid email id`,
            };

          tempValidation[field.value] = {
            ...register(field.value, options),
          };
        }
      });
      console.log(tempValidation, 'fields val');
      setFormValidation(tempValidation);
    }
  };

  console.log(formFields, 'default');
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5  lg:py-12 md:py-10 py-8">
        <div className="text-center mb-8">
          <h2
            id="3532025242"
            className=" fsize30 sm-fsize16 font-semibold text-primary"
          >
            {getSchemaForm &&
            getSchemaForm.length &&
            getSchemaForm[0].formId &&
            getSchemaForm[0].formId.formName
              ? getSchemaForm[0].formId.formName
              : ''}
          </h2>
        </div>

        <div>
          <div className="lg:w-3/5 m-auto lg:grid md:grid grid-cols-2 gap-4 ">
            {getallField
              ? getallField.map((item, index) => {
                  return (
                    <div className="lg:pb-0 md:pb-0 pb-3 ">
                      {!['button', 'mappedTable', 'notessystem'].includes(
                        item.inputType
                      ) && (
                        <p
                          id="1437369070"
                          className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                        >
                          {item.label}
                        </p>
                      )}
                      {item.inputType == 'input' && (
                        <>
                          <Input
                            className="p-2 inp-bdr w-full bg-white"
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType == 'multiline' && (
                        <>
                          <Textarea
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType == 'phone' && (
                        <>
                          <Input
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType == 'email' && (
                        <>
                          <Input
                            type="email"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType == 'url' && (
                        <>
                          <Input
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType === 'singleselect' && (
                        <>
                          <Select
                            placeholder={item.placeholder}
                            value={formFields[item.value]}
                            className="fsize14"
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value);
                            }}
                            id={`${item.value}-field`}
                          >
                            {item.enum.map(i => {
                              return <option value={i}>{i}</option>;
                            })}
                          </Select>
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType === 'radiobutton' && (
                        <>
                          <Controller
                            name={item.value}
                            control={control}
                            rules={{ ...formValidation[item.value] }}
                            render={() => (
                              <RadioGroup
                                onChange={e => {
                                  console.log('radio');
                                  handleChange(e, item.value, 'radio');
                                }}
                                value={formFields[item.value]}
                              >
                                <Stack direction="row">
                                  {item.enum.map(e => {
                                    return <Radio value={e}>{e}</Radio>;
                                  })}

                                  {/* <Radio value="2">Second</Radio>
                              <Radio value="3">Third</Radio> */}
                                </Stack>
                              </RadioGroup>
                            )}
                          />

                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType === 'imagefile' && (
                        <>
                          <input
                            type="file"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'file');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.inputType === 'file' && (
                        <>
                          <input
                            type="file"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'file');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'profilepic' && (
                        <>
                          <input
                            type="file"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'file');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'multipleimagefile' && (
                        <>
                          <input
                            type="file"
                            multiple
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'files');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'multiselect' && (
                        <>
                          <Controller
                            name={item.value}
                            control={control}
                            rules={{ ...formValidation[item.value] }}
                            render={() => (
                              <Multiselect
                                className="w-full"
                                options={item.enum.map((select, index) => {
                                  return { name: select, id: index };
                                })} // Options to display in the dropdown
                                selectedValues={selectValue} // Preselected value to persist in dropdown
                                onSelect={e => onSelect(e, item.value)} // Function will trigger on select event
                                onRemove={e => onRemove(e, item.value)} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                id={`${item.value}-field`}
                              />
                            )}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'date' && (
                        <>
                          <Input
                            type="date"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'files');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'date&time' && (
                        <>
                          <Input
                            type="datetime-local"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'files');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {item.inputType === 'checkbox' && (
                        <>
                          <Controller
                            name={item.value}
                            control={control}
                            rules={{ ...formValidation[item.value] }}
                            render={() => (
                              <CheckboxGroup
                                defaultValue={[]}
                                onChange={e => {
                                  handleChange(e, item.value, 'checkbox');
                                }}
                                id={`${item.value}-field`}
                              >
                                <Stack
                                  spacing={[1, 10]}
                                  direction={['column', 'row']}
                                >
                                  {item.enum.map(check => {
                                    return (
                                      <Checkbox value={check}>{check}</Checkbox>
                                    );
                                  })}
                                </Stack>
                              </CheckboxGroup>
                            )}
                          />

                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}

                          {/* <CheckboxGroup defaultValue="select">
                              <Stack
                                spacing={[1, 7]}
                                direction={['column', 'row']}
                              >
                                <Checkbox>check</Checkbox>
                                <Checkbox>check</Checkbox>
                                <Checkbox>check</Checkbox>
                              </Stack>
                            </CheckboxGroup> */}
                        </>
                      )}
                      {/* {item.inputType == 'button' && (
                          <Input
                            type="button"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={item.value}
                            maxlength={item.maxLength}
                            minlength={item.minLength}
                            id={`${item.value}-field`}
                          />
                        )} */}
                      {item.inputType === 'time' && (
                        <>
                          <Input
                            type="time"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            {...formValidation[item.value]}
                            onChange={e => {
                              handleChange(e, item.value, 'files');
                            }}
                            id={`${item.value}-field`}
                          />
                          {errors[item.value] && (
                            <span
                              className="fsize12 text-danger"
                              id={`${item.value}-validation-error`}
                            >
                              {errors[item.value]?.message}
                            </span>
                          )}
                        </>
                      )}

                      {/* {item.inputType == 'notessystem' && (
                          <>
                            <Textarea
                              className="p-2 inp-bdr w-full"
                              placeholder={item.placeholder}
                              {...formValidation[item.value]}
                              onChange={e => {
                                handleChange(e, item.value);
                              }}
                              id={`${item.value}-field`}
                            />
                            {errors[item.value] && (
                              <span
                                className="fsize12 text-danger"
                                id={`${item.value}-validation-error`}
                              >
                                {errors[item.value]?.message}
                              </span>
                            )}
                          </>
                        )} */}
                    </div>
                  );
                })
              : ''}
          </div>

          {getallField
            ? getallField.map(item => {
                return (
                  <div className=" text-center">
                    {item.inputType == 'button' && (
                      <Button
                        className=" font-semibold bg-da8f2c py-2 px-4 mt-6"
                        type="submit"
                        id={`submit-form-${getSchemaForm[0]?.schemaName}`}
                        onClick={() => onSubmit()}
                      >
                        {item.label}
                      </Button>
                    )}
                  </div>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </section>
  );
};

export default FormSection1;
