import { Button, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection41 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');

  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="bg-secondary">
      <div className="">
        <div className="lg:flex md:flex sm-reverse">
          <div className="lg:w-7/12 md:w-6/12 w-full">
            <p
              id="3635107156"
              className="dynamicStyle fsize36 md-fsize24 sm-fsize20 font-semibold clr-fff lg:px-12 md:px-12 px-5 lg:py-12 lg:py-8 py-6"
            >
              {data ? data['3635107156'] : 'Lorem ipsum dolor sit amet'}
            </p>
            <div className="about41">
              <Tabs isFitted className="clr-fff">
                <TabList mb="1em" className="tablist-border sm-mb-0">
                  <Tab
                    id="6661690003"
                    className="dynamicStyle bdr-right-d9d0d0"
                  >
                    {data ? data['6661690003'] : 'Lorem'}
                  </Tab>
                  <Tab id="5318398245"
                    className="dynamicStyle" >{data ? data['5318398245'] : 'Lorem'}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <p className="lg:px-8 md:px-6">
                      Our Mission "Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Sed corrupti quam alias possimus totam,
                      natus excepturi praesentium nobis consequuntur aliquid
                      placeat similique adipisci, nihil quidem officia, modi
                      voluptatibus dicta dolor."
                    </p>
                  </TabPanel>
                  <TabPanel>
                    <p className="lg:px-8 md:px-6">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sed corrupti quam alias possimus totam, natus excepturi
                      praesentium nobis consequuntur aliquid placeat similique
                      adipisci, nihil quidem officia, modi voluptatibus dicta
                      dolor.
                    </p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
          <div
            id="2579223440"
            className="dynamicStyle2 lg:w-5/12 md:w-6/12 w-full"
          >
            <Image
              src={data ? getImage(data['2579223440']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="w-full h-600px sm-h-250px object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection41;
