import { Button, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useSelector } from 'react-redux/es/exports';

const Team14 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);
  return (
    <div 
    className={
        colorMode === 'light'
          ? 'bg-secondary theme-bg'
          : 'theme-bg'
      }>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <div className="lg:flex md:flex items-center gap-10">
          <div className="lg:w-1/5 md:w-1/5 w-full">
            <div id="0199467272" className="dynamicStyle2 lg:h-52 lg:w-52 md:w-32 md:h-32 w-28 h-28 mx-auto mb-4">
              <Image
                src={data ? getImage(data['0199467272']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="w-full h-full rounded-full"
              />
            </div>
            <p id="4460342497"
          className="dynamicStyle cust-textColor theme-textColor fsize20 md-fsize18 font-medium clr-fff pb-2 text-center">
              {data ? data['4460342497'] : 'lorem ipsum'}
            </p>
            <div className="clr-fff theme-textColor flex justify-center gap-4">
              <FeatherIcon
                icon="linkedin"
                size={20}
                className="cursor-pointer"
              />
              <FeatherIcon
                icon="twitter"
                size={20}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="lg:w-3/5 md:w-3/5 w-full cust-textColor text-center lg:py-0 md:py-0 py-8">
            <h3 id="9052421694"
          className="dynamicStyle fsize40 md-fsize26 sm-fsize20 font-semibold clr-fff theme-textColor text-center pb-4">
              {data ? data['9052421694'] : 'lorem ipsum'}
            </h3>
            <p id="4382491530"
            dangerouslySetInnerHTML={{
                __html: data ? data['4382491530'] : 'no-data',
              }}
            className=' dynamicStyle textColor-light theme-textColor clr-fff sm-fsize14 lg:w-1/2 w-full mx-auto'>
             
            </p>
            <Button
            variant="outline"
            className="theme-radiusfull mt-6 cust-textColor fsize14 sm-fsize12 theme-btn buttonAnimate bdr-2px hvr-fff clr-fff"
            size="lg"
            id="9318549936"
          >
            <span
              onClick={() => handleClick('9318549936')}
            >
             {data ? data['9318549936'] : 'Button'}
            </span>
          </Button>
          </div>
          <div className="lg:w-1/5 md:w-1/5 w-full">
            <div className="lg:h-52 lg:w-52 md:w-32 md:h-32 w-28 h-28 mx-auto mb-4">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full h-full rounded-full"
              />
            </div>
            <p id="7856408819"
            className="dynamicStyle cust-textColor theme-textColor fsize20 md-fsize18 font-medium clr-fff pb-2 text-center">
              {data ? data['7856408819'] : 'lorem ipsum'}
            </p>
            <div className="clr-fff theme-textColor flex justify-center gap-4">
              <FeatherIcon
                icon="linkedin"
                size={20}
                className="cursor-pointer"
              />
              <FeatherIcon
                icon="twitter"
                size={20}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team14;
