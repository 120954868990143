import { Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection40 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');

  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-4 lg:py-16 md:py-16 py-8">
        <div className="lg:flex md:flex items-center lg:py-10 md:py-5 py-5 lg:pl-10 md:pl-5 pl-4 lg:pr-0 md:pr-0 pr-4 border-primary rounded-md">
          <div className="lg:w-7/12 md:w-7/12 w-full lg:pr-10 md:pr-5 pr-0">
            <div className="">
              <h5
                id="9330040048"
                className="dynamicStyle text-dark fsize34 cust-textColor sm-fsize20 md-fsize22 font-semibold"
              >
                {data ? data['9330040048'] : 'no-data'}
              </h5>
              <p
                id="2141823330"
                className="dynamicStyle fsize14 sm-fsize14 lineClamp3 textColor-light md-fsize14 mt-2 text-gray"
              >
                {data ? data['2141823330'] : 'no-data'}
              </p>
              <div className="lg:flex md:flex items-center justify-around gap-4 mt-8">
                <div >
                  <div id="6700498815" className="dynamicStyle2">
                  <Image
                    className="h-16 w-16 rounded-full mx-auto"
                    src={data ? getImage(data['6700498815']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                    />
                    </div>
                  <p
                    id="7891090984"
                    className="dynamicStyle fsize22 font-semibold md-fsize20 sm-fsize18 textColor-light md-fsize14 mt-3 text-dark text-center"
                  >
                    {data ? data['7891090984'] : 'no-data'}
                  </p>
                  <p
                    id="4178089656"
                    className="dynamicStyle fsize14 textColor-light md-fsize14 mt-1 text-gray text-center"
                  >
                    {data ? data['4178089656'] : 'no-data'}
                  </p>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-4">
                <div id="7942490143" className="dynamicStyle2">
                  <Image
                    className="h-16 w-16 rounded-full mx-auto"
                    src={data ? getImage(data['7942490143']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                  </div>
                  <p
                    id="6985962579"
                    className="dynamicStyle fsize22 font-semibold md-fsize20 sm-fsize18 textColor-light md-fsize14 mt-3 text-dark text-center"
                  >
                    {data ? data['6985962579'] : 'no-data'}
                  </p>
                  <p
                    id="0046416845"
                    className="dynamicStyle fsize14 textColor-light md-fsize14 mt-1 text-gray text-center"
                  >
                    {data ? data['0046416845'] : 'no-data'}
                  </p>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-4">
                <div id="3008591417" className="dynamicStyle2">
                  <Image
                    className="h-16 w-16 rounded-full mx-auto"
                    src={data ? getImage(data['3008591417']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                  </div>
                  <p
                    id="6518124073"
                    className="dynamicStyle fsize22 font-semibold md-fsize20 sm-fsize18 textColor-light md-fsize14 mt-3 text-dark text-center"
                  >
                    {data ? data['6518124073'] : 'no-data'}
                  </p>
                  <p
                    id="9859121973"
                    className="dynamicStyle fsize14 textColor-light md-fsize14 mt-1 text-gray text-center"
                  >
                   {data ? data['9859121973'] : 'no-data'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-5/12 w-full lg:mt-0 md:mt-0 mt-4">
            <div id="1562786983" className="dynamicStyle2">
              <Image
                className="h-400px mdh-300px sm-h-250px w-full"
                src={data ? getImage(data['1562786983']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection40;
