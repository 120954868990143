import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DownloadSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="">
      <div className="container mx-auto lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex items-center w-full">
          <div id='5774799724' className="dynamicStyle lg:w-1/2 md:w-1/2 w-full">
            {data && data['5774799724']
              ? data['5774799724'].map(e => {
                  return (
                    <div className="lg:mb-12 md:mb-12 mb-6">
                      <h2 className="mb-2 fsize34 md-fsize24 sm-fsize20 font-semibold text-black cust-textColor">
                      {e.field1}
                      </h2>
                      <p
                       dangerouslySetInnerHTML={{
                        __html: e.field2,
                      }} className="lg:w-3/4 text-black fsize14 md-fsize14 sm-fsize14 textColor-light">
                        
                      </p>
                      <div className="lg:mt-6 md:mt-6 mt-4 flex gap-4 items-center">
                        <Button className="bdr-2px theme-btn">{e.field3}</Button>
                        <p className="fsize14 font-medium cust-textColor cursor-pointer">
                        {e.field4}
                        </p>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
          <div
            id="4084707970"
            className="dynamicStyle2 lg:mt-0 md:mt-0 mt-8 lg:w-1/2 md:w-1/2 w-full lg:pl-12 md:pl-8"
          >
            <Image
              src={data ? getImage(data['4084707970']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt=""
              className="w-full h-550px md-h-450px sm-h-350px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadSection1;
