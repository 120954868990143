import { Button, Card, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection45 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');

  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-4 lg:py-16 md:py-16 py-8">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-5 md:pr-2">
            <h5
              id="1446299267"
              className="dynamicStyle text-dark fsize36 cust-textColor sm-fsize20 md-fsize22 font-semibold"
            >
              {data ? data['1446299267'] : 'no-data'}
            </h5>
            <p
              id="1735953414"
              className="dynamicStyle fsize18 sm-fsize14 lineClamp3 textColor-light md-fsize16 mt-2 text-gray"
            >
              {data ? data['1735953414'] : 'no-data'}
            </p>
            <div
              className="mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-8 md:gap-4 gap-4 dynamicStyle"
              id="7903609975"
            >
              {data && data['7903609975']
                ? data['7903609975'].map(e => {
                    return (
                      <Card className="lg:py-8 md:py-4 md:px-5 py-5 px-4 lg:px-12 cust-shadow cust-bgColor">
                        <h6 className="fsize32 md-fsize22 sm-fsize18 text-primary text-center font-semibold cust-textColor">
                          {e.field1}
                        </h6>
                        <p className="mt-2 fsize20 md-fsize16 sm-fsize14 text-gray text-center font-medium textColor-light">
                          {e.field2}
                        </p>
                      </Card>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex lg:pl-5 md:pl-2 w-full lg:mt-0 md:mt-0 mt-10">
            <div id="7497822953" className="dynamicStyle2">
              <Image
                className="h-580px md-h-450px w-full rounded-lg"
                src={data ? getImage(data['7497822953']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection45;
