import { Button } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
const Services5 = ({ pageName }) => {
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const data = useSelector(state => state.cms.allSections[pageName]);
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="py-5 container mx-auto lg:px-12 md:px-8 px-5">
      <div className="lg:w-3/5 md:w-3/4 mx-auto text-center w-full flex flex-col justify-center lg:pt-8 md:pt-8  lg:mb-12 md:mb-12 mb-8 ">
        <h3
          id="6256745993"
          className="dynamicStyle fsize16 md-fsize14 sm-fsize16 font-semibold text-primary cust-textColor"
        >
          {data ? data['6256745993'] : 'Developers'}
        </h3>

        <h1
          id="3967754473"
          className="dynamicStyle lg:w-3/5 md:w-3/5 w-full mx-auto fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor mb-2"
        >
          {data ? data['3967754473'] : 'Improve performance with cleaner code'}
        </h1>
        <div className="">
          <p
            id="4606371046"
            className="dynamicStyle lg:w-3/5 md:w-3/5 w-full mx-auto cust-textColor fsize16 md-fsize14 sm-fsize14 text-center"
          >
            {data
              ? data['4606371046']
              : 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nihil voluptatem cupiditate'}
          </p>
        </div>
        <Button
          fontWeight={'500'}
          background={'none'}
          _hover={'none'}
          outline={'0px'}
          className="dynamicStyle font-medium theme-btn lg:my-6 md:my-6 my-4 text-primary cust-textColor sm-fsize14 theme-btn buttonAnimate"
          id="7444293727"
      >
          {' '}
          <span
            className="flex items-center"
            onClick={() => handleClick('7444293727')}
          >
            Explore tools and SDKs
            <FeatherIcon
              className="text-primary cust-textColor ml-3 hvr-fff"
              size={18}
              icon="arrow-right"
            />
          </span>
        </Button>
        <div id="2005654466" className="dynamicStyle2">
          <Image
            src={data ? getImage(data['2005654466']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="w-full h-450px md-h-350px sm-h-250px"
          />
        </div>
      </div>
    </div>
  );
};

export default Services5;
