import { Card, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';

const Testimonial16 = ({ pageName }) => {
  const [currenttestimonial, settestimonials] = useState({});
  const dispatch = useDispatch();

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  const data = useSelector(state => state.cms.allSections[pageName]);

  useEffect(() => {
    // dispatch(getTestimonal());
    dispatch(getTestimonal());
    settestimonials(getAllTestimonial.data.result[0]);
  }, []);
  return (
    <div className="container mx-auto lg:py-16 md:py-12 lg:px-12 md:px-8 py-8 px-5">
      <div className="text-center mx-auto ">
        <div className="lg:pb-10 md:pb-10 pb-4">
          <h3
            id="2522474431"
            class="dynamicStyle fsize36 sm-fsize22 font-semibold pb-2 cust-textColor text-black"
          >
            {data ? data['2522474431'] : 'What our clients says'}
          </h3>
          <p
            id="5052574322"
            className="dynamicStyle text-gray sm-fsize14 mx-auto lg:w-8/12 textColor-light"
            dangerouslySetInnerHTML={{
              __html: data ? data['5052574322'] : 'no-data',
            }}
          >
          </p>
        </div>
        <div className="lg:w-7/12 md:w-9/12 mx-auto mt-4">
          <Card className="cust-shadow lg:px-4 lg:py-8 md:px-4 md:py-6 px-2 py-4">
            <p className="text-black pb-4 fsize16 sm-fsize14 textColor-light">
              {currenttestimonial.testimonial}
            </p>
            <p className="font-semibold fsize20 md-fsize18 sm-fsize16 cust-textColor text-black">
              {currenttestimonial.name}
            </p>
          </Card>
          <div className="lg:pt-10 md:pt-6 pt-4 lg:w-1/2 md:w-8/12 w-full mx-auto">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              // effect={'coverflow'}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: '1000',
              }}
              // coverflowEffect={{
              //   rotate: -15,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 1,
              //   slideShadows: false,
              // }}
              disableOnInteraction={true}
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                767: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
              }}
            >
              {getAllTestimonial &&
              getAllTestimonial.data &&
              getAllTestimonial.data.result &&
              getAllTestimonial.data.result.length > 0
                ? getAllTestimonial.data.result.map((testimonial, index) => {
                    return (
                      <SwiperSlide onClick={() => settestimonials(testimonial)}>
                        <Image
                          className={
                            currenttestimonial._id === testimonial._id
                              ? 'lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full cursor-pointer borderwid border-primary'
                              : 'lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full cursor-pointer'
                          }
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                          alt="testimonial-image"
                        />
                      </SwiperSlide>
                    );
                  })
                : 'No Data'}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial16;
