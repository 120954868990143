import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection4 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const data = useSelector(state => state.cms.allSections[pageName]);
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);

  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:w-3/5 md:w-4/5 w-full mx-auto text-center">
        <h3 id="3796575135"
          className="dynamicStyle text-primary fsize22 sm-fsize16 font-semibold">
          {data
            ? data['3796575135']
            : 'Lorem Ipsum'}
        </h3>
        <p id="9396210770"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold py-3 text-black cust-textColor">
          {data
            ? data['9396210770']
            : 'Lorem Ipsum'}
        </p>
        <p dangerouslySetInnerHTML={{
                  __html: data ? data['9479444568'] : 'no-data',
                }}
                id="9479444568"
                className="dynamicStyle text-gray textColor-light">
          
        </p>
        <InputGroup size="lg" width="" className="mx-auto my-6 lg:w-4/5 w-full">
          <Input pr="4.5rem" placeholder="Enter your email address" />
          <InputRightElement width="8rem" className="">
            <Button
              h="2rem"
              size="md"
              mr="0.2rem"
              id="8163489221"
                className="dynamicStyle bg-primary clr-fff fsize16"
            >
              {data ? data['8163489221'] : 'Submit'}
            </Button>
          </InputRightElement>
        </InputGroup>
        <p dangerouslySetInnerHTML={{
                  __html: data ? data['6780829985'] : 'no-data',
                }}
                id="6780829985"
                className="dynamicStyle text-gray sm-fsize12 textColor-light">
         
        </p>
      </div>
    </div>
  );
};

export default NewsletterSection4;
