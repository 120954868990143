import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Badge,
  Card,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  getAllBlogs,
  getBlogs,
  getAllCategory,
  setSelectedBlog,
  getBlogsLoadMore,
  setBlogsLoadMore,
} from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { handleScroll } from './fadeeffect/FadeEffect';
import FeatherIcon from 'feather-icons-react';

    const CONSTANT_PER_PAGE = 9;
const CONSTANT_PAGE = 0;

const Blogs4 = ({ isSideBarOpen, pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  let BlogsLoadMore = useSelector(state => state.BlogDetails.BlogsLoadMore);
  // console.log(Blogs, "checkvalue");
  let allCategory = useSelector(state => state.BlogDetails.Category);
  console.log(allCategory, 'cate');

  const [page, setPage] = useState(CONSTANT_PAGE);
  const [perPage, setPerPage] = useState(CONSTANT_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [
    page,
    // perPage,
    selectedCategory,
  ]);

  const fetchBlogs = async () => {
    let payload = {
      isActive: true,
      offset: page,
      limit: perPage,
    };
    if (selectedCategory && selectedCategory !== 'all') {
      Object.assign(payload, { categoryId: [selectedCategory] });
    }
    let data = await dispatch(getBlogsLoadMore(payload));
    if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
      if (page === 0) {
        dispatch(setBlogsLoadMore(data.data));
      } else {
        dispatch(
          setBlogsLoadMore({
            ...data.data,
            result: [...BlogsLoadMore.result, ...data.data.result],
          })
        );
      }
    } else {
      dispatch(setBlogsLoadMore({}));
    }
    // console.log(Blogs);
    // setFilteredPosts(Blogs)
  };

  const handleLoadMore = () => {
    // setPerPage(perPage + CONSTANT_PER_PAGE);
    setPage(state => state + 1);
  };

  const handleBlogDetail = blogId => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className=" relative zindex9 container mx-auto  lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="flex justify-between items-center">
            <div>
            <p id="8458264889" class="dynamicStyle fsize16 md-fsize16 sm-fsize14 cust-textColor">{data ? data['8458264889'] : 'You already know ?'}</p> 
          <h2
            id="2398497672"
            className="dynamicStyle mt-2 cust-textColor lg:text-3xl text-end md:text-2xl text-xl text-primary font-semibold"
          >
            {data ? data['2398497672'] : 'Useful Pet Knowledge'}
          </h2>

          </div>
          <Button
              variant="outline"
              colorScheme="primary"
              borderRadius={"9999px"}
              className="text-primary sm-fsize12 lg:mt-8 md:mt-8 dynamicStyle theme-radiusfull cust-textColor buttonAnimate fsize14 theme-btn"
              size="md"
              fontWeight={"500"}
              id="5289694416"
            >
              <span
                className="flex items-center"
                onClick={() => handleClick('5289694416')}
              >
                {data ? data['5289694416'] : 'View More'} 

                <FeatherIcon
                  className="text-primary lg:ml-3 md:ml-3 ml-2 cust-textColor"
                  size={18}
                  fontWeight={"600"}
                  icon="chevron-right"
                />
              </span>
            </Button>

         
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {BlogsLoadMore &&
            BlogsLoadMore.result &&
            BlogsLoadMore.result.length > 0 ? (
              <div ref={elementRef} className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}>
                <div className="lg:mt-12 md:mt-12 mt-6 shadow-none lg:grid md:grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-4">
                  {BlogsLoadMore.result.map((e, i) => {
                    return (
                      <Card
                        key={e._id}
                        p="2"
                        className={
                          colorMode === 'light'
                            ? 'cursor-pointer themeShadow theme-bg'
                            : 'cursor-pointer themeShadow theme-bg'
                        }
                        
                        onClick={() => handleBlogDetail(e._id)}
                      >
                        <div className='overflow-hidden rounded-xl'>
                        <Image
                          height="275px"
                          src={process.env.REACT_APP_STORAGE_URL + e.image}
                          alt="heroImage"
                          className="w-full object-cover rounded-xl custimghover sm-h250px md-h200px w-full"
                        />
                        </div>
                        <div className="p-2 lg:mt-4 md:mt-4">
                          <div className="flex items-center justify-between mb-4">
                            {e.categoryID &&
                              e.categoryID.length > 0 &&
                              e.categoryID[0].categoryName && (
                                <Badge
                                  borderRadius="full"
                                  px="3"
                                  py="0.5"
                                  colorScheme="teal"
                                  className='cust-textColor'
                                >
                                  {e.categoryID[0].categoryName}
                                </Badge>
                              )}
                            
                          </div>

                          <h4 className="font-semibold lg:text-xl md:text-xl text-lg line-clamp-2 cust-textColor">
                            {e.title}
                          </h4>
                          <p
                            dangerouslySetInnerHTML={{ __html: e.content }}
                            className="mt-2 text8787 line-clamp-1 lg:text-sm md:text-sm text-sm textColor-light"
                          ></p>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
        {BlogsLoadMore &&
          BlogsLoadMore.totalPages &&
          BlogsLoadMore.totalPages - 1 > page && (
            <div className="lg:mt-10 md:mt-10 mt-2  text-center">
              <Button
                className="bg-primary textwhite"
                onClick={handleLoadMore}
                width="200px"
                height="50px"
              >
                LOAD MORE
              </Button>
            </div>
          )}
      </div>
  );
};

export default Blogs4
