import { Button, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";

const AboutSection = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, "ss");

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-8 md:py-8 py-6 lg:px-12 md:px-8 px-5 b bg-white">
      <div className="text-center lg:pb-12 md:pb-12 pb-6 mx-auto lg:w-3/5 md:w-3/4 ">
        <h3
          id="9392653432"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
        >
          {data ? data["9392653432"] : "Lorem Ipsum"}
        </h3>
        <h3
          id="2809571636"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold mb-2"
        >
          {data ? data["2809571636"] : "Lorem Ipsum"}
        </h3>
        <p
          id="8263046497"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["8263046497"]
              : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          }}
          className="dynamicStyle fsize16 sm-fsize14 textColor-light"
        >
          {/* In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content. */}
        </p>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom  ${isVisible ? "visiblebottom" : ""} `}
      >
        <div id="6824273241" className="dynamicStyle2 w-full ">
          <Image
            src={data ? getImage(data["6824273241"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="section2"
            className="br-28px bg-ddd9d8 w-full lg:h-full md:h-full h-52 object-cover"
          />
        </div>

        <div className="lg:mt-12 md:mt-8 mt-6 flex justify-center">
          <Button
            id="6662290131"
            className={
              colorMode === "light"
                ? "rounded-sm fsize14 smcust-btn clr-fff dynamicStyle  bdr-2px theme-btn rounded-100px bg-primary"
                : "rounded-sm fsize14 smcust-btn clr-fff dynamicStyle  bdr-2px"
            }
          >
            <span onClick={() => handleClick("6662290131")}>
              {data ? data["6662290131"] : "Enquire Now"}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
