import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className={
        colorMode === 'light'
          ? 'relative overflow-hidden bg-darkprimary theme-bg'
          : 'relative overflow-hidden theme-bg'
      }
    >
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-12 px-5 ">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center">
          <p
            id="8125210393"
            className="dynamicStyle fsize32 sm-fsize20 pb-2 font-semibold cust-textColor clr-fff theme-textColor"
          >
            {data ? data['8125210393'] : 'Lorem Ipsum'}
          </p>
          <p
            id="8951070390"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8951070390']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className={
              colorMode === 'light'
                ? 'dynamicStyle sm-fsize14 clr-fff theme-textColor'
                : 'dynamicStyle textColor-light sm-fsize14 theme-textColor'
            }
          ></p>
          <div className="flex gap-2 mt-4">
            <div className="w-9/12">
              <Input
                type="text"
                size="lg"
                placeholder="Email"
                className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
              />
            </div>

            <Button
              size="lg"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle w-3/12 bdr-0px bg-primary theme-btn sm-fsize14 md-fsize14'
                  : 'dynamicStyle w-3/12 bdr-0px cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
              }
              id="8541092098"
            >
              <span onClick={() => handleClick('8541092098')}>
                {data ? data['8541092098'] : 'Submit'}
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:h-36 lg:w-36 md:h-36 md:w-36 rounded-full border-26px absolute absl-news16-top mob-hidden"></div>
      <div className="lg:h-36 lg:w-36 md:h-36 md:w-36 rounded-full border-26px absolute absl-news16-bot mob-hidden"></div>
    </div>
  );
};

export default NewsletterSection16;
