import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Textarea, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const LeadSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div>
      <div
        className={
          colorMode === 'light'
            ? 'bg-a2a2a2 theme-bg themeShadow'
            : 'cust-bgColor theme-bg themeShadow'
        }
      >
        <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-20 md:mt-12 mt-8 lg:pr-14 md:pr-10">
            <p
              id="2288669571"
              className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold cust-textColor pb-2"
            >
              {data ? data['2288669571'] : 'Lorem Ipsum'}
            </p>
            <p
              id="6182800367"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6182800367']
                  : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
              }}
              className={
                colorMode === 'light'
                  ? 'dynamicStyle sm-fsize14'
                  : 'dynamicStyle sm-fsize14 textColor-light'
              }
            ></p>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:py-16 md:py-12 py-6 lg:px-12 md:px-8 px-5 lg:flex md:flex">
        <div id="8306304389" className="dynamicStyle lg:w-1/2 md:w-1/2 w-full">
          {data && data['8306304389']
            ? data['8306304389'].map(e => {
                return (
                  <div className="lg:w-3/5 md:w-4/5 w-full flex gap-2 lg:mb-6 md:mb-4 mb-2">
                    <FeatherIcon
                      className="text-gray cust-textColor"
                      size={28}
                      icon="check"
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e.field1,
                      }}
                      className="sm-fsize14 font-medium cust-textColor"
                    ></p>
                  </div>
                );
              })
            : 'no-data'}
          {/* <div className="lg:w-3/5 md:w-4/5 w-full flex gap-2 lg:mb-6 md:mb-4 mb-2">
          <FeatherIcon
              className="text-gray cust-textColor"
              size={28}
              icon="check"
            />
            <p className="sm-fsize14 font-medium cust-textColor">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="lg:w-3/5 md:w-4/5 w-full flex gap-2 lg:mb-6 md:mb-4 mb-2">
          <FeatherIcon
              className="text-gray cust-textColor"
              size={28}
              icon="check"
            />
            <p className="sm-fsize14 font-medium cust-textColor">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
          </div> */}
        </div>
        <div
          className={
            colorMode === 'light'
              ? 'lg:w-1/2 md:w-1/2 w-full cust-shadow lg:px-8 md:px-8 px-4 py-6 rounded-3xl bg-fff lead-2 md-lead-2 sm-lead-2 themeShadow theme-bg'
              : 'lg:w-1/2 md:w-1/2 w-full cust-shadow lg:px-8 md:px-8 px-4 py-6 rounded-3xl cust-bgColor lead-2 md-lead-2 sm-lead-2 themeShadow theme-bg'
          }
        >
          <div className="grid grid-cols-1 lg:gap-6 md:gap-6 gap-4">
            <div className="">
              <p
                id="1600707892"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['1600707892'] : 'First Name'}
              </p>
              <Input
                type="text"
                placeholder="First name"
                className="theme-border sm-fsize14"
              />
            </div>
            <div className="">
              <p
                id="6887662298"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['6887662298'] : 'Last Name'}
              </p>
              <Input
                type="text"
                placeholder="Last name"
                className="theme-border sm-fsize14"
              />
            </div>
            <div className="">
              <p
                id="7666349352"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['7666349352'] : 'Email Id'}
              </p>
              <Input
                type="text"
                placeholder="Email Id"
                className="theme-border sm-fsize14"
              />
            </div>
            <div className="">
              <p
                id="2677367924"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['2677367924'] : 'Phone Number'}
              </p>
              <Input
                type="text"
                placeholder="Phone Number"
                className="theme-border sm-fsize14"
              />
            </div>
            <div>
              <p
                id="3784698258"
                className="dynamicStyle dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['3784698258'] : 'Lorem Ipsum'}
              </p>
              <Textarea
                name="description"
                row={4}
                id=""
                placeholder="Type here"
                className="theme-border sm-fsize14"
              />
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <Button
              px={8}
              py={4}
              className={
                colorMode === 'light'
                  ? 'dynamicStyle w-full bg-primary textwhite theme-btn sm-fsize10 '
                  : 'dynamicStyle w-full bg-darkprimary textwhite theme-btn sm-fsize10 '
              }
              id="8437079219"
            >
              <span onClick={() => handleClick('8437079219')}>
                {data ? data['8437079219'] : 'Submit'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadSection2;
