import React, { useEffect, useRef, useState } from "react";
import { Image, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { handleScroll } from "./fadeeffect/FadeEffect";

const HeroSection22 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="herosection22-img lg:relative md:relative bg-grad">
      <div id="1255513914" className="w-full dynamicStyle2 ">
        <Image
          src={data ? getImage(data["1255513914"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          className="herosection22-img object-cover lg:block md:block hidden"
        />
      </div>
      <div className="lg:absolute md:absolute top-0 left-0 w-full flex items-center herosection22-img ">
        <div className="lg:px-12 md:px-8 px-5 lg:py-0 md:py-0 py-10 lg:flex md:flex items-center container mx-auto">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <h3
              id="4367369696"
              className="cust-textColor fsize45 md-fsize40 sm-fsize24 font-semibold text-primary dynamicStyle"
            >
              {data ? data["4367369696"] : "EDUCATE"}
            </h3>
            <p
              id="0342978196"
              dangerouslySetInnerHTML={{
                __html: data ? data["0342978196"] : "no-data",
              }}
              className="fsize16 md-fsize16 sm-fsize14 mt-2 cust-textColor lg:leading-7 md:leading-7 leading-6 dynamicStyle"
            ></p>
            <div className="lg:mt-8 md:mt-6 mt-4">
              <Button
                colorScheme="primary"
                id="0396505882"
                borderRadius={0}
                className="dynamicStyle cust-textColor sm-fsize13 buttonAnimate bdr-2px theme-btn"
              >
                {" "}
                <span onClick={() => handleClick("0396505882")}>
                  {data ? data["0396505882"] : "Lorem ipsum"}
                </span>
                <FeatherIcon icon="arrow-right" size="18" className=" ml-1" />
              </Button>
            </div>
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-1/2 md:w-1/2 w-full lg:pl-10 md:pl-5 pl-0 lg:mt-0 md:mt-0 mt-6 ${
              isVisible ? "visibleright" : ""
            } `}
          >
            <div id="3690711472" className="w-full dynamicStyle2">
              <Image
                src={data ? getImage(data["3690711472"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="hero"
                className="heroside-img object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection22;
