import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import Marquee from 'react-fast-marquee';

const Clients9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }


  return (
    <div className="bg-secondary theme-bg ">
      <div className="container mx-auto lg:py-20 md:py-16 py-10 lg:px-12 md:px-8 px-5">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h2
            id="5145450506"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 text-center font-semibold clr-fff cust-textColor mb-2"
          >
            {data ? data['5145450506'] : 'Lorem ipsum'}
          </h2>
          <p
            id="2254296986"
            className="dynamicStyle fsize20 md-fsize18 sm-fsize16 text-center clr-fff textColor-light"
            dangerouslySetInnerHTML={{
              __html: data ? data['2254296986'] : 'Lorem Ipsum',
            }}
          ></p>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-10 gap-6 w-full lg:mt-10 md:mt-8 mt-5">
          <div className="w-full">
            <div id="6817280223" className="dynamicStyle">
              <Image
                src={data ? getImage(data['6817280223']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2
              id="6623795272"
              className="dynamicStyle fsize22 md-fsize20 sm-fsize16 text-center font-semibold clr-fff cust-textColor mt-3"
            >
              {data ? data['6623795272'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="5804413505" className="dynamicStyle">
              <Image
                src={data ? getImage(data['5804413505']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2
              id="5685179790"
              className="dynamicStyle fsize22 md-fsize20 sm-fsize16 text-center font-semibold clr-fff cust-textColor mt-3"
            >
              
              {data ? data['5685179790'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="0679910146" className="dynamicStyle">
              <Image
                src={data ? getImage(data['0679910146']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2
              id="7141688549"
              className="dynamicStyle fsize22 md-fsize20 sm-fsize16 text-center font-semibold clr-fff cust-textColor mt-3"
            >
              {data ? data['7141688549'] : 'Lorem ipsum'}
            </h2>
          </div>
          <div className="w-full">
            <div id="3913018646" className="dynamicStyle">
              <Image
                src={data ? getImage(data['3913018646']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-full clent9-img object-contain mx-auto"
              />
            </div>
            <h2
              id="4873360576"
              className="dynamicStyle fsize22 md-fsize20 sm-fsize16 text-center font-semibold clr-fff cust-textColor mt-3"
            >
              {data ? data['4873360576'] : 'Lorem ipsum'}
            </h2>
          </div>
        </div>
        <p
            id="5893195741"
            className="dynamicStyle fsize20 md-fsize18 sm-fsize16 text-center clr-fff textColor-light lg:mt-16 md:mt-10 mt-5"
            dangerouslySetInnerHTML={{
              __html: data ? data['5893195741'] : 'Lorem Ipsum',
            }}
          ></p>
      </div>
    </div>
  );
};

export default Clients9;
