import { Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const Team16 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className={colorMode === 'light' ? 'theme-bg' : 'theme-bg'}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
          <p
            id="6582852817"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold mb-2"
          >
            {data ? data['6582852817'] : 'WHAT PEOPLE SAY ABOUT US'}
          </p>
          <p
            id="3197152449"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3197152449']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize16 sm-fsize14 textColor-light"
          ></p>
        </div>
        <div id='2831011237' className="dynamicStyle lg:mt-16 md:mt-12 mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-x-12 lg:gap-y-16 md:gap-x-12 md:gap-y-16 gap-8 lg:px-8">
          {data && data['2831011237']
            ? data['2831011237'].map(e => {
                return (
                  <Card
                  className={colorMode === 'light' ? 'box-shadow-none' : 'p-4'}
                  >
                    <div className="flex w-full">
                      <div className="w-1/4">
                        <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="team_img"
                          className="lg:w-24 md:w-16 w-16 lg:h-24 md:h-16 h-16 object-contain rounded-full"
                        />
                      </div>
                      <div
                      className={colorMode === 'light' ? 'w-3/4 lg:pl-0 md:pl-0' : 'w-3/4 lg:pl-0 md:pl-2'}
                      >
                        <p className="fsize13 lineClamp3 textColor-light"
                         dangerouslySetInnerHTML={{
                          __html: e.field2,
                        }}>
                         
                        </p>
                        <div className="lg:pt-4 md:pt-4 pt-2">
                          <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor">
                            {e.field3}
                          </p>
                          <p className="fsize14 sm-fsize12 font-medium text-gray cust-textColor">{e.field4}</p>
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </div>
  );
};

export default Team16;
