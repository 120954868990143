import { Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const Addbanner6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="pt-16 pb-28">
      <div id="7853233455" className="dynamicStyle2 h-350px relative w-full">
        <Image
          src={data ? getImage(data['7853233455']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-350px w-full"
        />
        <div className="absl6-text">
          <p
            id="0540318716"
            className="dynamicStyle clr-fff fsize20 sm-fsize16 font-semibold py-2 "
          >
            {data ? data['0540318716'] : 'Lorem Ipsum'}
          </p>
          <p
            id="5929410239"
            className="dynamicStyle pb-4 clr-fff fsize32 sm-fsize20 font-semibold"
          >
            {data ? data['5929410239'] : 'Lorem ipsum dolor sit amet.'}
          </p>
          <p
            id="2112556652"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2112556652']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className="dynamicStyle clr-fff sm-fsize14"
          ></p>
        </div>
        <div className="grid grid-cols-4 lg:gap-6 md:gap-6 gap-2 lg:px-20 md:px-14 px-2 lg:w-3/4 w-full absl6-cards">
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-bg theme-border-0">
            <div>
              <p
                id="6643842590"
                className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor"
              >
                {data ? data['6643842590'] : '324+'}
              </p>
              <p id="3714027548"
              className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
              {data ? data['3714027548'] : 'Our Clients'}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="4349401570"
                className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">
              {data ? data['4349401570'] : '324+'}
              </p>
              <p id="3012648253"
              className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                 {data ? data['3012648253'] : 'Our Clients'}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="6526303637"
                className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">
              {data ? data['6526303637'] : '324+'}
              </p>
              <p id="0160897624"
              className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                 {data ? data['0160897624'] : 'Our Clients'}
              </p>
            </div>
          </div>
          <div className="text-center h-180px md-h-150px sm-h-150px bdr-blue bg-fff flex justify-center items-center cust-bgColor theme_insertShadow theme-bg theme-border-0">
            <div>
              <p id="4377684219"
                className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-bold text-black cust-textColor">
              {data ? data['4377684219'] : '324+'}
              </p>
              <p id="5747298989"
              className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold px-8 text-black textColor-light">
                 {data ? data['5747298989'] : 'Our Clients'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addbanner6;
