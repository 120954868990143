import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Button, Input, Textarea, useColorMode } from "@chakra-ui/react";
const ContactForm3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div className="bg-fff">
      <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:w-3/4 md:w-3/4 m-auto">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 w-full  grid-cols-1 lg:gap-6 md:gap-6 gap-4 mb-10">
            {/* <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
          <div className="">
            <FeatherIcon
              icon="phone"
              className={
                colorMode === "light"
                  ? "mx-auto lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
                  : "mx-auto cust-textColor lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
              }
            />
            <p
              id="5522160848"
              className="dynamicStyle pt-4 cust-textColor sm-fsize14"
            >
              {data ? data["5522160848"] : "Lorem Ipsum"}
            </p>
          </div>
        </div> */}
            <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
              <div className="">
                <FeatherIcon
                  icon="mail"
                  className={
                    colorMode === "light"
                      ? "mx-auto lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
                      : "mx-auto cust-textColor lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
                  }
                />
                <p
                  id="8163546063"
                  className="dynamicStyle pt-4 cust-textColor sm-fsize14"
                >
                  {data ? data["8163546063"] : "Lorem Ipsum"}
                </p>
              </div>
            </div>
            <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
              <div className="text-center">
                <FeatherIcon
                  icon="map-pin"
                  className={
                    colorMode === "light"
                      ? "mx-auto lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
                      : "mx-auto cust-textColor lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6"
                  }
                />
                <p
                  id="8110559898"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["8110559898"] : "Lorem Ipsum",
                  }}
                  className="dynamicStyle pt-4 cust-textColor sm-fsize14"
                ></p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex md:flex gap-10">
          <div className="lg:w-1/2 md:w-1/2 sm-wfull lg:mb-0 md:mb-0 mb-10">
            <div className="mb-4">
              <p id="7156014336" className="dynamicStyle pb-2 cust-textColor">
                {data ? data["7156014336"] : "Lorem Ipsum"}
              </p>
              <Input placeholder="Enter your name" className="" />
            </div>
            <div className="mb-4">
              <p id="2200954228" className="dynamicStyle pb-2 cust-textColor">
                {data ? data["2200954228"] : "Lorem Ipsum"}
              </p>
              <Input placeholder="Enter your email id" className="" />
            </div>
            <div className="mb-4">
              <p id="1943437306" className="dynamicStyle pb-2 cust-textColor">
                {data ? data["1943437306"] : "Lorem Ipsum"}
              </p>
              <Textarea placeholder="Enter your message" className="" />
            </div>

            <Button
              px={8}
              py={4}
              id="6317987363"
              className={
                colorMode === "light"
                  ? "dynamicStyle bg-primary bdr-0px sm-fsize14 clr-fff theme-btn"
                  : "dynamicStyle cust-bgColor bdr-0px cust-textColor sm-fsize14 theme-btn"
              }
            >
              <span onClick={() => handleClick("6317987363")}>
                {data ? data["6317987363"] : "Send"}
              </span>
            </Button>
          </div>
          <div className="lg:w-1/2 md:w-1/2 sm-wfull">
            <div className="mb-4">
              <h3
                id="2284481732"
                className="dynamicStyle fsize28 sm-fsize20 font-semibold pb-2 cust-textColor"
              >
                {data ? data["2284481732"] : "Lorem Ipsum"}
              </h3>
              <p
                id="1534225896"
                dangerouslySetInnerHTML={{
                  __html: data ? data["1534225896"] : "Lorem Ipsum",
                }}
                className="dynamicStyle text-gray-400 sm-fsize14 textColor-light"
              ></p>
            </div>
            <div className="mb-4">
              <p
                id="3161120496"
                className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-2 cust-textColor"
              >
                {data ? data["3161120496"] : "Lorem Ipsum"}
              </p>
              <p
                id="5622044910"
                className="dynamicStyle pb-1 sm-fsize14 textColor-light"
              >
                {data ? data["5622044910"] : "Lorem Ipsum"}
              </p>
              <p
                id="6793819052"
                className="dynamicStyle pb-1 sm-fsize14 textColor-light"
              >
                {data ? data["6793819052"] : "Lorem Ipsum"}
              </p>
            </div>
            {/* <div className="mb-4">
              <p
                id="7980021519"
                className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-2 cust-textColor"
              >
                {data ? data["7980021519"] : "Lorem Ipsum"}
              </p>
              <p
                id="8267311900"
                className="dynamicStyle pb-1 sm-fsize14 textColor-light"
              >
                {data ? data["8267311900"] : "Lorem Ipsum"}
              </p>
              <p
                id="5166659352"
                className="dynamicStyle pb-1 sm-fsize14 textColor-light"
              >
                {data ? data["5166659352"] : "Lorem Ipsum"}
              </p>
            </div> */}
            <div className="mb-4">
              <p
                id="5799308128"
                className="dynamicStyle text-primary font-semibold fsize18 sm-fsize16 pb-4 cust-textColor"
              >
                {data ? data["5799308128"] : "Lorem Ipsum"}
              </p>
              <div className="flex gap-4">
                <div className="bdr-d5d2d2-1px rounded-full p-3">
                  <FeatherIcon
                    icon="linkedin"
                    className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                  />
                </div>
                <div className="bdr-d5d2d2-1px rounded-full p-3">
                  <FeatherIcon
                    icon="instagram"
                    className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                  />
                </div>
                <div className="bdr-d5d2d2-1px rounded-full p-3">
                  <FeatherIcon
                    icon="facebook"
                    className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                  />
                </div>
                <div className="bdr-d5d2d2-1px rounded-full p-3">
                  <FeatherIcon
                    icon="twitter"
                    className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm3;
