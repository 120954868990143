import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useNavigate } from 'react-router-dom';

const GridCard3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const navigate = useNavigate();
  const link = 'https://example.com';
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="container mx-auto lg:py-16 md:py-8 py-4 lg:px-12 md:px-8 px-5">
      <p
        id="5569353772"
        className="dynamicStyle font-semibold text-primary textColor-light"
      >
        {data ? data['5569353772'] : 'Lorem Ipsum'}
      </p>
      <p
        id="1417110583"
        className="dynamicStyle fsize34 md-fsize26 sm-fsize22 font-medium lg:mb-8 md:mb-8 mb-4 clr-191E22 cust-textColor"
      >
        {data ? data['1417110583'] : 'Lorem Ipsum generated'}
      </p>
      <div className="w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 md:gap-4 gap-4">
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary textColor-light sm-fsize14">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
        <div className="">
          <div className="overflow-hidden">
            <Image
              className="lg:h-60 h-52 w-full lg:mb-4 md:mb-4 mb-2 custimghover"
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt=""
            />
          </div>
          <p className="mb-2 md-fsize14 sm-fsize14 font-semibold clr-0E1317 cust-textColor">
            190 Gram African Cotton Real Wax Print Fabric (African Wax Prints
            Fabric)
          </p>
          <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 clr-0E1317 textColor-light">
            Multicolor • Cotton • Made in India
          </p>
          <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
          <div className="flex lg:w-1/4 cursor-pointer">
            <p className="text-primary sm-fsize14 textColor-light">Enquire</p>
            <ArrowRight className="text-primary ml-2 textColor-light sm-arrow" />
          </div>
        </div>
      </div>
      <div className="text-center lg:mt-12 md:mt-10 mt-6">
        <Button
          id="0391086893"
          // className="dynamicStyle sm-fsize14 lg:py-6 sm-py-6"
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-primary fsize14 sm-fsize12 btn-mdsize theme-btn2'
              : 'dynamicStyle cust-bgColor cust-textColor fsize14 sm-fsize12 btn-mdsize theme-btn2'
          }
        >
          <span onClick={() => handleClick('0391086893')}>
            {data ? data['0391086893'] : 'Buy Now'}
          </span>
        </Button>
      </div>
    </section>
  );
};

export default GridCard3;
