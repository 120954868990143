import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaQuoteRight } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
import { Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { Image, Card } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Testimonial17 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  // function getImage(image) {
  //   if (image && image.includes('blob:')) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'check-test');
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-14 md:py-12 py-8 container m-auto">
      <div class="text-center w-70  mx-auto g:pb-12 md:pb-12 pb-5">
        <p id="0091393439" className="dynamicStyle sm-fsize14 textColor-light ">
        {data ? data['0091393439'] : 'Collections'}
        </p>
        <h2
          id="9986348712"
          class="dynamicStyle cust-textColor fsize34 sm-fsize24 text-primary cust-textColor font-semibold mt-2"
        >
          {data ? data['9986348712'] : 'Collections'}
        </h2>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
      >
        <div className="lg:flex md:grid md:grid-cols-2 grid grid-cols-1 w-full testimonial17 justify-center flex-wrap gap-6">
          {getAllTestimonial &&
          getAllTestimonial.data &&
          getAllTestimonial.data.result &&
          getAllTestimonial.data.result.length > 0
            ? getAllTestimonial.data.result.map((testimonial, index) => {
                return (
                  <Card
                    className="cust-shadow  theme-radius rounded-lg mt-10 p-4 relative themeShadow-insert theme-bg lg:w-1/4 md:w-full"
                    key={index}
                  >
                    <ReactStars count={5} size={24} activeColor="#ffd700" />
                    <div className="pt-3">
                      <div className="flex gap-2">
                        <p className="w-90per cust-textColor fsize14 mt-1 lineClam4">
                          {testimonial.testimonial}
                        </p>
                        {/* <span className='w-10per fsize45 text-center'> &#8221; </span> */}
                        <FaQuoteRight
                          size="23"
                          className="text-primary cust-textColor"
                        />
                      </div>
                      <h2 className="fsize20 sm-fsize18 font-semibold mt-3 lg:mb-10 md:mb-8 cust-textColor">
                        {testimonial.name}
                      </h2>
                    </div>
                    <div
                      key={index}
                      className={index % 2 === 0 ? 'reviewcard' : 'reviewcard2'}
                    >
                      <Image
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                        alt=""
                        className="rounded-full img-wh-80"
                      />
                    </div>
                  </Card>
                );
              })
            : 'No Data'}
        </div>
      </div>
    </div>
  );
};
export default Testimonial17;
