import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  Progress,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const PollSection4 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <h3
            id="6238936678"
          className="dynamicStyle inline-block fsize34 md-fsize28 sm-fsize24 font-semibold text-black cust-textColor"
        >
          {data ? data['6238936678'] : 'Poll Question 2'}
        </h3>
        <p
          className="dynamicStyle fsize14 md-fsize14 sm-fsize12 lg:w-2/3 text-black textColor-light"
            id="2066868935"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2066868935']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
        >
        </p>
        <div className="lg:py-12 md:py-12 py-6 lg:w-1/2 md:w-1/2">
          <div className='flex gap-4 mb-4'>
            <p className='cust-textColor text-black'>75%</p>
            <div className='w-full'>
              <p className='mb-2 textColor-light text-black'>2023 - 2024</p>
              <Progress colorScheme='gray' height="10px" value={80} className='rounded-full themeShadow' />
            </div>
          </div>
          <div className='flex gap-4 mb-4'>
            <p className='cust-textColor text-black'>55%</p>
            <div className='w-full'>
              <p className='mb-2 textColor-light text-black'>2024 - 2025</p>
              <Progress colorScheme='gray' height="10px" value={55} className='rounded-full themeShadow' />
            </div>
          </div>
          <div className='flex gap-4 mb-4'>
            <p className='cust-textColor text-black'>20%</p>
            <div className='w-full'>
              <p className='mb-2 textColor-light text-black'>2025 - 2026</p>
              <Progress colorScheme='gray' height="10px" value={20} className='rounded-full themeShadow' />
            </div>
          </div>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup size="sm" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text-black textColor-light dynamicStyle"
                id="7991342936"
              >
                {data ? data['7991342936'] : 'Total votes:'}
              </p>
              <p className="fsize12 text-black textColor-light">24</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PollSection4;
