import { Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Services4 = ({ pageName }) => {
  const [collapse, setcollpase] = useState(null);
  const data = useSelector(state => state.cms.allSections[pageName]);
  console.log(data[6440697692], 'ds');

  const handleClick = index => {
    if (index === null) {
      setcollpase(null);
    } else {
      setcollpase(index);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-14 px-5 py-10">
      <div class="">
        <p
          id="7168604627"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary cust-textColor theme-textColor font-medium"
        >
          {data
            ? data['7168604627']
            : 'FINED YOUR BEST COACH NEAREST TO YOU AND BOOK YOUR SLOT EASILY'}
        </p>
        <h2
          id="4325274324"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor theme-textColor mt-2 "
        >
          {data
            ? data['4325274324']
            : 'FINED YOUR BEST COACH NEAREST TO YOU AND BOOK YOUR SLOT EASILY'}
        </h2>
      </div>
      <div className="mt-8">
        <div>
          {data && data['6440697692']
            ? data['6440697692'].map((e, index) => {
                return (
                  <div className="bordbl pb-2">
                    <div
                      className={
                        collapse === index
                          ? 'py-3 lg:px-2 md:px-2 cursor-pointer w-full flex justify-around items-start'
                          : 'py-3 lg:px-2 md:px-2 cursor-pointer w-full flex justify-around items-center'
                      }
                      onClick={() => handleClick(index)}
                    >
                      <div className="flex items-center gap-2 lg:w-5/12 md:w-5/12 w-11/12">
                        <div
                          className={
                            collapse === index
                              ? 'lg:w-3 lg:h-3 md:w-3 md:h-3 w-2 h-2 rounded-full bg-primary'
                              : 'lg:w-3 lg:h-3 md:w-3 md:h-3 w-2 h-2 rounded-full bg-dark'
                          }
                        ></div>
                        <h2
                          className={
                            collapse === index
                              ? 'fsize30 md-fsize20 sm-fsize18 text-primary font-semibold'
                              : 'fsize30 md-fsize20 sm-fsize18 text-dark font-semibold'
                          }
                        >
                          {e.field2}
                        </h2>
                      </div>
                      <div className="lg:w-6/12 md:w-6/12 lg:pr-5 md:pr-2 lg:block md:block hidden">
                        {collapse === index ? (
                          <>
                            <Image
                              src={e ? getImage(e.field1) : ''}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="image"
                              className="w-full lg:h-52 md:h-40 h-40"
                            />
                          </>
                        ) : null}
                        <p
                          className={
                            collapse === index
                              ? 'mt-4 fsize16 md-fsize14 text-gray lineClamp3'
                              : 'fsize16 md-fsize14 text-gray lineClamp3'
                          }
                          dangerouslySetInnerHTML={{
                            __html: e.field3,
                          }}
                        ></p>
                      </div>
                      <div className="w-1/12 flex justify-end">
                        <FeatherIcon
                          icon={collapse === index ? 'minus' : 'plus'}
                          size="24"
                        />
                      </div>
                    </div>
                    {collapse === index ? (
                      <div className="w-full lg:px-2 md:px-2 lg:hidden md:hidden block">
                        <>
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="image"
                            className="w-full lg:h-52 md:h-40 h-40"
                          />
                        </>
                        <p
                          className="fsize14 mt-3 text-gray lineClamp3"
                          dangerouslySetInnerHTML={{
                            __html: e.field3,
                          }}
                        ></p>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default Services4;
