import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PollSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <h3
          id="0895344041"
          className="dynamicStyle inline-block fsize34 md-fsize28 sm-fsize24 font-semibold text-black cust-textColor"
        >
          {data ? data['0895344041'] : 'Poll Question 1'}
        </h3>
        <p
          className="dynamicStyle fsize14 md-fsize14 sm-fsize12 lg:w-2/3 text-black textColor-light"
          id="8012130299"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['8012130299']
              : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
          }}
        ></p>
        <div className="lg:py-12 md:py-12 py-6 w-full flex flex-col gap-3">
          <Checkbox className="text-black textColor-light">2021-22</Checkbox>
          <Checkbox className="text-black textColor-light">2022-23</Checkbox>
          <Checkbox className="text-black textColor-light">2023-24</Checkbox>
        </div>
        <div className=" w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup size="sm" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text-black textColor-light dynamicStyle"
                id="6810007173"
              >
                {data ? data['8875853016'] : 'Total votes:'}
              </p>
              <p className="fsize12 text-black textColor-light">24</p>
            </div>
          </div>
          <Button
            id="2399056665"
            px={6}
            className="bg-primary cust-bgColor py-2  theme-btn2 clr-fff dynamicStyle"
            fontWeight={400}
            borderRadius={2}
          >
            <span onClick={() => handleClick('2399056665')}>
              {data ? data['2399056665'] : 'Vote'}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default PollSection1;
