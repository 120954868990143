import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  useColorMode,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
const Contactform5 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const data = useSelector(state => state.cms.allSections[pageName]);
  const handleClick = id => {
    const isHttpsLink =``
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary theme-bg' : 'theme-bg'}
    >
      <div className="container mx-auto lg:px-16 md:px-12 px-5 lg:py-16 md:py-16 py-8">
        <div className="lg:w-1/2 md:w-3/4 w-full text-center container mx-auto flex flex-col justify-center">
          <h3
            id="0285449509"
            className="dynamicStyle theme-textColor cust-textColor clr-fff fsize34 md-fsize24 sm-fsize20 font-semibold sm-fsize20"
          >
            {data ? data['0285449509'] : 'GET IN TOUCH'} 
          </h3>
          <hr class="hr-layout2"></hr>
          <p
            id="1522815469"
            className="mt-4 dynamicStyle theme-textColor cust-textColor clr-fff fsize16 md-fsize16 sm-fsize14 text-center"
          >
            {data ? data['1522815469'] : '1600 Pennsylvania Ave NW, Washington, DC 20500, United States of America. Tel: (202) 456-1111'} 
          </p>
        </div>

        <div className="lg:w-1/2 md:w-3/4 w-full mx-auto flex flex-col justify-center lg:my-10 md:my-10 my-6">
          <div className="w-full lg:flex md:flex lg:gap-6 md:gap-4 gap-4 mb-4">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <Input
                placeholder="Your Name*"
                className="form-control theme-border-0 fsize16 md-fsize14 sm-fsize14 bg-fff  themeShadow"
              />
            </div>

            <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-4">
              <Input
                placeholder="Your Email*"
                className="form-control theme-border-0 fsize16 md-fsize14 sm-fsize14 bg-fff  themeShadow"
              />
            </div>
          </div>

          <div className="mb-6">
            <Textarea
              placeholder="Your Message*"
              className="form-control theme-border-0 fsize16 md-fsize14 sm-fsize14 bg-fff clr-fff  themeShadow"
            />
          </div>

          <Button
            fontWeight={'500'}
            mx={'0px'}
            background={'none'}
            _hover={'none'}
            className="buttonAnimate cust-textColor theme-btn cust-bgColor fsize16 md-fsize14 sm-fsize14 cursor-pointer bg-fff text-secondary w-full text-center font-semibold"
            id="6686146883"
          >
            <span
              className="flex items-center"
             onClick={() => handleClick('6686146883')}
            >
              {data ? data['6686146883'] : 'Submit'} 
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Contactform5;
