import React, { useState } from 'react';
import { Button, Image, Input, Radio } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const Calendar1 = ({ pageName }) => {
  const [value, setValue] = useState('1');
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="lg:flex md:flex w-full container mx-auto lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 ">
      <div className="lg:w-7/12 md:w-1/2 w-full lg:pr-10 md:pr-4">
        <div className="">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next2',
              prevEl: '.image-swiper-button-prev2',
            }}
            className="p-1 bg-fa rounded-lg"
            breakpoints={{
              1536: {
                slidesPerView: 8,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 8,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 8,
                spaceBetween: 10,
              },
              991: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev2 bg-white rounded-full p-1 cursor-pointer fsize12">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="13px"
                width="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next2 bg-white rounded-full p-1 cursor-pointer fsize12">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="13px"
                width="13px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="p-2 bg-dark text-center rounded-lg">
                <h6 className="fsize14 sm-fsize12 text-white">8</h6>
                <h6 className="fsize14 sm-fsize12 text-white">FRI</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">9</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">10</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">11</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">12</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">13</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">14</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">15</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">16</h6>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p-2 h-16 flex items-center justify-center rounded-lg">
                <h6 className="fsize16 sm-fsize13 text-gray">17</h6>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="pt-4 pb-8">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4">
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
            <div className="rounded-lg border lg:p-3 md:p-3 p-2">
              <p className="fsize14 sm-fsize12 text-gray">9:00 PM - 10:00PM</p>
              <div className="flex items-center gap-1 mt-1">
                <FeatherIcon icon="clock" size="14" />
                <p className="fsize14 sm-fsize12 text-gray">60 Min</p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 flex items-center gap-6 bordbl">
          <div className="flex items-center gap-2">
            <FeatherIcon
              icon="clock"
              className="text-gray border rounded-sm p-1"
            />
            <p className="fsize16 text-gray font-medium">Booked Slots</p>
          </div>
          <div className="flex items-center gap-2">
            <FeatherIcon
              icon="clock"
              className="text-gray border rounded-sm p-1"
            />
            <p className="fsize16 text-gray font-medium">Available Slots</p>
          </div>
        </div>
        <div className="mt-4 grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 items-center gap-6">
          <div>
            <h5 className="fsize20 md-fsize18 sm-fsize16 text-black font-medium">Type of Session</h5>
            <div className="flex items-center gap-4 mt-2">
              <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                <Radio value="1" className="text-black">
                  Online
                </Radio>
              </div>
              <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                <Radio value="2" className="text-black">
                  Offline
                </Radio>
              </div>
            </div>
          </div>
          <div>
            <h5 className="fsize20 md-fsize18 sm-fsize16 text-black font-medium">Select Location</h5>
            <div className="flex items-center gap-4 mt-2">
              <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                <Radio value="1" className="text-black">
                  GYM
                </Radio>
              </div>
              <div className="py-2 px-4 bg-f4f5f6 rounded-full fsize14 sm-fsize12">
                <Radio value="2" className="text-black">
                  OUTSIDE
                </Radio>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-5/12 md:w-1/2 w-full lg:pl-0 md:pl-4 pl-0 lg:mt-0 md:mt-0 mt-6">
        <div
          className={
            colorMode === 'light'
              ? 'lg:p-8 md:p-6 p-4 cust-shadow rounded-lg bg-white'
              : 'lg:p-8 md:p-6 p-4 cust-shadow rounded-lg bg-glass'
          }
        >
          <div className="flex justify-center">
            <div>
              <Image
                src={data ? getImage(data['9333614484']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="booking"
                className="lg:w-32 lg:h-32 md:w-32 md:h-32 w-24 h-24 rounded-full mx-auto"
              />
              <h4
                id="1857928962"
                className="dynamicStyle fsize23 sm-fsize23 font-semibold cust-textColor text-center mt-2 capitalize"
              >
                Manish Sharma
              </h4>
              <p
                id="1655114786"
                className="dynamicStyle fsize17 sm-fsize13 mt-2 text-gray textColor-light font-semibold text-center capitalize"
              >
                Lorem Ipsum
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="">
              <label className="fsize14 sm-fsize12">Booking Time</label>
              <Input
                placeholder="Enter"
                type="date"
                h={12}
                className="textColor-light mt-2"
              />
            </div>
            <div className="">
              <label className="fsize14 sm-fsize12">Booking Date</label>
              <Input
                placeholder="Enter"
                type="time"
                h={12}
                className="textColor-light mt-2"
              />
            </div>
            <div className="">
              <label className="fsize14 sm-fsize12">Section Type</label>
              <Input
                placeholder="Enter"
                h={12}
                className="textColor-light mt-2"
              />
            </div>
          </div>
          <div className="mt-6">
            <Button
              colorScheme="primary"
              className="dynamicStyle w-full lg:py-6 py-4 sm-fsize13 textColor-light cust-bgColor"
              id="7373931105"
            >
              <span onClick={() => handleClick('7373931105')}>
                Book Appointtment
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar1;
