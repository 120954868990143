import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Image, useColorMode, Input, Button, Card } from '@chakra-ui/react';

const BookingDetail1 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [check, setcheck] = useState(1);
  return (
    <section className="container mx-auto lg:px-12 md:px-4 px-5 lg:py-12 md:8 py-6">
      <div className="lg:flex md:flex items-center justify-between w-full">
        <div>
          <h4 className="fsize34 md-fsize24 text-dark font-semibold cust-textColor">
            Lorem Ipsum
          </h4>
          <div className="lg:flex md:flex items-center gap-1">
            <p className="fsize16 text-gray textColor-light">Lorem Ipsum</p>
            <p className="fsize16 text-primary cust-textColor">Lorem Ipsum</p>
          </div>
        </div>
        <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-2">
          <div className="p-2 border rounded-full">
            <FeatherIcon
              icon="heart"
              className="w-4 h-4 text-primary cust-textColor"
            />
          </div>
          <p className="fsize16 text-gray textColor-light">Save</p>
        </div>
      </div>
      <div className="mt-4">
        <div className="lg:flex md:flex gap-4 w-full">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="Image"
              className="w-full lg:h-96 md:h-80 h-52 rounded-lg"
            />
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full grid grid-cols-2 md:gap-6 gap-4 lg:gap-6 lg:mt-0 md:mt-0 mt-4">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="Image"
              className="w-full lg:h-44 md:h-36 h-32 flex rounded-lg"
            />
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="Image"
              className="w-full lg:h-44 md:h-36 h-32 flex rounded-lg"
            />
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="Image"
              className="w-full lg:h-44 md:h-36 h-32 flex rounded-lg"
            />
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="Image"
              className="w-full lg:h-44 md:h-36 h-32 flex rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="lg:flex md:flex gap-4 w-full">
          <div className="lg:w-8/12 md:w-7/12">
            <div className="border rounded-lg lg:p-5 md:p-4 p-2">
              <div className="flex items-center sm-overflow-auto">
                <div
                  className={
                    check === 1
                      ? 'bordb-primary pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                      : 'bordbl pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                  }
                  onClick={() => setcheck(1)}
                >
                  <h4
                    className={
                      check === 1
                        ? 'fsize16 md-fsize13 sm-fsize13 min-wtab text-primary font-semibold '
                        : 'fsize16 md-fsize13 sm-fsize13 min-wtab text-gray textColor-light'
                    }
                  >
                    Overview
                  </h4>
                </div>
                <div
                  className={
                    check === 2
                      ? 'bordb-primary pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                      : 'bordbl pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                  }
                  onClick={() => setcheck(2)}
                >
                  <h4
                    className={
                      check === 2
                        ? 'fsize16 md-fsize13 sm-fsize13 min-wtab text-primary font-semibold '
                        : 'fsize16 md-fsize13 sm-fsize13 min-wtab text-gray textColor-light'
                    }
                  >
                    Reviews(160)
                  </h4>
                </div>
                <div
                  className={
                    check === 3
                      ? 'bordb-primary pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                      : 'bordbl pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                  }
                  onClick={() => setcheck(3)}
                >
                  <h4
                    className={
                      check === 3
                        ? 'fsize16 md-fsize13 sm-fsize13 min-wtab text-primary font-semibold '
                        : 'fsize16 md-fsize13 sm-fsize13 min-wtab text-gray textColor-light'
                    }
                  >
                    Policies
                  </h4>
                </div>
                <div
                  className={
                    check === 4
                      ? 'bordb-primary pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                      : 'bordbl pb-2 lg:px-6 md:px-3 px-3 cursor-pointer'
                  }
                  onClick={() => setcheck(4)}
                >
                  <h4
                    className={
                      check === 4
                        ? 'fsize16 md-fsize13 sm-fsize13 min-wtab text-primary font-semibold '
                        : 'fsize16 md-fsize13 sm-fsize13 min-wtab text-gray textColor-light'
                    }
                  >
                    FAQ's
                  </h4>
                </div>
              </div>
              {check === 1 ? (
                <>
                  {' '}
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 mt-4">
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {check === 2 ? (
                <>
                  {' '}
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 mt-4">
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {check === 3 ? (
                <>
                  {' '}
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 mt-4">
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {check === 4 ? (
                <>
                  {' '}
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 mt-4">
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4 bordb-dashed lg:p-4 md:p-2 p-2">
                      <div className="w-8/12 flex items-center gap-4">
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="icon"
                          className="w-6 h-6"
                        />
                        <p className="fsize16 md-fsize14 text-grey textColor-light">
                          Lorem
                        </p>
                      </div>
                      <div className="w-4/12">
                        <p className="fsize16 md-fsize14 text-dark font-semibold cust-textColor">
                          Lorem
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="border rounded-lg lg:p-5 md:p-4 p-2 mt-4">
              <h4 className="fsize20 md-fsize18 sm-fsize16 text-primary cust-textColor font-semibold underline text-underlines">
                Description
              </h4>
              <p className="fsize16 text-gray textColor-light mt-4">
                Lorem Ipsum
              </p>
            </div>
            <div className="border rounded-lg lg:p-5 md:p-4 p-2 mt-4">
              <h4 className="fsize20 md-fsize18 sm-fsize16 text-primary cust-textColor font-semibold underline text-underlines">
                Ameneties
              </h4>
              <div className="mt-6 grid lg:grid-cols-5 md:grid-cols-2 gap-4">
                <div className="border p-4 rounded-lg">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="Image"
                    className="w-12 h-12 mx-auto rounded-lg"
                  />
                  <p className="fsize14 textColor-light text-center text-gray mt-2">
                    Lorem Ipsum
                  </p>
                </div>
                <div className="border p-4 rounded-lg">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="Image"
                    className="w-12 h-12 mx-auto rounded-lg"
                  />
                  <p className="fsize14 textColor-light text-center text-gray mt-2">
                    Lorem Ipsum
                  </p>
                </div>
                <div className="border p-4 rounded-lg">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="Image"
                    className="w-12 h-12 mx-auto rounded-lg"
                  />
                  <p className="fsize14 textColor-light text-center text-gray mt-2">
                    Lorem Ipsum
                  </p>
                </div>
                <div className="border p-4 rounded-lg">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="Image"
                    className="w-12 h-12 mx-auto rounded-lg"
                  />
                  <p className="fsize14 textColor-light text-center text-gray mt-2">
                    Lorem Ipsum
                  </p>
                </div>
                <div className="border p-4 rounded-lg">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="Image"
                    className="w-12 h-12 mx-auto rounded-lg"
                  />
                  <p className="fsize14 textColor-light text-center text-gray mt-2">
                    Lorem Ipsum
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-4/12 md:w-5/12 lg:mt-0 md:mt-0 mt-4">
            <div className="border rounded-lg lg:p-5 md:p-4 p-2">
              <div className="flex items-center">
                <p className="fsize24 md-fsize21 sm-fsize18 font-semibold text-primary cust-textColor">
                  2,095
                  <span className="fsize12 ml-1 clr-f4f5f6 textColor-light">
                    /month
                  </span>
                </p>
              </div>
              <div className="lg:flex md:grid md:grid-cols-1 md:gap-1 items-center lg:gap-6 mt-2">
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex items-center gap-2 py-2 rounded-full'
                      : ' flex items-center gap-2 py-2 rounded-full'
                  }
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize14 font-semibold cust-textColor">3 beds</p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex items-center gap-2 py-2 rounded-full'
                      : ' flex items-center gap-2 py-2 rounded-full'
                  }
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize14 font-semibold cust-textColor">
                    2 bathrooms
                  </p>
                </div>
                <div
                  className={
                    colorMode === 'light'
                      ? 'flex items-center gap-2 py-2 rounded-full'
                      : ' flex items-center gap-2 py-2 rounded-full'
                  }
                >
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="objext-contain w-6 h-6"
                  />
                  <p className="fsize14 font-semibold cust-textColor">
                    5*7 m<sup>2</sup>
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <Input placeholder="Enter" size="md" />
              </div>
              <div className="mt-4">
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                  className="textColor-light"
                />
              </div>
              <div className="lg:mt-10 md:mt-8 mt-4">
                <Button
                  height="40px"
                  className="bg-primary cust-bgColor w-full lg:mt-8 md:mt-8 mt-6 theme-btn zindex9 relative mt-6 textwhite dynamicStyle buttonAnimate bdr-2px"
                >
                  Shedule visit
                </Button>
              </div>
            </div>
            <div className="border rounded-lg lg:p-4 md:p-4 p-2 mt-4">
              <h4 className="fsize22 md-fsize20 sm-fsize18 text-primary font-semibold my-0">
                Similar properties
              </h4>
              <Card className="rounded-lg theme-bg themeShadow cursor-pointer lg:mt-4 md:mt-2 mt-2">
                <div className="relative">
                  <Image
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt=""
                    className="rounded-t-lg w-full h-48"
                  />
                </div>
                <div className="p-3">
                  <div className="bottom-border-e2e8f0">
                    <div className="flex justify-between items-center">
                      <p className="fsize20 md-fsize16 sm-fsize14 font-semibold text-primary cust-textColor">
                        2,095
                        <span className="fsize10 clr-f4f5f6 textColor-light">
                          /month
                        </span>
                      </p>

                      <div className="p-2 border rounded-full">
                        <FeatherIcon
                          icon="heart"
                          className="w-4 h-4 cust-textColor"
                        />
                      </div>
                    </div>
                    <p className="fsize24 md-fsize18 sm-fsize20 font-semibold cust-textColor">
                      Lorem Ipsum
                    </p>
                    <p className="fsize13 textColor-light clr-f4f5f6 textColor-light pb-2">
                      Green Valley, So Road, MH
                    </p>
                  </div>
                  <div className="py-3">
                    <div className="lg:flex md:grid md:grid-cols-1 md:gap-2 items-center lg:gap-6">
                      <div
                        className={
                          colorMode === 'light'
                            ? 'flex items-center gap-2 py-2 rounded-full'
                            : ' flex items-center gap-2 py-2 rounded-full'
                        }
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt=""
                          className="objext-contain w-6 h-6"
                        />
                        <p className="fsize14 font-semibold cust-textColor">
                          3 beds
                        </p>
                      </div>
                      <div
                        className={
                          colorMode === 'light'
                            ? 'flex items-center gap-2 py-2 rounded-full'
                            : ' flex items-center gap-2 py-2 rounded-full'
                        }
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt=""
                          className="objext-contain w-6 h-6"
                        />
                        <p className="fsize14 font-semibold cust-textColor">
                          2 bathrooms
                        </p>
                      </div>
                      <div
                        className={
                          colorMode === 'light'
                            ? 'flex items-center gap-2 py-2 rounded-full'
                            : ' flex items-center gap-2 py-2 rounded-full'
                        }
                      >
                        <Image
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt=""
                          className="objext-contain w-6 h-6"
                        />
                        <p className="fsize14 font-semibold cust-textColor">
                          5*7 m<sup>2</sup>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingDetail1;
