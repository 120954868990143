import { Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection44 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');

  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-4 lg:py-16 md:py-16 py-8">
        <div className="lg:flex md:flex items-center">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <h5
              id="5631669592"
              className="dynamicStyle text-dark fsize36 cust-textColor sm-fsize20 md-fsize26 font-semibold"
            >
              {data ? data['5631669592'] : 'no-data'}
            </h5>
            <p
              id="2591786740"
              className="dynamicStyle fsize16 sm-fsize14 lineClamp3 textColor-light md-fsize16 mt-2 text-gray"
            >
              {data ? data['2591786740'] : 'no-data'}
            </p>
            <div
              className="mt-8 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 dynamicStyle"
              id="7642001994"
            >
              {data && data['7642001994']
                ? data['7642001994'].map(e => {
                    return (
                      <div>
                        <h6 className="fsize32 text-gray font-semibold">
                          {e.field1}
                        </h6>
                        <h2 className="mt-1 fsize22 md-fsize20 sm-fsize18 text-dark font-semibold cust-textColor">
                          {e.field2}
                        </h2>
                        <p className="mt-2 fsize16 text-gray font-medium">
                          {e.field3}
                        </p>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex gap-6 w-full lg:mt-0 md:mt-0 mt-10">
            <div className="w-1/2 lg:mt-20 md:mt-16 mt-8">
              <div id="0434541005" className="dynamicStyle2">
                <Image
                  className="lg:h-80 md:h-44 h-44 w-full rounded-lg"
                  src={data ? getImage(data['0434541005']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div id="7642423064" className="dynamicStyle2 mt-4">
                <Image
                  className="lg:h-52 md:h-40 h-40 w-full rounded-lg"
                  src={data ? getImage(data['7642423064']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </div>
            <div className="w-1/2">
              <div id="4336554812" className="dynamicStyle2">
                <Image
                  className="lg:h-60 md:h-40 h-40 w-full rounded-lg"
                  src={data ? getImage(data['4336554812']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285960.jpg`}
                  alt="img"
                />
              </div>
              <div id="7090174168" className="dynamicStyle2 mt-4">
                <Image
                  className="lg:h-52 md:h-40 h-40 w-full rounded-lg"
                  src={data ? getImage(data['7090174168']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection44;
