import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const NewsletterSection5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:w-3/5 md:w-4/5 w-full mx-auto text-center">
        <p
          id="3136572118"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold py-3 text-black cust-textColor"
        >
          {data ? data['3136572118'] : 'Lets Design Together'}
        </p>
        <p
          id="2826385445"
          className="dynamicStyle text-gray textColor-light fsize16 md-fsize16 sm-fsize14"
        >
          {data
            ? data['2826385445']
            : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias excepturi provident doloribus natus. '}
        </p>
        <div className="flex items-center lg:gap-3 md:gap-3 gap-2 lg:mt-5 md:mt-4 mt-3">
          <InputGroup size="lg" className="mx-auto my-6 lg:w-4/5 w-full">
            <Input
              pr="4.5rem"
              placeholder="Enter your email address"
              className="h45px fsize14 md-fsize14 sm-fsize12"
            />
          </InputGroup>

          <Button
            px={'20px'}
            colorScheme="primary"
            className="h45px clr-fff fsize16 md-fsize14 sm-fsize12 cust-bgColor buttonAnimate theme-btn"
            size="lg"
            id="3023293995"
          >
            <span onClick={() => handleClick('3023293995')}>
              {data ? data['3023293995'] : 'Contact Me'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection5;
