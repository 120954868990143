import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode, Button} from '@chakra-ui/react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const FeatureCard30 = ({ pageName }) => {
  const [check, SetCheck] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="lg:w-2/3 md:w-2/3 mx-auto">
        <h4
          className="fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold text-center text-primary dynamicStyle"
          id="3151485908"
        >
          {data ? data['3151485908'] : 'News Arrivals'}
        </h4>
        <p
          id="2794871608"
          className="fsize16 md-fsize16 sm-fsize14 font-medium text-center mt-2 dynamicStyle textColor-light"
        >
          {data
            ? data['2794871608']
            : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam explicabo cum laudantium repellendus animi provident culpa alias illo dicta ab.'}{' '}
         
        </p>
      </div>
      <div className="lg:mt-10 md:mt-6 mt-6">
        <div className="mx-auto lg:w-4/5 md:w-4/5">
          <Swiper
            spaceBetween={30}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 5,
                spaceBetween: 12,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.9,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.9,
                spaceBetween: 12,
              },
              320: {
                slidesPerView: 1.9,
                spaceBetween: 12,
              },
            }}
          >
            <SwiperSlide id={1} onClick={() => SetCheck(1)}>
              <p
                className={
                  check === 1
                    ? 'fsize14 bg-primary cust-bgColor theme-btn text-white py-2 px-2 text-center dynamicStyle rounded-md'
                    : 'theme-btn fsize14 bg-f3f6f6 cust-bgColor cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md'
                }
              >
               Men's Fashion
              </p>
            </SwiperSlide>
            <SwiperSlide id={2} onClick={() => SetCheck(2)}>
              <p
                className={
                  check === 2
                    ? 'theme-btn fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle rounded-md'
                    : 'theme-btn fsize14 bg-f3f6f6 cust-bgColor cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md'
                }
              >
          Women's Fashion
              </p>
            </SwiperSlide>
            <SwiperSlide id={3} onClick={() => SetCheck(3)}>
              <p
                className={
                  check === 3
                    ? 'theme-btn fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle rounded-md'
                    : 'theme-btn fsize14 bg-f3f6f6 cust-bgColor cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md'
                }
              >
                Women Accessories
              </p>
            </SwiperSlide>
            <SwiperSlide id={4} onClick={() => SetCheck(4)}>
              <p
                className={
                  check === 4
                    ? 'theme-btn fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle rounded-md'
                    : 'theme-btn fsize14 bg-f3f6f6 cust-bgColor cust-textColor  py-2 px-2 text-center dynamicStyle rounded-md'
                }
              >
              Men Accessories
              </p>
            </SwiperSlide>
            <SwiperSlide id={5} onClick={() => SetCheck(5)}>
              <p
                className={
                  check === 5
                    ? 'theme-btn fsize14 bg-primary cust-bgColor text-white py-2 px-2 text-center dynamicStyle rounded-md'
                    : 'theme-btn fsize14 bg-f3f6f6 cust-textColor cust-bgColor py-2 px-2 text-center dynamicStyle rounded-md'
                }
              >
                  Discount Deals
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="mt-6 lg:w-11/12 mx-auto">
          {check === 1 ? (
            <>
            <div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6">
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Long Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Full Sweater
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        White Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Colorful Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        White Shirt
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='w-full flex justify-center lg:mt-8 md:mt-8 mt-6'>
              <Button
                fontWeight={400}
                py={0}
                id="9923062845"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                    : 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                }
                borderRadius={4}
              >
                <span onClick={() => handleClick('9923062845')}>
                  {data ? data['9923062845'] : 'View More'}
                </span>
              </Button>
              </div>
              </div>
            </>
          ) : (
            ''
          )}
          {check === 2 ? (
            <>
            <div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='w-full flex justify-center lg:mt-8 md:mt-8 mt-6'>
              <Button
                fontWeight={400}
                py={0}
                id="9923062845"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                    : 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                }
                borderRadius={4}
              >
                <span onClick={() => handleClick('9923062845')}>
                  {data ? data['9923062845'] : 'View More'}
                </span>
              </Button>
              </div>
              </div>
            </>
          ) : (
            ''
          )}
          {check === 3 ? (
            <>
            <div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='w-full flex justify-center lg:mt-8 md:mt-8 mt-6'>
              <Button
                fontWeight={400}
                py={0}
                id="9923062845"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                    : 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                }
                borderRadius={4}
              >
                <span onClick={() => handleClick('9923062845')}>
                  {data ? data['9923062845'] : 'View More'}
                </span>
              </Button>
              </div>
              </div>
            </>
          ) : (
            ''
          )}
          {check === 4 ? (
            <>
            <div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='w-full flex justify-center lg:mt-8 md:mt-8 mt-6'>
              <Button
                fontWeight={400}
                py={0}
                id="9923062845"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                    : 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                }
                borderRadius={4}
              >
                <span onClick={() => handleClick('9923062845')}>
                  {data ? data['9923062845'] : 'View More'}
                </span>
              </Button>
              </div>
              </div>
            </>
          ) : (
            ''
          )}
          {check === 5 ? (
            <>
            <div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
                <Card
                  className={
                    colorMode === 'light'
                      ? 'w-full cust-shadow themeShadow theme-bg rounded-md p-3'
                      : 'w-full themeShadow theme-bg p-3'
                  }
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div
                    id="9733816070"
                    className="dynamicStyle2 flex justify-center w-full rounded-md"
                  >
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                     
                      alt="imagee"
                      className="lg:h-56 md:h-52 h-52 w-full object-cover bg-d2d2d2 css-0 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex justify-between">
                      <p className="fsize18 md-fsize16 sm-fsize16 font-medium text-black cust-textColor lineClamp1">
                        Shiny Dress
                      </p>
                      <Rating
                        initialRating={5}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 text-black textColor-light sm-star-16 "
                            size={22}
                          />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="star-hw md-star-12 sm-star-16  fillstroke sg"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>
                    <p class="fsize14 md-fsize14 sm-fsize12 text-grey textColor-light">
                      Al Karam
                    </p>
                    <p className="fsize14 md:fsize14 sm-fsize12  text-gray cust-textColor my-3">
                      (4.1k) Customer Reviews
                    </p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="fsize14 md:fsize14 sm-fsize12 text-gray cust-textColor">
                        $95.50
                      </p>

                      <p className="text-danger textColor-light fsize14 md:fsize14 sm-fsize12 font-medium">
                        Almost Sold Out
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='w-full flex justify-center lg:mt-8 md:mt-8 mt-6'>
              <Button
                fontWeight={400}
                py={0}
                id="9923062845"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                    : 'dynamicStyle clr-fff bg-black  cust-bgColor cust-textColor  fsize14 theme-btn themeShadow'
                }
                borderRadius={4}
              >
                <span onClick={() => handleClick('9923062845')}>
                  {data ? data['9923062845'] : 'View More'} 
                </span>
              </Button>
              </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>

    
      </div>
    </div>
  );
};

export default FeatureCard30;
