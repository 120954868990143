import { Button, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection32 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }

  return (
    <>
      <div className="relative">
        <div className="dynamicStyle2" id="0406501127">
          <Image
            src={data ? getImage(data['0406501127']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="elearning-banner"
            className="h-400px sm-h300px filter-b4 w-full"
          />
        </div>
        <div className="absolute top-0 left-0 h-400px sm-h300px w-full flex justify-start items-center">
          <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
            <div className="lg:w-6/12 md:w-7/12 w-full">
              <h2
                className="fsize40 md-fsize34 sm-fsize24 font-semibold cust-textColor clr-fff dynamicStyle"
                id="4867612140"
              >
                {data ? data['4867612140'] : 'Ui/UX Design'}
              </h2>
              <p
                id="9069879141"
                className="dynamicStyle  clr-fff fsize16 md-fsize16 sm-fsize14 font-medium mt-2 textColor-light"
              >
                {data ? data['9069879141'] : 'Ui/UX Design'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection32;
