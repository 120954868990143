import { Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection39 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  console.log(selectedStyle, 'ss');

  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="bg-dark cust-bgColor">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <div className="lg:flex md:flex items-center gap-10">
          <div className="lg:w-1/2 md:w-1/2 w-full relative">
            <div id="7462683715" className="dynamicStyle2">
              <Image
                className="h-400px mdh-300px sm-h-250px lg:w-9/12 w-full mx-auto rounded-lg"
                src={data ? getImage(data['7462683715']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div className="absolute top-0 left-0 mt-6">
              <div className="flex items-center gap-2 bg-white shadow theme_insertShadow cust-bgColor rounded-lg p-3">
                <div id="6766906375" className="dynamicStyle2">
                  <Image
                    className="w-10 h-10 rounded-full"
                    src={data ? getImage(data['6766906375']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                </div>
                <div>
                  <h5
                    id="3122413847"
                    className="dynamicStyle fsize16 sm-fsize14 my-0 cust-textColor font-semibold text-dark"
                  >
                    {data ? data['3122413847'] : 'no-data'}
                  </h5>
                  <p
                    id="8267637788"
                    className="dynamicStyle fsize12 my-0 font-medium textColor-light text-gray"
                  >
                    {data ? data['8267637788'] : 'no-data'}
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-center aboutminus-mb w-full mt-4">
              <div className="lg:w-7/12 md:w-10/12 w-11/12 bg-white cust-bgColor theme_insertShadow shadow rounded-lg p-3 relative">
                <div className="w-10/12">
                  <h5
                    id="9932385299"
                    className="dynamicStyle fsize16 sm-fsize14 my-0 cust-textColor font-semibold text-dark"
                  >
                    {data ? data['9932385299'] : 'no-data'}
                  </h5>
                  <p
                    id="3137447020"
                    className="dynamicStyle fsize12 my-0 font-medium textColor-light text-gray"
                  >
                    {data ? data['3137447020'] : 'no-data'}
                  </p>
                </div>
                <div className='aboutminus-mt mr-4 absolute top-0 right-0'>
                <div
                  id="0875963964"
                  className="dynamicStyle2"
                >
                  <Image
                    className="w-10 h-10 rounded-full "
                    src={data ? getImage(data['0875963964']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="img"
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-12">
            <div className="bg-glass2 theme_insertShadow w-max flex items-center gap-2 p-2 rounded-lg">
              <div
                className={
                  check === 1
                    ? 'bg-white cursor-pointer text-primary px-4 py-2 rounded-lg'
                    : 'clr-fff cursor-pointer px-4 py-2'
                }
                onClick={() => setcheck(1)}
              >
                <p
                  id="1763659957"
                  className="dynamicStyle md-fsize13 sm-fsize12 fsize14"
                >
                  {data ? data['1763659957'] : 'no-data'}
                </p>
              </div>
              <div
                className={
                  check === 2
                    ? 'bg-white cursor-pointer text-primary px-4 py-2 rounded-lg'
                    : 'clr-fff cursor-pointer px-4 py-2'
                }
                onClick={() => setcheck(2)}
              >
                <p
                  id="1504186873"
                  className="dynamicStyle md-fsize13 sm-fsize12 fsize14"
                >
                  {data ? data['1504186873'] : 'no-data'}
                </p>
              </div>
            </div>
            {check === 1 ? (
              <>
                <div className="mt-4">
                  <h5
                    id="2712029709"
                    className="dynamicStyle clr-fff fsize34 cust-textColor sm-fsize20 md-fsize22 font-semibold"
                  >
                    {data ? data['2712029709'] : 'no-data'}
                  </h5>
                  <p
                    id="8267637788"
                    className="dynamicStyle fsize18 sm-fsize14 textColor-light md-fsize14 mt-2 text-gray"
                  >
                    {data ? data['8267637788'] : 'no-data'}
                  </p>
                  <Button
                    px={8}
                    py={5}
                    variant="outline"
                    id="5052612289"
                    className={
                      colorMode === 'light'
                        ? 'dynamicStyle bg-primary clr-fff rounded-lg sm-fsize12 theme-btn lg:mt-5 md:mt-4 mt-3'
                        : 'dynamicStyle theme-btn  cust-textColor sm-fsize12 lg:mt-5 md:mt-4 mt-3'
                    }
                  >
                    <span onClick={() => handleClick('5052612289')}>
                      {data ? data['5052612289'] : 'Button'}
                    </span>
                  </Button>
                </div>
              </>
            ) : null}

            {check === 2 ? (
              <>
                <div className="mt-4">
                  <h5
                    id="5399009899"
                    className="dynamicStyle clr-fff fsize34 cust-textColor sm-fsize20 md-fsize22 font-semibold"
                  >
                    {data ? data['5399009899'] : 'no-data'}
                  </h5>
                  <p
                    id="7027784758"
                    className="dynamicStyle fsize18 sm-fsize14 textColor-light md-fsize14 mt-2 text-gray"
                  >
                    {data ? data['7027784758'] : 'no-data'}
                  </p>
                  <Button
                    px={8}
                    py={5}
                    variant="outline"
                    id="5186453922"
                    className={
                      colorMode === 'light'
                        ? 'dynamicStyle bg-primary clr-fff rounded-lg sm-fsize12 theme-btn lg:mt-5 md:mt-4 mt-3'
                        : 'dynamicStyle theme-btn  cust-textColor sm-fsize12 lg:mt-5 md:mt-4 mt-3'
                    }
                  >
                    <span onClick={() => handleClick('5186453922')}>
                      {data ? data['5186453922'] : 'Button'}
                    </span>
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection39;
