import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const CardLayout3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className="">
        <p
          id="3739957758"
          className="dynamicStyle inline-block fsize34 md-fsize24 fsize20 font-semibold cust-textColor"
        >
          {data ? data['3739957758'] : 'Web 2 Solutions'}
        </p>
        <div id="2933093908" className="dynamicStyle lg:mt-4 md:mt-4 mt-2">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden lg:px-0 md:px-0"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data['2933093908']
              ? data['2933093908'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className={
                          colorMode === 'light'
                            ? ' p-3 w-full rounded-lg cust-shadow themeShadow themeShadow-insert theme-bg'
                            : 'p-3 w-full themeShadow theme-bg rounded-lg'
                        }
                      >
                        <div className="overflow-hidden h-200px rounded-lg w-full  lg:mb-3">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="card"
                            className="h-200px zoom w-full object-cover rounded-lg"
                          />
                        </div>

                        <p className=" cust-textColor fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2">
                          {e.field2}
                        </p>
                        <p className=" textColor-light fsize14 text-center lineClamp2">
                          {e.field3}
                        </p>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
          </Swiper>
        </div>
      </div>
      <div className="mt-12">
      <p
          id="8707324455"
          className="dynamicStyle inline-block fsize34 md-fsize24 fsize20 font-semibold cust-textColor"
        >
          {data ? data['8707324455'] : 'Web 2 Solutions'}
        </p>
        <div id="6565675174" className="dynamicStyle lg:mt-4 md:mt-4 mt-2">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden lg:px-0 md:px-0"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data['6565675174']
              ? data['6565675174'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className={
                          colorMode === 'light'
                            ? ' p-3 w-full rounded-lg cust-shadow themeShadow themeShadow-insert theme-bg'
                            : 'p-3 w-full themeShadow theme-bg rounded-lg'
                        }
                      >
                        <div className="overflow-hidden h-200px rounded-lg w-full  lg:mb-3">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="card"
                            className="h-200px zoom w-full object-cover rounded-lg"
                          />
                        </div>

                        <p className=" cust-textColor fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2">
                          {e.field2}
                        </p>
                        <p className=" textColor-light fsize14 text-center lineClamp2">
                          {e.field3}
                        </p>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
          </Swiper>
        </div>
      </div>
      <div className="mt-12">
      <p
          id="9852877892"
          className="dynamicStyle inline-block fsize34 md-fsize24 fsize20 font-semibold cust-textColor"
        >
          {data ? data['9852877892'] : 'Web 2 Solutions'}
        </p>
        <div id="1015341789" className="dynamicStyle lg:mt-4 md:mt-4 mt-2">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden lg:px-0 md:px-0"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data['1015341789']
              ? data['1015341789'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card
                        className={
                          colorMode === 'light'
                            ? ' p-3 w-full rounded-lg cust-shadow themeShadow themeShadow-insert theme-bg'
                            : 'p-3 w-full themeShadow theme-bg rounded-lg'
                        }
                      >
                        <div className="overflow-hidden h-200px rounded-lg w-full  lg:mb-3">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="card"
                            className="h-200px zoom w-full object-cover rounded-lg"
                          />
                        </div>

                        <p className=" cust-textColor fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2">
                          {e.field2}
                        </p>
                        <p className=" textColor-light fsize14 text-center lineClamp2">
                          {e.field3}
                        </p>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CardLayout3;
