import { Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const VideoSection1 = ({ pageName }) => {
    const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <>
      <div className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="">
          <p
            id="4294199660"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary cust-textColor font-semibold dynamicStyle"
          >
            {data ? data['4294199660'] : 'Lorem'}
          </p>
          <p
            id="9014864018"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor dynamicStyle"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9014864018']
                : 'Prepare for a promising career by acquiring essential',
            }}
          ></p>
        </div>
        <div className="lg:flex md:flex gap-12 w-full lg:mt-12 md:mt-12 mt-6">
          <div className="lg:w-/12 md:w-1/2 w-full">
            <iframe
              src="https://www.youtube-nocookie.com/embed/kkipHKSh3i8?si=b3_X-QHhyJlp-mhn&amp;controls=0"
              title="Example Iframe"
              height="288"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              refreerepolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ border: 'none' }}
            />
            <div id="8180284360" className="lg:mt-6 md:mt-6 mt-4 dynamicStyle2">
              <Image
                src={data ? getImage(data['8180284360']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="video image"
                className="object-contain"
              />
            </div>
          </div>
          <div
            id="6424292994"
            className="dynamicStyle lg:w-1/2 md:w-1/2 w-full"
          >
            {data && data['6424292994']
              ? data['6424292994'].map((e, index) => {
                  return (
                    <div className="flex w-full lg:gap-6 md:gap-6 gap-5 lg:pb-8 md:pb-6 pb-4">
                      <p
                      className={
                        colorMode === 'light'
                          ? 'w-1/5 text-primary flex items-center justify-center lg:mt-1 md:mt-1  fsize18 md-fsize18 sm-fsize14 text-center custpointwh sm-wh rounded-full bg-feefc4'
                          : 'w-1/5 cust-bgColor cust-textColor flex items-center justify-center lg:mt-1 md:mt-1  fsize18 md-fsize18 sm-fsize14 text-center custpointwh sm-wh rounded-full'
                      }
>
                        {e.field1}
                      </p>
                      <div className="w-4/5">
                        <p className="fsize20 md-fsize18 sm-fsize16 font-semibold text-black cust-textColor">
                          {e.field2}
                        </p>
                        <p
                          className="textColor-light sm-fsize14 pt-2"
                          dangerouslySetInnerHTML={{
                            __html: e.field3,
                          }}
                        ></p>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSection1;
