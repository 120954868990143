import { Badge, Card, Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { handleScroll } from './fadeeffect/FadeEffect';

const Blogs2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
        <div className="lg:flex md:flex block justify-center items-center">
          <div className="lg:w-4/12 md:w-4/12 w-full">
            <p
              id="4374141076"
              className="dynamicStyle cust-textColor fsize16 md-fsize14 sm-fsize13 text-primary font-semibold"
            >
              {data ? data['4374141076'] : 'Lorem ipsum'}
            </p>
            <h5
              id="9984730997"
              className="dynamicStyle cust-textColor fsize32 md-fsize26 sm-fsize24 font-semibold"
            >
              {data ? data['9984730997'] : 'Lorem ipsum'}
            </h5>
            <p
              id="0975279666"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0975279666']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
              className="dynamicStyle textColor-light fsize18 md-fsize16 sm-fsize14 mt-2"
            ></p>
          </div>
          <div className="lg:w-8/12 md:w-8/12 w-full lg:mt-0 md:mt-0 mt-4">
            <Swiper
              spaceBetween={25}
              slidesPerView={3.2}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper lg:p-2"
              autoplay={{
                delay: '1000',
              }}
              loop
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {allBlog
                ? allBlog.map(item => (
                    <SwiperSlide>
                      <Card className="cursor-pointer cust-shadow rounded">
                        <div className="w-full relative">
                          <div className="overflow-hidden rounded-lg">
                            <Image
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              className="w-full lg:h-48 h-44 rounded-lg custimghover"
                              alt="image"
                            />
                          </div>
                          <div className="absolute bottom-0 left-0 m-3 text-center bg-white px-3 py-1 rounded-full">
                            <p className="fsize13 font-normal">
                              {new Date(item.createdAt).toLocaleDateString(
                                'en-US',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="flex justify-between items-center">
                            <p className="fsize14 cust-textColor">
                              {new Date(item.createdAt).toLocaleTimeString(
                                'en-US',
                                {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}
                            </p>

                            <div className="">
                              <Badge
                                borderRadius="full"
                                px="3"
                                py="0.5"
                                colorScheme="green"
                                className="fsize14 cust-textColor"
                              >
                                {item.categoryID[0].categoryName}
                              </Badge>
                            </div>
                          </div>
                          <div className="py-2">
                            <p className="fsize18 sm-fsize16 font-semibold pb-2 cust-textColor">
                              {item.title}
                            </p>
                            {/* <p
                        className="fsize14 lineClamp3 textColor-light"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p> */}
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))
                : 'no-data'}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs2;
