import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const HelpSupport1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div>
      <div
        className={
          colorMode === 'light'
            ? 'h-300px sm-h-250px flex items-center justify-center bg-primary theme-bg themeShadow'
            : 'h-300px sm-h-250px flex items-center justify-center cust-bgColor theme-bg themeShadow'
        }
      >
        <div className="lg:w-3/5 md:w-4/5 w-full px-5 text-center">
          <h3
            id="8674476617"
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize32 sm-fsize20 font-medium pb-2'
                : 'dynamicStyle fsize32 sm-fsize20 font-medium pb-2 cust-textColor'
            }
          >
            {data ? data['8674476617'] : 'Lorem Ipsum'}
          </h3>
          <p
            id="8563695398"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8563695398']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize14'
                : 'dynamicStyle fsize14 textColor-light'
            }
          ></p>
        </div>
      </div>
      <div
        id="6445661510"
        className="dynamicStyle container mx-auto lg:px-12 md:px-8 px-5 lg:pt-14 md:pt-10 pt-8"
      >
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2.5,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
          }}
        >
          {/* <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
          {data && data['6445661510']
            ? data['6445661510'].map(e => {
                return (
                  <SwiperSlide>
                    <div
                      className={
                        colorMode === 'light'
                          ? 'p-4 bg-fdf3f3 rounded-lg themeShadow theme-bg'
                          : 'p-4 cust-bgColor rounded-lg themeShadow theme-bg'
                      }
                    >
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="img"
                        className="h-20 w-20 mb-4 mx-auto"
                      />
                      <div className="text-center">
                        <p className="fsize18 sm-fsize16 font-semibold pb-2 cust-textColor">
                          UI/UX
                        </p>
                        <p
                          className={
                            colorMode === 'light'
                              ? 'pb-6 sm-fsize14'
                              : 'pb-6 sm-fsize14 textColor-light'
                          }
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p>
                        <p className="font-semibold cust-textColor">
                          Read more
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'no-data'}
          {/* <SwiperSlide>
          <Card className="p-4 bg-fdf3f3 rounded-lg ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-20 mb-4 mx-auto"
            />
            <div className="text-center">
              <p className="fsize18 sm-fsize16 font-semibold pb-2">UI/UX</p>
              <p className="pb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="font-semibold">Read more</p>
            </div>
          </Card>
          </SwiperSlide>
          <SwiperSlide>
          <Card className="p-4 bg-fdf3f3 rounded-lg">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-20 mb-4 mx-auto"
            />
            <div className="text-center">
              <p className="fsize18 sm-fsize16 font-semibold pb-2">UI/UX</p>
              <p className="pb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="font-semibold">Read more</p>
            </div>
          </Card>
          </SwiperSlide>
          <SwiperSlide>
          <Card className="p-4 bg-fdf3f3 rounded-lg">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-20 mb-4 mx-auto"
            />
            <div className="text-center">
              <p className="fsize18 sm-fsize16 font-semibold pb-2">UI/UX</p>
              <p className="pb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="font-semibold">Read more</p>
            </div>
          </Card>
          </SwiperSlide>
          <SwiperSlide>
          <Card className="p-4 bg-fdf3f3 rounded-lg">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-20 mb-4 mx-auto"
            />
            <div className="text-center">
              <p className="fsize18 sm-fsize16 font-semibold pb-2">UI/UX</p>
              <p className="pb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="font-semibold">Read more</p>
            </div>
          </Card>
          </SwiperSlide> */}
        </Swiper>
      </div>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:pt-10 md:pt-10 pt-4 lg:pb-28 md:pb-24 pb-28">
        <div className="lg:w-3/4 md:w-3/4 w-full mx-auto text-center">
          <h3
            id="9278240054"
            className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
          >
            {data ? data['9278240054'] : 'Lorem ipsum'}
          </h3>
          <p
            id="7011425847"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['7011425847']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize14'
                : 'dynamicStyle fsize14 textColor-light'
            }
          ></p>
        </div>
        <div className="lg:pt-16 md:pt-12 pt-8 ">
          <Accordion id="5872416536" className="dynamicStyle" allowToggle>
            {data && data['5872416536']
              ? data['5872416536'].map(e => {
                  return (
                    <AccordionItem
                      mb={4}
                      className={
                        colorMode === 'light'
                          ? 'bg-dcdcdc theme-bg themeShadow theme-border-0'
                          : 'cust-bgColor theme-bg themeShadow theme-border-0'
                      }
                    >
                      {({ isExpanded }) => (
                        <>
                          <h2>
                            <AccordionButton className="cust-textColor">
                              <Box as="span" flex="1" p={1} textAlign="left">
                                {e.field1}
                              </Box>
                              {isExpanded ? (
                                <MinusIcon fontSize="12px" />
                              ) : (
                                <AddIcon fontSize="12px" />
                              )}
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            pb={4}
                            dangerouslySetInnerHTML={{
                              __html: e.field2,
                            }}
                            className={
                              colorMode === 'light'
                                ? 'sm-fsize14'
                                : 'textColor-light sm-fsize14'
                            }
                          ></AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  );
                })
              : 'no-data'}
            {/* <AccordionItem mb={4} className="bg-dcdcdc">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" p={1} textAlign="left">
                        Section 3 title
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem mb={4} className="bg-dcdcdc">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" p={1} textAlign="left">
                        Section 3 title
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem mb={4} className="bg-dcdcdc">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" p={1} textAlign="left">
                        Section 4 title
                      </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </AccordionPanel>
                </>
              )}
            </AccordionItem> */}
          </Accordion>
        </div>
      </div>
      <div
        className={
          colorMode === 'light'
            ? 'h-32 bg-dcdcdc relative themeShadow theme-bg'
            : 'h-32 relative cust-bgColor themeShadow theme-bg'
        }
      >
        <div className="container mx-auto lg:px-12 md:px-8 px-5 help1-absl sm-help1-absl">
          <div
            className={
              colorMode === 'light'
                ? 'lg:flex md:flex justify-between gap-10 items-center bg-fff lg:py-6 md:py-6 py-4 lg:px-6 md:px-6 px-4 cust-shadow rounded-lg themeShadow theme-bg'
                : 'lg:flex md:flex justify-between gap-10 items-center cust-bgColor lg:py-6 md:py-6 py-4 lg:px-6 md:px-6 px-4 cust-shadow rounded-lg themeShadow theme-bg'
            }
          >
            <div className="lg:mb-0 md:mb-0 mb-4">
              <p
                id="7704852264"
                className="dynamicStyle fsize22 sm-fsize18 font-semibold cust-textColor"
              >
                {data ? data['7704852264'] : 'Lorem Ipsum'}
              </p>
              <p
                id="3632002229"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['3632002229']
                    : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                }}
                className={
                  colorMode === 'light' ? 'dynamicStyle fsize14' : 'dynamicStyle fsize14 textColor-light'
                }
              ></p>
            </div>
            <div className="">
              <Button
                size="md"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle w-full bdr-0px bg-primary clr-fff theme-btn sm-fsize14 md-fsize14'
                    : 'dynamicStyle w-full bdr-0px bg-primary cust-textColor theme-btn sm-fsize14 md-fsize14'
                }
                id="4174864605"
              >
                <span onClick={() => handleClick('4174864605')}>
                  {data ? data['4174864605'] : 'Submit'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSupport1;
