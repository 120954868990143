import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createContact } from "../../../redux/marcom/marcom-action";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const ContactDetails5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = "https://example.com";

  const [phone, setPhone] = useState("");

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    description: "",
    countryCode: "",
  };

  const validationRules = Yup.object({
    name: Yup.string()
      // .min(3, "Name must be at least 3 characters")
      .max(25, "Name must be at most 25 characters")
      .required("Name is required")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required("Mobile number is required"),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),
    description: Yup.string()
      .trim()
      // .min(3, "Message must be at least 3 characters")
      .max(100, "Message must be at most 100 characters")
      .required("Message is required"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      mobile: values.mobile,
      description: values.description,
      countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));
      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Will get back to you shortly`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Will get back to you shortly`);
        formik.handleReset();
        formik.setFieldValue("description", "");
        formik.setFieldValue("countryCode", "");
        setPhone("");
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    setPhone(value);
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
  };

  return (
    <div className="w-full flex relative h-100vh md-h-600px ">
      <div
        className={
          colorMode === "light"
            ? "w-70 h-40 bg-fff h-100vh md-h-600px  lg:block md:block hidden"
            : "w-70 h-40 cust-bgColor h-100vh md-h-600px  lg:block md:block hidden"
        }
      ></div>
      <div
        className={
          colorMode === "light"
            ? "w-30 h-40 bg-primary h-100vh md-h-600px  lg:block md:block hidden"
            : "w-30 h-40 bg-darkprimary h-100vh md-h-600px  lg:block md:block hidden"
        }
      ></div>

      <div className="absolute top-0 left-0 w-full h-100vh md-h-600px flex items-center justify-center">
        <div className="container mx-auto lg:py-20 md:py-12 py-8 lg:px-12 md:px-8 px-5">
          <div className="lg:flex md:flex items-center lg:gap-16 md:gap-8 w-full">
            <div className="lg:w-1/2 md:w-1/2 w-full">
              <h2
                id="1282566755"
                className="dynamicStyle fsize48 md-fsize28 sm-fsize20 font-bold text-primary cust-textColor lg:mb-4 md:mb-3 mb-2"
              >
                {data ? data["1282566755"] : "Get in touch"}
              </h2>
              <p
                id="9338429176"
                className="dynamicStyle text-black fsize14 sm-fsize12 lg:pr-8 textColor-light"
                dangerouslySetInnerHTML={{
                  __html: data ? data["9338429176"] : "Lorem Ipsum",
                }}
              ></p>
              <form onSubmit={formik.handleSubmit}>
                <div className="lg:mt-12 md:mt-12 mt-8">
                  <FormControl
                    className="lg:mb-6 md:mb-6 mb-4"
                    isInvalid={!!formik.errors.name && formik.touched.name}
                  >
                    <Input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      placeholder="Name *"
                      height="3rem"
                      borderRadius="2px"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <FormErrorMessage mt={1}>
                        {formik.errors.name}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    className="lg:mb-6 md:mb-6 mb-4"
                    isInvalid={!!formik.errors.email && formik.touched.email}
                  >
                    <Input
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      placeholder="Email"
                      height="3rem"
                      borderRadius="2px"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormErrorMessage mt={1}>
                        {formik.errors.email}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    className="lg:mb-6 md:mb-6 mb-4"
                    isInvalid={!!formik.errors.mobile && formik.touched.mobile}
                  >
                    <PhoneInput
                      name="mobile"
                      country={"sg"}
                      enableSearch={true}
                      placeholder="Phone number *"
                      height="3rem"
                      borderRadius="2px"
                      value={phone}
                      onChange={handlePhoneChange}
                      className="mt-2 phoneinput w-full mt-2 themefamily"
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <FormErrorMessage mt={1}>
                        {formik.errors.mobile}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={
                      !!formik.errors.description && formik.touched.description
                    }
                  >
                    <Textarea
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      placeholder="Message"
                      height="3rem"
                      borderRadius="2px"
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <FormErrorMessage mt={1}>
                          {formik.errors.description}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                  <div className="dynamicStyle" id="0825047576">
                    <Button
                      type="submit"
                      height="3rem"
                      borderRadius="2px"
                      className={
                        colorMode === "light"
                          ? " w-full bg-primary mt-6 clr-fff theme-btn"
                          : " w-full  mt-6 clr-fff theme-btn"
                      }
                    >
                      <span
                        className="flex items-center"
                        // onClick={() => handleClick("0825047576")}
                      >
                        {data ? data["0825047576"] : "Send"}
                      </span>
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
              {/* <iframe
                className="w-full h-550px md-h-450 sm-h-350px"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7709023962652!2d103.90097507499006!3d1.3128967616934797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da180e0fb5888d%3A0xfffd54b242c84074!2s47%20Joo%20Chiat%20Ln%2C%20Singapore%20428106!5e0!3m2!1sen!2sin!4v1705597718184!5m2!1sen!2sin"
                width="100%"
                allowFullScreen=""
                loading="lazy"
                title="map"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails5;
