import React, { useRef, useState } from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
const DownloadSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:py-16 md:py-8 py-6 lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex items-center lg:gap-10 md:gap-6">
        <div id="3350966812"
          className="dynamicStyle2 lg:w-4/12 md:w-4/12 w-full">
          <Image
            src={data ? getImage(data['3350966812']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="h-400px md-h-350px sm-h-250px w-full lg:mb-0 md:mb-0 mb-4"
          />
        </div>
        <div className="lg:w-8/12 md:w-8/12 w-full">
          <div className="lg:mb-8 mb-4">
            <p id="6029140121"
              className="dynamicStyle fsize40 md-fsize28 sm-fsize20 font-semibold lg:pb-2 pb-1 text-black cust-textColor">
              {data ? data['6029140121'] : 'Lorem Ipsum'}
            </p>
            <p
            id="1840104074"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1840104074']
                : ' The SBI Foundation, or State Bank of India Foundation, is the corporate social responsibility (CSR) arm of the State Bank of',
            }}
              className={
                colorMode === 'light'
                  ? 'dynamicStyle sm-fsize14'
                  : 'dynamicStyle sm-fsize14 textColor-light'
              }
            >
            </p>
          </div>
          <div className="flex items-center lg:gap-8 gap-4">
            <div id="2369614681"
          className="dynamicStyle2 lg:w-3/12 w-4/12">
              <Image
                src={data ? getImage(data['2369614681']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="lg:h-48 md:h-48 h-40 w-full"
              />
            </div>
            <div className="lg:w-9/12 w-8/12">
              <p
              id="5730417540"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5730417540']
                  : ' The SBI Foundation, or State Bank of India Foundation, is the corporate social responsibility (CSR) arm of the State Bank of',
              }}
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle sm-fsize14 mb-4'
                    : 'dynamicStyle sm-fsize14 mb-4 textColor-light'
                }
              >
              </p>
              <div className="flex items-center gap-2">
                <FaGooglePlay size={22} className="text-gray textColor-light" />
                <FaApple size={26} className="text-gray textColor-light" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection4;
