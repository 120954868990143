import React from 'react';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Linkedin, Twitter, Mail, Facebook } from 'react-feather';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
const Team15 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let allTeam = useSelector(state => state.Teams.Teams);
  return (
    <section className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="lg:w-2/3 md:w-2/3 text-center container mx-auto flex flex-col justify-center">
        <h3
          id="3521116257"
          className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold sm-fsize20 text-primary"
        >
          {data ? data['3521116257'] : 'MEET OUR BEAUTIFUL TEAM'}
        </h3>
        <hr class="hr-layout2"></hr>
        <p
          id="9297346175"
          className="mt-4 dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text-center"
        >
           {data ? data['9297346175'] : 'We are a small team of designers and developers, who help brands with big ideas.'} 
        </p>
      </div>
      <div className="lg:pt-12 md:pt-10 pt-8">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2.4,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {allTeam && allTeam.length > 0
            ? allTeam.map(e => {
                return (
                  <SwiperSlide>
                    <Card
                      className={
                        colorMode === 'light'
                          ? ' w-full p-4 box-shadow-none themeShadow theme-bg cursor-pointer'
                          : ' w-full p-4 themeShadow themeShadow-insert theme-bg cursor-pointer'
                      }
                    >
                      <div className="w-24 h-24 rounded-full mx-auto">
                        <Image
                          className="w-24 h-24 rounded-full"
                          src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="img"
                        />
                      </div>
                      <div className="text-center pt-2">
                        <p className="fsize18 font-semibold cust-textColor">
                          {e.name}
                        </p>
                        <p className="text-gray textColor-light">
                          {e.designation}
                        </p>
                        <p className="text-gray fsize14 textColor-light fsize14 mt-2">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Necessitatibus unde illo voluptatum facilis
                          vitae laborum eligendi vel ea magni nulla
                        </p>
                        <div className="flex gap-3 pt-4 justify-center">
                          <div
                            className={
                              colorMode === 'light'
                                ? ' filled-social-icons'
                                : 'social-icons-darkmode'
                            }
                          >
                            <Facebook
                              width={'20px'}
                              height={'20px'}
                              className={
                                colorMode === 'light'
                                  ? 'text-506380 cust-textColor fill-icon-white'
                                  : 'text-506380 cust-textColor'
                              }
                            />
                          </div>
                          <div
                            className={
                              colorMode === 'light'
                                ? ' filled-social-icons'
                                : 'social-icons-darkmode'
                            }
                          >
                            <Twitter
                              width={'20px'}
                              height={'20px'}
                              className={
                                colorMode === 'light'
                                  ? 'text-506380 cust-textColor fill-icon-white'
                                  : 'text-506380 cust-textColor'
                              }
                            />
                          </div>
                          <div
                            className={
                              colorMode === 'light'
                                ? ' filled-social-icons'
                                : 'social-icons-darkmode'
                            }
                          >
                            <Linkedin
                              width={'20px'}
                              height={'20px'}
                              className={
                                colorMode === 'light'
                                  ? 'text-506380 cust-textColor fill-icon-white'
                                  : 'text-506380 cust-textColor'
                              }
                            />
                          </div>
                          <div
                            className={
                              colorMode === 'light'
                                ? ' filled-social-icons'
                                : 'social-icons-darkmode'
                            }
                          >
                            <Mail
                              width={'20px'}
                              height={'20px'}
                              className={
                                colorMode === 'light'
                                  ? 'text-506380 cust-textColor fill-icon-white'
                                  : 'text-506380 cust-textColor'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
        </Swiper>
      </div>
    </section>
  );
};

export default Team15;
