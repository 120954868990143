import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-20 md:px-12 px-5 ">
        <div className="lg:flex md:flex items-center lg:gap-10 md:gap-6 relative">
          <div id="7281962542"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 h-500px md-h-400px sm-h-250px lg:mb-0 md:mb-0 mb-6">
            <Image
              src={data ? getImage(data['7281962542']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-500px md-h-400px sm-h-250px w-full z-ind-1"
            />
          </div>
          <div className="lg:w-1/2 md:w-1/2 ">
            <p
              id="5328164855"
              className="dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
            >
              {data ? data['5328164855'] : 'Lorem Ipsum'}
            </p>
            <p
              id="2393093350"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2393093350']
                  : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
              }}
              className="dynamicStyle text-gray textColor-light sm-fsize14"
            ></p>

            <div className="flex gap-4 mt-2">
              <Input
                type="text"
                size="lg"
                placeholder="Email Address"
                className="w-8/12 bdr-0px theme-border"
              />
              <Button
                size="lg"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle w-4/12 bdr-0px bg-primary theme-btn sm-fsize14 md-fsize14'
                    : 'dynamicStyle w-4/12 bdr-0px cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
                }
                id="3754250557"
              >
                 <span onClick={() => handleClick('3754250557')}>
              {data ? data['3754250557'] : 'Submit'}
            </span>
              </Button>
            </div>
          </div>
          <div className="lg:h-24 lg:w-24 md:h-20 md:w-20 rounded-full bg-primary absolute top-0 right-0 mob-hidden"></div>
          <div className="lg:h-36 lg:w-36 md:h-32 md:w-32 rounded-full border-20px absolute absl-news9-1 md-absl-news9-1 mob-hidden"></div>
          <div className="h-6 w-6 rounded-full bg-primary absolute absl-news9-2 mob-hidden"></div>
        </div>
      </div>
      <div className="lg:h-56 lg:w-56 md:h-48 md:w-48 rounded-full border-16px absolute absl-news9-3 mob-hidden"></div>
    </div>
  );
};

export default NewsletterSection9;
