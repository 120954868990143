import React, { useEffect, useRef, useState } from "react";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import Marquee from "react-fast-marquee";

const Clients3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const Client = [
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      img: "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
  ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:pt-16 md:pt-16 pt-12 lg:px-12 md:px-8 px-5">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
        <h2
          id="5369058283"
          className="dynamicStyle pb-2 fsize34 md-fsize24 sm-fsize24 text-center font-semibold cust-textColor"
        >
          {data ? data["5369058283"] : " Our Clients"}
        </h2>
        <p
          id="5186270664"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["5186270664"]
              : "We are united by our passion for innovation and our commitment",
          }}
        ></p>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? "visiblebottom" : ""} `}
      >
        <div id="0339448071" className="dynamicStyle lg:mt-12 md:mt-12 mt-8">
          <Marquee loop={0} autoFill={true}>
            {data && data["0339448071"]
              ? data["0339448071"].map((e) => {
                  return (
                    <div className="mx-0">
                      <Image
                        src={e ? getImage(e.field1) : ""}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                        className="rounded-md img-ratio lg:h-40 md:h-32 h-40 px-5"
                      />
                    </div>
                  );
                })
              : "no-data"}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Clients3;
