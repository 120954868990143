import React, { useEffect } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const FeatureCard33 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="lg:flex md:flex block w-full">
        <div className="lg:w-4/12 md:w-4/12">
          {' '}
          <div id="0447619267" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['0447619267']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="h-450px sm-h250px w-full rounded-lg"
              alt="cards"
            />
          </div>
        </div>
        <div className="lg:w-8/12 md:w-8/12 lg:ml-8 md:ml-6 lg:mt-0 md:mt-0 mt-4">
          <h4
            className="fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold text-dark dynamicStyle"
            id="3362985580"
          >
            {data ? data['3362985580'] : 'About Us'}
          </h4>
          <p
            id="9917964327"
            className="fsize18 md-fsize16 sm-fsize14 font-medium mt-2 dynamicStyle textColor-light"
          >
            {data ? data['9917964327'] : 'About Us'}
          </p>
          <div
            id="9283014762"
            className="lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid gap-6 mt-12 dynamicStyle"
          >
            {data && data['9283014762']
              ? data['9283014762'].map((e, index) => {
                  return (
                    <div className="">
                      <div>
                        <Image
                          src={e.field1}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          className="w-12 h-12"
                          alt="cards"
                        />
                      </div>
                      <h4 className="fsize24 md-fsize20 sm-fsize18 mt-2 cust-textColor font-semibold text-dark">
                        {e.field2}
                      </h4>
                      <p className="fsize16 md-fsize14 sm-fsize13 text-gray font-medium mt-1 textColor-light">
                        {e.field3}
                      </p>
                    </div>
                  );
                })
              : 'no-card'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard33;
