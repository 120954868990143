import React from 'react';
import { useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import { Image, useColorMode } from '@chakra-ui/react';

const CounterSection5 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [counterOn, setCounterOn] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="bg-primary lg:py-16 md:py-16 py-4 lg:px-16 md:px-4 px-4">
      <div className="lg:flex md:flex items-center justify-between w-full container mx-auto">
        <div className="">
          <h2
            id="3215290451"
            className="dynamicStyle fsize40 md-fsize36 sm-fsize24 font-semibold cust-textColor clr-fff"
          >
           {data ? data['3215290451'] : 'Lorem Ipsum'}
          </h2>
          <p
           id="8227542373"
            className="dynamicStyle fsize20 md-fsize18 sm-fsize16 font-medium text-secondary lg:py-4 md:py-4 py-2 textColor-light"
          >
           {data ? data['8227542373'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div
          className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-12 lg:mt-0 md:mt-0 mt-3'
            
        >
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                      start={0}
                      end={200}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff cust-textColor">
                  +
                </p>
              </div>
              <p
                id="2382535317"
                className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
                {data ? data['2382535317'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                      start={0}
                      end={100}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff cust-textColor">
                  +
                </p>
              </div>
              <p
                id="4757730704"
                className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
               {data ? data['4757730704'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>
          <div className=" flex justify-center items-center">
            <div>
              <div className="flex items-center justify-center text-primary">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <CountUp
                      className="font-bold fsize50 md-fsize30 sm-fsize24 clr-fff cust-textColor"
                      start={0}
                      end={83}
                      duration={4}
                      delay={0}
                    />
                  )}
                </ScrollTrigger>
                <p className=" fsize36 md-fsize36 sm-fsize24 font-semibold pb-1 clr-fff cust-textColor">
                  +
                </p>
              </div>
              <p
                id="3943088763"
                className="dynamicStyle text-center fsize22 md-fsize16 sm-fsize13 text-535353 font-semibold text-secondary textColor-light"
              >
                {data ? data['3943088763'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection5;
