import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const ProductSection11 = ({ pageName }) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="flex justify-between gap-4 items-center mb-8">
        <div>
          <p
          id="8500807294"
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize18 sm-fsize16'
                : 'dynamicStyle fsize18 sm-fsize16 cust-textColor'
            }
          >
            {data ? data['8500807294'] : 'Lorem Ipsum'}
          </p>
          <p
           id="6199813878"
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize28 sm-fsize20 font-semibold'
                : 'dynamicStyle fsize28 sm-fsize20 font-semibold textColor-light'
            }
          >
            {data ? data['6199813878'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div>
          <Button
            variant="outline"
            colorScheme="primary"
            className="dynamicStyle theme-radiusfull text-primary cust-textColor sm-fsize14 theme-btn bdr-2px "
            size="md"
            id="4374074513"
          >
            <span className="flex items-center">
            <span onClick={() => handleClick('4374074513')}>
            {data ? data['4374074513'] : 'READ MORE'}
          </span>
              <FeatherIcon
                className="text-primary cust-textColor ml-3"
                size={18}
                icon="chevron-right"
              />
            </span>
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6">
        <Card
          className={
            colorMode === 'light'
              ? 'p-2 cust-shadow rounded-md themeShadow theme-bg'
              : 'p-2 cust-shadow rounded-md themeShadow theme-bg'
          }
        >
          <div className="h-56 w-full ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-56 w-full rounded-md"
            />
          </div>
          <div className="my-2">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 sm-fsize18 font-semibold'
                  : 'fsize20 sm-fsize18 font-semibold cust-textColor'
              }
            >
              Lorem ipsum dolor sit.
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray py-2 fsize14'
                  : 'text-gray py-2 fsize14 textColor-light'
              }
            >
              Gender : Male • Age : 02 Months
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'font-semibold'
                  : 'font-semibold cust-textColor'
              }
            >
              8,900
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'p-2 cust-shadow rounded-md themeShadow theme-bg'
              : 'p-2 cust-shadow rounded-md themeShadow theme-bg'
          }
        >
          <div className="h-56 w-full ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-56 w-full rounded-md"
            />
          </div>
          <div className="my-2">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 sm-fsize18 font-semibold'
                  : 'fsize20 sm-fsize18 font-semibold cust-textColor'
              }
            >
              Lorem ipsum dolor sit.
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray py-2 fsize14'
                  : 'text-gray py-2 fsize14 textColor-light'
              }
            >
              Gender : Male • Age : 02 Months
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'font-semibold'
                  : 'font-semibold cust-textColor'
              }
            >
              8,900
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'p-2 cust-shadow rounded-md themeShadow theme-bg'
              : 'p-2 cust-shadow rounded-md themeShadow theme-bg'
          }
        >
          <div className="h-56 w-full ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-56 w-full rounded-md"
            />
          </div>
          <div className="my-2">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 sm-fsize18 font-semibold'
                  : 'fsize20 sm-fsize18 font-semibold cust-textColor'
              }
            >
              Lorem ipsum dolor sit.
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray py-2 fsize14'
                  : 'text-gray py-2 fsize14 textColor-light'
              }
            >
              Gender : Male • Age : 02 Months
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'font-semibold'
                  : 'font-semibold cust-textColor'
              }
            >
              8,900
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'p-2 cust-shadow rounded-md themeShadow theme-bg'
              : 'p-2 cust-shadow rounded-md themeShadow theme-bg'
          }
        >
          <div className="h-56 w-full ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-56 w-full rounded-md"
            />
          </div>
          <div className="my-2">
            <p
              className={
                colorMode === 'light'
                  ? 'fsize20 sm-fsize18 font-semibold'
                  : 'fsize20 sm-fsize18 font-semibold cust-textColor'
              }
            >
              Lorem ipsum dolor sit.
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray py-2 fsize14'
                  : 'text-gray py-2 fsize14 textColor-light'
              }
            >
              Gender : Male • Age : 02 Months
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'font-semibold'
                  : 'font-semibold cust-textColor'
              }
            >
              8,900
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProductSection11;
