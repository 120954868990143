import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-12 px-5 ">
      <div className="lg:flex md:flex items-center gap-8">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-4">
          <p
            id="3929458806"
            className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor"
          >
            {data ? data['3929458806'] : 'Lorem Ipsum'}
          </p>
          <p
            id="1905364809"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1905364809']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className={
              colorMode === 'light'
                ? 'dynamicStyle sm-fsize14'
                : 'dynamicStyle textColor-light sm-fsize14'
            }
          ></p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full flex gap-4">
          <div className="relative w-9/12">
            <Input
              type="text"
              size="lg"
              placeholder="Email"
              className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
            />
            <FeatherIcon
              className="absolute top-3 right-6 text-gray cust-textColor"
              size={20}
              icon="search"
            />
          </div>
          <Button
            size="lg"
            className={
              colorMode === 'light'
                ? 'dynamicStyle w-3/12 bdr-0px bg-primary theme-btn sm-fsize14 md-fsize14'
                : 'dynamicStyle w-3/12 bdr-0px cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
            }
            id="8508664826"
          >
            <span onClick={() => handleClick('8508664826')}>
              {data ? data['8508664826'] : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection12;
