import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  Progress,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const PollSection5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <h3
            id="1840635012"
          className="dynamicStyle inline-block fsize34 md-fsize28 sm-fsize24 font-semibold text-black cust-textColor"
        >
          {data ? data['1840635012'] : 'Poll Question 2'}
        </h3>
        <p
          className="dynamicStyle fsize14 md-fsize14 sm-fsize12 lg:w-2/3 text-black textColor-light"
            id="9004885384"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9004885384']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
        >
        </p>
        <div className="lg:py-12 md:py-12 py-6 lg:w-1/2 md:w-1/2">
          <Stack className="relative lg:mb-6 md:mb-6 mb-4 ">
            <Progress
              colorScheme="gray"
              className="rounded-full themeShadow"
              height="42px"
              value={50}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2 left-0">
              <div>
                <p className="text-black fsize15 sm-fsize14">2023-2024</p>
              </div>
              <div>
                <p className="text-black fsize15 sm-fsize14">50%</p>
              </div>
            </div>
          </Stack>
          <Stack className="relative lg:mb-6 md:mb-6 mb-4 ">
            <Progress
              colorScheme="gray"
              className="rounded-full themeShadow"
              height="42px"
              value={60}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2 left-0">
              <div>
                <p className="text-black fsize15 sm-fsize14">2024-2025</p>
              </div>
              <div>
                <p className="text-black fsize15 sm-fsize14">60%</p>
              </div>
            </div>
          </Stack>
          <Stack className="relative ">
            <Progress
              colorScheme="gray"
              className="rounded-full themeShadow"
              height="42px"
              value={72}
            />
            <div className="flex items-center justify-between w-full px-5 absolute top-2 left-0">
              <div>
                <p className="text-black fsize15 sm-fsize14">2025-2026</p>
              </div>
              <div>
                <p className="text-black fsize15 sm-fsize14">72%</p>
              </div>
            </div>
          </Stack>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup size="sm" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text-black textColor-light dynamicStyle"
                id="9289728588"
              >
                {data ? data['9289728588'] : 'Total votes:'}
              </p>
              <p className="fsize12 text-black textColor-light">24</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PollSection5;
