import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  // Search2Icon,
  SearchIcon,
} from "@chakra-ui/icons";
// import { ArrowRight } from "react-feather";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Image,
  Input,
  // InputGroup,
  // InputRightElement,
  Switch,
  useDisclosure,
  // MenuButton,
  // MenuList,
  // Menu,
  // MenuItem,
  Card,
  useColorMode,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux/es/exports";
import { selectSwitchContrast } from "../../../redux/menu/menu-actions";
// import GoogleTranslate from "../tarnslate/Googletranslate";
// import { getAllCaseStudy } from "../../../redux/caseStudy/cs-action";
// import { useNavigate } from "react-router-dom";
// import { XIcon } from "react-share";
const Header6 = () => {
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menuState.menu);
  const { colorMode } = useColorMode();

  let switchContrast = useSelector(
    (state) => state.menuState.selectedContrastData
  );

  // const navigate = useNavigate();
  // console.log(switchContrast, "switchContrast");
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      // Adjust this value based on when you want the navbar to become fixed
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [megaMenuVisible, setmegaMenuVisible] = useState(false);
  // const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  // const [searchCaseStudy, setSearchCaseStudy] = useState("");
  // const toggleDropdown = () => {
  //   setDropdownVisible(!dropdownVisible);
  // };

  // const toggleMegaMenuDropdown = (visible) => {
  //   setmegaMenuVisible(visible);
  // };
  // const handleSearch = async () => {
  //   navigate("/home");

  //   let payload = {
  //     // columnFilter: "projectname",
  //     searchBy: searchCaseStudy,
  //   };
  //   await dispatch(getAllCaseStudy(payload));
  //   scrollToCaseStudy();
  // };

  // const scrollToCaseStudy = () => {
  //   const caseStudySection = document.getElementById("case-study");
  //   if (caseStudySection) {
  //     caseStudySection.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const btnRef = React.useRef();

  const [isVisible, setIsVisible] = useState(true);

  const handleContrast = () => {
    dispatch(selectSwitchContrast(!switchContrast));
    console.log(switchContrast, "switchContrast");
  };
  const handleClose = () => {
    setIsVisible(false);
  };

  const isMegaMenu = (subMenu) => {
    let isMega = subMenu.every((sub) => sub.subMenu.length > 0);
    return isMega;
  };

  // const [searchVisible, setSearchVisible] = useState(false);

  // const openSearch = () => {
  //   console.log("object");
  //   setSearchVisible(true);
  //   console.log("object1");
  // };

  function openSearch() {
    document.getElementById("isSearch").style.display = "block";
  }

  function closeSearch() {
    document.getElementById("isSearch").style.display = "none";
  }

  if (window.location.pathname === "/login") return null;
  if (window.location.pathname === "/register") return null;
  if (window.location.pathname === "/forgot password") return null;

  return (
    <div>
      <div id="isSearch" className="search-overlay">
        <div className="search-overlay-content relative">
          <div className="flex items-center lg:gap-12 md:gap-12 gap-6 lg:w-2/3 md:w-2/3 w-full mx-auto">
            <form action="" className="relative w-full">
              <Input type="text" placeholder="Search..." name="search" />
              {/* <Button type="submit" p={1}>
                <Search2Icon />
              </Button> */}
            </form>
            <span className="closebtn fsize18 sm-fsize14" onClick={closeSearch} title="close">
              <CloseIcon />
            </span>
          </div>
          <div className="lg:mt-12 md:mt-12 mt-8">
            <Card className="bg-fff text-left py-4 px-6 mb-4">
              <p className="fsize20 sm-fsize16 font-medium text-primary lg:pb-2 md:pb-2 pb-1">Title 1</p>
              <p className="fsize16 sm-fsize12">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Voluptatem, omnis? Impedit aut distinctio rerum itaque omnis
                sunt, vitae cupiditate qui velit, optio tempore, culpa expedita.
              </p>
            </Card>
            <Card className="bg-fff text-left py-4 px-6 mb-4">
              <p className="fsize20 sm-fsize16 font-medium text-primary lg:pb-2 md:pb-2 pb-1">Title 1</p>
              <p className="fsize16 sm-fsize12">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Voluptatem, omnis? Impedit aut distinctio rerum itaque omnis
                sunt, vitae cupiditate qui velit, optio tempore, culpa expedita.
              </p>
            </Card>
          </div>
        </div>
      </div>
      <Card className="header6 relative">
        <div
          className={
            colorMode === "light"
              ? "header-6-sub-menu"
              : "header-6-sub-menu dark-bottom-border"
          }
        >
          <div className="container mx-auto">
            <div className="header-6-sub-menu-container lg:px-12 md:px-8 px-5 lg:py-0 md:py-6 py-4">
              <div className="lg:w-1/2 flex items-center lg:mb-0 md:mb-0 mb-3">
                {/* <GoogleTranslate /> */}
                <p className="px-2 cust-textColor">
                  <FormControl display="flex" alignItems="center" gap={2}>
                    <Switch
                      isChecked={switchContrast}
                      size="sm"
                      id="switchContrast"
                      onChange={handleContrast}
                    />
                    <FormLabel
                      htmlFor="email-alerts"
                      ml="4px"
                      mr="0px"
                      mb="0"
                      fontWeight={600}
                      className="fsize12"
                    >
                      High Contrast
                    </FormLabel>
                  </FormControl>
                </p>
                <div className="px-2 flex items-center gap-2 cust-textColor">
                  <FaUser className="w-4 h-4 rounded-full" />
                  <NavLink to="/contact-us">
                    <p className="fsize12 font-semibold">Contact Sales</p>
                  </NavLink>
                </div>
              </div>
              <div className="lg:w-1/2 flex justify-end items-center gap-4">
                {/* <InputGroup size="md" width="12rem" className="cust-textColor">
                  <Input
                    pr="3rem"
                    pl="0.5rem"
                    className="fsize12"
                    placeholder="Search Here..."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    value={searchCaseStudy}
                    onChange={(e) => setSearchCaseStudy(e.target.value)}
                  />
                  <InputRightElement width="3rem" onClick={handleSearch}>
                    <Button h="1.75rem" size="sm">
                      <Search2Icon />
                    </Button>
                  </InputRightElement>
                </InputGroup> */}

                <Button type="submit" onClick={openSearch}>
                  <SearchIcon />
                </Button>
                <ul className="flex items-center gap-2 list-none">
                  <li className="px-2 fsize12 font-semibold cust-textColor">
                    <NavLink target="_blank" to="https://app.brained.app/login">
                      Log in
                    </NavLink>
                  </li>
                  {/* <li className="px-2 fsize12 font-semibold"><NavLink target="_blank" to="/app.brained.app/register">Register</NavLink></li> */}
                  <li className="px-2 fsize12 font-semibold cust-textColor">
                    Customer Support
                  </li>
                  <li className="px-2 py-4 fsize12 font-semibold cust-textColor header6SubDropdown">
                    <div className="header6SubDropdownDiv cust-textColor">
                      About <ChevronDownIcon />
                    </div>
                    <div className="header6SubDropdown-content cust-bgColor">
                      <NavLink className="cust-textColor" to="/about-us">
                        About us
                      </NavLink>
                      <NavLink className="cust-textColor" to="/careers">
                        Careers
                      </NavLink>
                      <NavLink className="cust-textColor" to="/contact-us">
                        Contact us
                      </NavLink>
                      {/* <NavLink
                        className="cust-textColor"
                        to="/investor-relations"
                      >
                        Investor Relations
                      </NavLink>
                      <NavLink className="cust-textColor" to="/teams">
                        Management Team
                      </NavLink> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            colorMode === "light"
              ? `header-6-menu ${isFixed ? "header-6-Sticky" : ""}`
              : `header-6-menu cust-bgColor dark-bottom-border ${
                  isFixed ? "header-6-Sticky" : ""
                }`
          }
        >
          <div className="container mx-auto">
            <div className="header-6-menu-container lg:px-12 md:px-8 px-5 ">
              <div className="flex items-center header6Height sm-w-100">
                <div className="sm-w-50 ">
                  <NavLink to="/">
                    <Image
                      src={getPrimaryImage(brandProfile)}
                      alt="logo"
                      className="w-24 h-16 object-contain "
                    />
                  </NavLink>
                </div>

                <div className="lg:hidden md:hidden block sm-w-50">
                  <div className=" flex gap-2 justify-end">
                    <Button
                      background="transparent"
                      type="submit"
                      onClick={openSearch}
                    >
                      <SearchIcon />
                    </Button>

                    <Button onClick={onOpenMenu}>
                      <HamburgerIcon />
                    </Button>
                    <Drawer
                      isOpen={isOpenMenu}
                      placement="right"
                      onClose={onCloseMenu}
                      finalFocusRef={btnRef}
                    >
                      <DrawerContent maxWidth="75%">
                        <DrawerHeader>
                          <NavLink to="">
                            <h2 className="text-white fsize20 font-semibold">
                              Menu
                            </h2>
                          </NavLink>{" "}
                          <DrawerCloseButton
                            top="16px"
                            className="text-white"
                          />
                        </DrawerHeader>
                        <DrawerBody px={4}>
                          <ul className="list-none subMenu ">
                            {menuData
                              ? menuData.map((e, i) => (
                                  <>
                                    {e.menutype === "page" &&
                                    e.subMenu.length < 1 ? (
                                      <li
                                        key={i}
                                        className={
                                          colorMode === "light"
                                            ? "px-2 py-2 li-height bottom-border-e2e8f0 sm-fsize14"
                                            : "px-2 py-2 cust-textColor li-height bottom-border-4f5765 sm-fsize14"
                                        }
                                      >
                                        <NavLink to={`/${e.pageData[0].page}`}>
                                          {e.menuName}
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    )}

                                    {/* SUB_MENU */}

                                    {/* <Accordion allowToggle>
                                      {e.menutype === "page" &&
                                      e.subMenu.length > 0 ? (
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton
                                              backgroundColor="transparent"
                                              py={2}
                                              px={2}
                                            >
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                              >
                                                {e.menuName}
                                              </Box>
                                              <AccordionIcon className="text-primary" />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel p={0} pb={2}>
                                            <ul className="header-6-dropdown">
                                              <li className="header-6-dropdown-item bg-white">
                                                <a href="#team" className="">
                                                  Team
                                                </a>
                                              </li>
                                              <li className="header-6-dropdown-item bg-white">
                                                <a href="#careers" className="">
                                                  Careers
                                                </a>
                                              </li>
                                            </ul>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      ) : (
                                        ""
                                      )}
                                    </Accordion> */}
                                    <Accordion allowToggle>
                                      {e.subMenu.length > 0 ? (
                                        <AccordionItem borderTopWidth={0}>
                                          <h2>
                                            <AccordionButton
                                              backgroundColor="transparent"
                                              py={2}
                                              px={2}
                                            >
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                className="cust-textColor sm-fsize14"
                                              >
                                                {e.menuName}
                                              </Box>
                                              <AccordionIcon className="text-primary" />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel p={0}>
                                            <div className="cust-bgColor header-6-megaMenu grid grid-cols-1">
                                              {e.subMenu && e.subMenu.length > 0
                                                ? e.subMenu.map((item) => (
                                                    <div className="header-6-megaMenu-menu">
                                                      <div className="flex items-center mb-2">
                                                        <Image
                                                          src={`${process.env.REACT_APP_STORAGE_URL}${item.menuImage[0]}`}
                                                          alt=""
                                                          className="w-6 h-6 object-contain rounded-full"
                                                        />
                                                        <h3 className="ml-2 cust-textColor">
                                                          {item.menutype ===
                                                          "page" ? (
                                                            <NavLink
                                                              to={`/${item?.pageData[0]?.page}`}
                                                              className="fsize14"
                                                            >
                                                              {item.menuName}
                                                            </NavLink>
                                                          ) : (
                                                            <a
                                                              target="_blank"
                                                              without rel="noreferrer"
                                                              className="cust-textColor"
                                                              href={
                                                                item.menuData.startsWith(
                                                                  "http"
                                                                )
                                                                  ? item.menuData
                                                                  : `http://${item.menuData}`
                                                              }
                                                            >
                                                              {item.menuName}
                                                            </a>
                                                          )}
                                                        </h3>
                                                      </div>
                                                      <ul className="">
                                                        {item.subMenu &&
                                                        item.subMenu.length > 0
                                                          ? item.subMenu.map(
                                                              (i) => (
                                                                <ul className="list-none  pb-2">
                                                                  {i.menutype ===
                                                                  "page" ? (
                                                                    <li>
                                                                      <NavLink
                                                                        to={`/${i.pageData[0].page}`}
                                                                        className="textColor-light header-6-megaMenu-menu-link text-black fsize14"
                                                                      >
                                                                        {
                                                                          i.menuName
                                                                        }
                                                                      </NavLink>
                                                                    </li>
                                                                  ) : (
                                                                    <li>
                                                                      <NavLink
                                                                        target="_blank"
                                                                        className="textColor-light header-6-megaMenu-menu-link text-black fsize14"
                                                                        href={
                                                                          i.menuData.startsWith(
                                                                            "http"
                                                                          )
                                                                            ? i.menuData
                                                                            : `http://${i.menuData}`
                                                                        }
                                                                      >
                                                                        {
                                                                          i.menuName
                                                                        }
                                                                      </NavLink>
                                                                    </li>
                                                                  )}
                                                                </ul>
                                                              )
                                                            )
                                                          : ""}
                                                        {/* <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 2
                                                  </NavLink>
                                                </li>
                                                <li>
                                                  <NavLink
                                                    to=""
                                                    className="header-6-megaMenu-menu-link fsize14"
                                                  >
                                                    Link 3
                                                  </NavLink>
                                                </li> */}
                                                      </ul>
                                                    </div>
                                                  ))
                                                : ""}
                                              {/* <div className="header-6-megaMenu-menu">
                                      <div className="flex items-center mb-3">
                                        <Image
                                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                          alt=""
                                          className="w-8 h-8"
                                        />
                                        <h3 className="ml-2 font-medium text-primary">
                                          Mega Menu{" "}
                                        </h3>
                                      </div>
                                      <ul className="list-none">
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 1
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 2
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 3
                                          </NavLink>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="header-6-megaMenu-menu">
                                      <div className="flex items-center mb-3">
                                        <Image
                                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                          alt=""
                                          className="w-8 h-8"
                                        />
                                        <h3 className="ml-2 font-medium text-primary">
                                          Mega Menu{" "}
                                        </h3>
                                      </div>
                                      <ul className="list-none">
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 1
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 2
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 3
                                          </NavLink>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="header-6-megaMenu-menu">
                                      <div className="flex items-center mb-3">
                                        <Image
                                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                          alt=""
                                          className="w-8 h-8"
                                        />
                                        <h3 className="ml-2 font-medium text-primary">
                                          Mega Menu{" "}
                                        </h3>
                                      </div>
                                      <ul className="list-none">
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 1
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 2
                                          </NavLink>
                                        </li>
                                        <li>
                                          <NavLink
                                            to=""
                                            className="header-6-megaMenu-menu-link fsize14"
                                          >
                                            Link 3
                                          </NavLink>
                                        </li>
                                      </ul>
                                    </div> */}
                                            </div>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      ) : (
                                        ""
                                      )}
                                    </Accordion>
                                  </>
                                ))
                              : ""}
                            <Accordion allowToggle>
                              <AccordionItem borderTopWidth={0}>
                                <h2>
                                  <AccordionButton py={2} px={2}>
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      className="cust-textColor sm-fsize14"
                                    >
                                      About Us
                                    </Box>
                                    <AccordionIcon className="text-primary" />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} pt={0}>
                                  <ul className="list-none pt-2">
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink to="/about-us">About us</NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink to="/careers">Careers</NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink to="/contact-us">
                                        Contact us
                                      </NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14 pb-2">
                                      <NavLink to="/investor-relations">
                                        Investor Relations
                                      </NavLink>
                                    </li>
                                    <li className="cust-textColor fsize14">
                                      <NavLink to="/teams">
                                        Management Team
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </ul>
                        </DrawerBody>
                        <DrawerFooter display="block">
                          <ul className="flex items-center gap-2 list-none mb-3">
                            <li className="px-2 fsize12 font-semibold cust-textColor">
                              Log in
                            </li>
                            <li className="px-2 fsize12 font-semibold cust-textColor">
                              Customer Support
                            </li>
                            {/* <li className="px-2 fsize12 font-semibold">
                              About
                            </li> */}
                          </ul>
                          <div className="w-full flex items-center">
                            <Button
                              colorScheme="primary"
                              variant="outline"
                              fontWeight={500}
                              className={
                                colorMode === "light"
                                  ? "fsize14 w-full bg-primary clr-fff sm-fsize12"
                                  : "fsize14 w-full bg-darkprimary cust-textColor sm-fsize12"
                              }
                            >
                              Get started free
                            </Button>
                            <Button
                              ml={3}
                              fontWeight={500}
                              className="fsize14 sm-fsize12 w-full"
                            >
                              Book a Demo
                            </Button>
                          </div>
                        </DrawerFooter>
                      </DrawerContent>
                    </Drawer>
                  </div>
                </div>
                <ul className="pl-12 list-none subMenu flex items-center ">
                  {/* {menuData
                    ? menuData.map((e, i) => (
                        <>
                          {e.menutype === "page" && e.subMenu.length < 1 ? (
                            <li
                              key={i}
                              className="px-8 flex items-center li-height "
                            >
                              <NavLink to={`/${e.pageData[0].page}`}>
                                {e.menuName}
                              </NavLink>
                            </li>
                          ) : (
                            ""
                          )}

                        
                        </>
                      ))
                    : ""} */}

                  {menuData
                    ? menuData.map((e, i) => (
                        <>
                          {console.log(typeof e.subMenu, "array")}
                          {e.menutype === "page" ? (
                            <li className="px-8 flex items-center li-height hovermenu ">
                              <>
                                {e.subMenu.length > 0 ? (
                                  <div className="hovermenu cust-textColor">
                                    {isMegaMenu(e.subMenu) ? (
                                      <>
                                        {e.menuName}
                                        <ChevronDownIcon className="ml-1" />
                                        <div className="showheader">
                                          <div className="cust-bgColor header-6-megaMenu grid lg:grid-cols-4 md:grid-cols-2 gap-6">
                                            {e.subMenu && e.subMenu.length > 0
                                              ? e.subMenu.map((item) => (
                                                  <div className="header-6-megaMenu-menu">
                                                    <div className="flex items-center mb-4">
                                                      <Image
                                                        src={`${process.env.REACT_APP_STORAGE_URL}${item.menuImage[0]}`}
                                                        alt=""
                                                        className="w-12 h-12 rounded-full object-contain"
                                                      />

                                                      <h3 className="ml-2 font-medium text-primary cust-textColor">
                                                        {item.menutype ===
                                                        "page" ? (
                                                          <NavLink
                                                            to={`/${item.pageData[0].page}`}
                                                          >
                                                            {item.menuName}
                                                          </NavLink>
                                                        ) : (
                                                          <a
                                                            target="_blank"
                                                            without rel="noreferrer"
                                                            className="textColor-light header-6-megaMenu-menu-link pb-4"
                                                            href={
                                                              item.menuData.startsWith(
                                                                "http"
                                                              )
                                                                ? item.menuData
                                                                : `http://${item.menuData}`
                                                            }
                                                          >
                                                            {i.menuName}
                                                          </a>
                                                        )}
                                                      </h3>
                                                    </div>
                                                    <ul className="list-none">
                                                      {item.subMenu &&
                                                      item.subMenu.length > 0
                                                        ? item.subMenu.map(
                                                            (i) => (
                                                              <>
                                                                {i.menutype ===
                                                                "page" ? (
                                                                  <NavLink
                                                                    to={`/${i.pageData[0].page}`}
                                                                    className="textColor-light header-6-megaMenu-menu-link pb-4"
                                                                  >
                                                                    {i.menuName}
                                                                  </NavLink>
                                                                ) : (
                                                                  <a
                                                                    target="_blank"
                                                                    without rel="noreferrer"
                                                                    className="textColor-light header-6-megaMenu-menu-link pb-4"
                                                                    href={
                                                                      i.menuData.startsWith(
                                                                        "http"
                                                                      )
                                                                        ? i.menuData
                                                                        : `http://${i.menuData}`
                                                                    }
                                                                  >
                                                                    {i.menuName}
                                                                  </a>
                                                                )}
                                                              </>
                                                            )
                                                          )
                                                        : ""}
                                                    </ul>
                                                  </div>
                                                ))
                                              : ""}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* {
                                        e.subMenu.map((item) => ( */}
                                        <div className="px-2 flex items-center header6Height relative">
                                          <div className="cust-textColor sm-fsize14">
                                            {e.menuName}
                                            <ChevronDownIcon className="ml-1" />
                                          </div>

                                          <div className="showheader">
                                            <ul className="header-6-dropdown  z-10">
                                              {e.subMenu.map((sub) => {
                                                return (
                                                  <li className="header-6-dropdown-item cust-bgColor bg-white fsize14 text-black">
                                                    {sub.menutype === "page" ? (
                                                      <NavLink
                                                        to={`/${sub?.pageData[0]?.page}`}
                                                      >
                                                        {sub.menuName}
                                                      </NavLink>
                                                    ) : (
                                                      <a
                                                        target="_blank"
                                                        without rel="noreferrer"
                                                        className=""
                                                        href={
                                                          sub.menuData.startsWith(
                                                            "http"
                                                          )
                                                            ? sub.menuData
                                                            : `http://${sub.menuData}`
                                                        }
                                                      >
                                                        {sub.menuName}
                                                      </a>
                                                    )}
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                        {/* ))} */}
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  e.subMenu.length === 0 && (
                                    <NavLink
                                      to={`/${e.pageData[0].page}`}
                                      className="cust-textColor"
                                    >
                                      {e.menuName}
                                    </NavLink>
                                  )
                                )}
                              </>
                              {/* submenu */}
                              {/* {e.subMenu.length < 0
                                ? e.subMenu.map((item) => (
                                    <div className="px-4">
                                      <Menu>
                                        <MenuButton
                                          px={0}
                                          py={2}
                                          transition="all 0.2s"
                                          borderRadius="md"
                                          borderWidth="0px"
                                          className="font-medium navbartextColor-light"
                                          // _hover={{ bg: 'gray.400' }}
                                          // _expanded={{ bg: 'blue.400' }}
                                          // _focus={{ boxShadow: 'outline' }}
                                        >
                                          {item.menuName} <ChevronDownIcon />
                                        </MenuButton>
                                        <MenuList>
                                          {e.subMenu.map((sub, i) => {
                                            return (
                                              <NavLink
                                                to={`/${sub.pageData[0].page}`}
                                              >
                                                <MenuItem
                                                  className="navbartextColor-light"
                                                  key={i}
                                                >
                                                  {sub.menuName}
                                                </MenuItem>
                                              </NavLink>
                                            );
                                          })}
                                        </MenuList>
                                      </Menu>
                                    </div>
                                  ))
                                : ""} */}

                              {/* mega menu  */}
                            </li>
                          ) : (
                            <a
                              target="_blank"
                              without rel="noreferrer"
                              className="cust-textColor"
                              href={
                                e.menuData.startsWith("http")
                                  ? e.menuData
                                  : `http://${e.menuData}`
                              }
                            >
                              {e.menuName}
                            </a>
                          )}
                        </>
                      ))
                    : ""}
                </ul>
              </div>
              <ul className="flex items-center list-none">
                {/* <li className="pl-2">
                  <NavLink to="https://app.brained.app/" target="_blank">
                    <Button
                      colorScheme="primary"
                      fontWeight={500}
                      className={
                        colorMode === "light"
                          ? "fsize14 bg-primary "
                          : "fsize14 cust-textColor bg-darkprimary theme-btn"
                      }
                    >
                      Draft Requirements{" "}
                      <ArrowRight className="textwhite ml-2 textColor-light sm-arrow" />
                    </Button>
                  </NavLink>
                </li> */}
                {/* <li className="pl-2">
                  <NavLink
                    to="https://meet.brained.app/#/customer/book-a-demo"
                    target="_blank"
                  >
                    <Button
                      variant="outline"
                      fontWeight={500}
                      className="fsize14"
                    >
                      Book a Demo
                    </Button>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </Card>
      {isVisible && (
        <div className="bg-feefc4 p-2">
          <div className="container mx-auto flex justify-center items-center lg:gap-6 md:gap-6 gap-3">
            <p className="fsize12 sm-fsize10">
              BRAINED 3.0 prototype any Enterprise Platform Requirement in 24
              hours.
            </p>
            <a
              href="https://cera.aiab.in/organisation-register"
              target="_blank"
              without rel="noreferrer"
            >
              <Button
                height="fit-content"
                py={2}
                className="bg-fff text-black fsize12 sm-fsize10"
              >
                Get Started{" "}
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/rocket.png"
                  className=" pl-2 w-6 h-4 obhect-contain"
                  alt="rocket"
                />
              </Button>
            </a>
            <CloseIcon
              className="cursor-pointer fsize12"
              svgClasses="w-4 h-4"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header6;
