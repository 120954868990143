import {
  Card,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import ReactStars from "react-rating-stars-component"
import { useSelector } from 'react-redux/es/exports';

const ProductSection14 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className="container mx-auto theme-bg lg:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex justify-between items-center lg:mb-8 md:mb-6 mb-4">
        <h2
          id="9536318334"
          className="dynamicStyle fsize24 sm-fsize20 font-semibold cust-textColor"
        >
      {data ? data['9536318334'] : 'Enjoy our feature products'}
        </h2>
        <p
          id="5834175050"
          className={
            colorMode === 'light'
              ? 'dynamicStyle'
              : 'dynamicStyle textColor-light'
          }
        >
      {data ? data['5834175050'] : 'Enjoy our feature products'}
        </p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
        <Card>
          <div className=" rounded-lg overflow-hidden themeShadow">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-72 md:h-60 h-52"
            />
            <div className="pt-4 px-4">
              <h6 className="font-semibold fsize20 md-fsize18 sm-fsize16 cust-textColor">
                Rounded Red Hat
              </h6>
              <div className="flex items-center gap-2 mt-1">
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 text-gray line-through'
                      : 'sm-fsize14 line-through textColor-light'
                  }
                >
                  $8.00
                </p>
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 pl-1 text-primary'
                      : 'sm-fsize14 pl-1 cust-textColor'
                  }
                >
                  $8.00
                </p>
              </div>
              <ReactStars classNames="my-0" count={5} size={24} activeColor="#ffd700" />
            </div>
            <div className='flex justify-end'>
            <div className='bg-primary w-max px-6 py-3 bord-btn'>
                <p className='fsize14 sm-fsize13 text-white'>Add To Cart</p>
            </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className=" rounded-lg overflow-hidden themeShadow">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-72 md:h-60 h-52"
            />
            <div className="pt-4 px-4">
              <h6 className="font-semibold fsize20 md-fsize18 sm-fsize16 cust-textColor">
                Rounded Red Hat
              </h6>
              <div className="flex items-center gap-2 mt-1">
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 text-gray line-through'
                      : 'sm-fsize14 line-through textColor-light'
                  }
                >
                  $8.00
                </p>
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 pl-1 text-primary'
                      : 'sm-fsize14 pl-1 cust-textColor'
                  }
                >
                  $8.00
                </p>
              </div>
              <ReactStars classNames="my-0" count={5} size={24} activeColor="#ffd700" />
            </div>
            <div className='flex justify-end'>
            <div className='bg-primary w-max px-6 py-3 bord-btn'>
                <p className='fsize14 sm-fsize13 text-white'>Add To Cart</p>
            </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className=" rounded-lg overflow-hidden themeShadow">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-72 md:h-60 h-52"
            />
            <div className="pt-4 px-4">
              <h6 className="font-semibold fsize20 md-fsize18 sm-fsize16 cust-textColor">
                Rounded Red Hat
              </h6>
              <div className="flex items-center gap-2 mt-1">
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 text-gray line-through'
                      : 'sm-fsize14 line-through textColor-light'
                  }
                >
                  $8.00
                </p>
                <p
                  className={
                    colorMode === 'light'
                      ? 'sm-fsize14 pl-1 text-primary'
                      : 'sm-fsize14 pl-1 cust-textColor'
                  }
                >
                  $8.00
                </p>
              </div>
              <ReactStars classNames="my-0" count={5} size={24} activeColor="#ffd700" />
            </div>
            <div className='flex justify-end'>
            <div className='bg-primary w-max px-6 py-3 bord-btn'>
                <p className='fsize14 sm-fsize13 text-white'>Add To Cart</p>
            </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProductSection14;
