import {
  Button,
  Image,
  Input,
  Textarea,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const CustomHeroSectionForm1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const steps = [
    { description: 'Personal Details' },
    { description: 'Address' },
    { description: 'Employment Details' },
    { description: 'Income Details' },
    { description: 'Franchise details' },
  ];

  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  return (
    <div className="h-750px">
      <div id="6458969435" className="dynamicStyle2 relative h-400px">
        <Image
          src={data ? getImage(data['6458969435']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-400px w-full object-cover"
        />
        <div className="cust-absl w-4/5 text-center">
          <h3
            id="3372782684"
            className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff pb-2"
          >
            {data ? data['3372782684'] : 'Lorem Ipsum'}
          </h3>
          <p
            id="1575954283"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1575954283']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle sm-fsize14 clr-fff"
          ></p>
        </div>

        <div className="lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 w-11/12 cust-shadow abslform-card bg-fff">
          <div className="pb-10">
            <Stepper
              index={activeStep}
              className="custom overflow-x-auto ruby-text custom-touch"
            >
              {steps.map((step, index) => (
                <Step className="block sm-min-w-max p-2" key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
            <div className="relative">
              <Input type="text" placeholder="first name" className="" />
              <p
                id="7584657145"
                className="dynamicStyle fsize10 px-1 abslform1 bg-fff "
              >
                {data ? data['7584657145'] : 'First Name'}
              </p>
            </div>
            <div className="relative">
              <Input type="text" placeholder="Last name" />
              <p
                id="0279966470"
                className="dynamicStyle fsize10 px-1 abslform1 bg-fff "
              >
                {data ? data['0279966470'] : 'Last Name'}
              </p>
            </div>
            <div className="relative">
              <Input type="text" placeholder="Email Id" className="" />
              <p
                id="0241883350"
                className="dynamicStyle fsize10 px-1 abslform1 bg-fff "
              >
                {data ? data['0241883350'] : 'Email Id'}
              </p>
            </div>
            <div className="relative">
              <Input type="text" placeholder="Phone Number" className="" />
              <p
                id="8510649009"
                className="dynamicStyle fsize10 px-1 abslform1 bg-fff "
              >
                {data ? data['8510649009'] : 'Phone Number'}
              </p>
            </div>
          </div>
          <div>
            <Textarea name="description" id="" placeholder="Type here" />
          </div>

          <div className="flex justify-end lg:mt-10 md:mt-10 mt-6">
            <Button
              px={8}
              className="dynamicStyle bg-primary textwhite cust-bgColor theme-btn lg:px-6 lg:py-2 md:px-4 md:py-2 px-2 py-1  fsize14 sm-fsize10 px-1"
              id=""
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomHeroSectionForm1;
