import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaWhatsapp,
} from 'react-icons/fa';
import { useSelector } from 'react-redux/es/exports';
const Footer12 = ({ pageName }) => {
    const data = useSelector(state => state.cms.allSections[pageName]);
    let brandProfile = useSelector(state => state.brandDetails.brandProfile);
    function getSecondaryImage(value) {
      return value
        ? value.alternateLogo
          ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
          : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
    }
  return (
    <div className="bg-secondary cust-bgColor text-white">
      <div className="lg:px-12 md:px-10 px-4 lg:py-14 md:py-12 py-6">
        <div className="lg:flex md:flex w-full items-start">
          <div className="lg:w-1/5 md:w-1/5 w-full flex lg:justify-start md:justify-start justify-center">
            <a href="/">
              <Image
                src={getSecondaryImage(brandProfile)}
                className="lg:h-20 md:h-20 h-16 lg:w-40 md:w-20 w-12 object-contain"
              />
            </a>
          </div>
          <div className="grid md:grid-cols-4 grid-cols-2 lg:grid-cols-4 lg:gap-8 md:gap-8 gap-4 lg:mt-0 md:mt-0 mt-4 lg:w-4/5 md:w-full w-full">
            <div>
              {' '}
              <p id="0685276944"
                      className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
                   {data ? data['0685276944'] : 'Products'}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Loungewear{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Dailywear{' '}
              </p>
            </div>
            <div>
              {' '}
              <p id="1588329144"
                      className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
                {data ? data['1588329144'] : 'Customer Care'} 
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Connect{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Support{' '}
              </p>
            </div>
            <div>
              {' '}
              <p id="1106543429"
                      className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
              {data ? data['1588329144'] : 'Terms & Privacy'}   
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Terms & Conditions{' '}
              </p>
              <p className="lg:pt-4 md:pt-3 pt-2 text-white fsize14 md-fsize12 sm-fsize12">
                Privacy Policy{' '}
              </p>
            </div>
            <div>
              {' '}
              <p id="1106543429"
                      className="dynamicStyle font-semibold text-white fsize16 md-fsize14 sm-fsize14">
                 {data ? data['1588329144'] : 'Social Media'}  
              </p>
              <div className="lg:pt-4 md:pt-3 pt-2 flex">
                <div className="lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 cursor-pointer">
                <a href="/">
                  <FaFacebookF className="clr-fff" />
                  </a>
                </div>
                <div className="lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 cursor-pointer">
                <a href="/">
                  <FaInstagram className="clr-fff" />
                  </a>
                </div>
                <div className="lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 cursor-pointer">
                <a href="/"> 
                  <FaWhatsapp className="clr-fff" />
                  </a>
                </div>
                <div className="lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 cursor-pointer">
                <a href="/">
                  <FaPinterest className="clr-fff" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer12;
