import { Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const Services6 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <>
      <div className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="text-center lg:w-2/3 md:w-2/3 w-full mx-auto">
          <p
            id="2449322198"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor mb-2"
          >
            {data ? data['2449322198'] : 'Lorem'}
          </p>
          <p
            id="3747154202"
            className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize14 text-center"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3747154202']
                : 'Prepare for a promising career by acquiring essential',
            }}
          ></p>
        </div>
        <div className="lg:flex md:flex lg:gap-12 md:gap-6 w-full lg:mt-12 md:mt-12 mt-6">
          <div
            id="7427807967"
            className="dynamicStyle2 lg:w-/12 md:w-1/2 w-full"
          >
            <Image
              src={data ? getImage(data['7427807967']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="service_image"
              className="object-contain"
            />
          </div>
          <div
            id="7534097216"
            className="dynamicStyle lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-6"
          >
            {data && data['7534097216']
              ? data['7534097216'].map((e, index) => {
                  return (
                    <Card 
                    className={
                        colorMode === 'light'
                          ? 'box-shadow-none p-4 w-full lg:mb-6 md:mb-6 mb-4 theme-bodyColor themeShadow-insert themeShadow'
                          : 'p-4 w-full lg:mb-6 md:mb-6 mb-4 theme-bodyColor themeShadow-insert themeShadow'
                      }
                     key={index}>
                      <p className="fsize24 md-fsize24 sm-fsize20 font-semibold text-black cust-textColor">
                        {e.field1}
                      </p>
                      <p
                        className="lineClamp3 textColor-light fsize14 sm-fsize14 lg:pt-2 md:pt-2 pt-1"
                        dangerouslySetInnerHTML={{
                          __html: e.field2,
                        }}
                      ></p>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services6;
