import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const DownloadSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-12 px-5 ">
      <div className="lg:flex md:flex items-center lg:gap-8 gap-6">
        <div
          id="0101956877"
          className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-4"
        >
          <Image
            src={data ? getImage(data['0101956877']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="h-400px sm-h250px w-full"
          />
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <p id="4055220626"
              className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor">
            {data ? data['4055220626'] : 'Lorem Ipsum'}
          </p>
          <p
          id="7067874531"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['7067874531']
              : ' The SBI Foundation, or State Bank of India Foundation, is the corporate social responsibility (CSR) arm of the State Bank of',
          }}
            className={colorMode === 'light' ? 'dynamicStyle pb-2' : 'dynamicStyle pb-2 textColor-light'}
          >
            
          </p>
          <Button
            size="lg"
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary theme-btn sm-fsize14 md-fsize14'
                : 'dynamicStyle cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
            }
            id="7516724899"
          >
            <span onClick={() => handleClick('7516724899')}>
              {data ? data['7516724899'] : 'Submit'}
            </span>
          
            <FeatherIcon className="ml-3" size={18} icon="download" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection2;
