import {
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const SubscriptionPlan8 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center">
          <p
            id="2746531427"
            className="dynamicStyle text-black cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold lg:mb-2 md:mb-2"
          >
            {data ? data['2746531427'] : 'The right plan for your event'}
          </p>
          <p
            id="6991303489"
            dangerouslySetInnerHTML={{
              __html: data ? data['6991303489'] : 'Lorem ipsum generated',
            }}
            className='dynamicStyle text-black textColor-light fsize16 md-fsize14 sm-fsize14"'
          ></p>
        </div>
        <div className="lg:mt-12 md:mt-2 mt-8">
          <Tabs variant="soft-rounded" colorScheme="green">
            <div className=" w-100per text-center">
              <TabList
                display="inline-flex"
                justifyContent="center"
                className="bg-primary p-1  rounded-full "
              >
                <Tab id="5112127406" className="dynamicStyle" borderRadius="30">
                  {data ? data['5112127406'] : 'Monthly'}
                </Tab>
                <Tab id="4015237002" className="dynamicStyle" borderRadius="30">
                  {data ? data['4015237002'] : 'Yearly'}
                </Tab>
              </TabList>
            </div>
            <div className="lg:pt-8 md:pt-8 pt-6">
              <TabPanels>
                <TabPanel>
                  <div className="grid lg:grid-cols-3 gap-6">
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="fsize24 md-fsize20 sm-fsize16 text-black cust-textColor font-semibold lg:pb-4 md:pb-3 pb-2">
                          Starter Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="fsize44 md-fsize28 sm-fsize20 text-black cust-textColor font-semibold lg:pb-2 md:pb-2 pb-1">
                          $32.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold textColor-light">
                          per month / per user
                        </p>
                      </div>
                    </Card>
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="text-black cust-textColor fsize24 md-fsize20 sm-fsize16 font-semibold lg:pb-4 md:pb-3 pb-2">
                          Essential Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="text-black cust-textColor fsize44 md-fsize28 sm-fsize20 font-semibold lg:pb-2 md:pb-2 pb-1">
                          $64.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold ">
                          per month / per user
                        </p>
                      </div>
                    </Card>
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="text-black cust-textColor fsize24 md-fsize20 sm-fsize16 font-semibold lg:pb-4 md:pb-3 pb-2">
                          Business Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="text-black cust-textColor fsize44 md-fsize28 sm-fsize20 font-semibold lg:pb-2 md:pb-2 pb-1">
                          $128.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold ">
                          per month / per user
                        </p>
                      </div>
                    </Card>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="grid lg:grid-cols-3 gap-6">
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="text-black cust-textColor fsize24 md-fsize20 sm-fsize16 font-semibold lg:pb-4 md:pb-3 pb-2">
                          Starter Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="text-black cust-textColor fsize44 md-fsize28 sm-fsize20 font-semibold lg:pb-2 md:pb-2 pb-1">
                          $32.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold ">
                          per year / per user
                        </p>
                      </div>
                    </Card>
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="text-black cust-textColor fsize24 md-fsize20 sm-fsize16 font-semibold lg:pb-4 md:pb-3 pb-2">
                          Essential Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="text-black cust-textColor fsize44 md-fsize28 sm-fsize20 font-semibold lg:pb-2 md:pb-2 pb-1">
                          $64.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold ">
                          per year / per user
                        </p>
                      </div>
                    </Card>
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'bdr1-f2f2f2 box-shadow-none themeShadow theme-bg'
                          : 'themeShadow box-shadow-none theme-bg'
                      }
                    >
                      <div className="mx-6 pt-16 pb-8 text-center bottom-border-ebebeb">
                        <p className="text-black cust-textColor fsize24 md-fsize20 sm-fsize16 font-semibold lg:pb-4 md:pb-3 pb-2">
                          Business Plan
                        </p>
                        <p className="text-black textColor-light fsize14 md-fsize14 sm-fsize12 lg:px-6 md:px-6">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Vero doloribus fugit reprehenderit soluta
                          incidunt?
                        </p>
                      </div>
                      <div className="mx-6 pb-16 pt-8 text-center">
                        <p className="text-black cust-textColor fsize44 md-fsize28 sm-fsize20 font-semibold lg:pb-2 md:pb-2 pb-1">
                          $128.00
                        </p>
                        <p className="text-black textColor-light fsize12 font-semibold ">
                          per year / per user
                        </p>
                      </div>
                    </Card>
                  </div>
                </TabPanel>
              </TabPanels>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPlan8;
