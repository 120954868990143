import { Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const Services7 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className={colorMode === 'light' ? 'theme-bg' : 'theme-bg'}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
        <div className="lg:w-2/3 md:w-2/3 w-full text-center mx-auto">
          <p
            id="7401408450"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold mb-2"
          >
            {data ? data['7401408450'] : 'SERVICES WE PROVIDE'}
          </p>
          <p
            id="3537365850"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3537365850']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize16 sm-fsize14 textColor-light"
          ></p>
        </div>
        <div id="1902505608" className="dynamicStyle lg:mt-16 md:mt-12 mt-8">
          <Swiper
            spaceBetween={25}
            slidesPerView={3.2}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper p-2"
            autoplay={{
              delay: '1000',
            }}
            loop
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 3.4,
                spaceBetween: 25,
              },
              991: {
                slidesPerView: 2.8,
                spaceBetween: 25,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            {data && data['1902505608']
              ? data['1902505608'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card className="p-4 box-shadow-none themeShadow theme-bodyColor themeShadow-insert" key={index}>
                        <div className="w-full">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="serviec"
                            className="w-2/3 mx-auto h-36 rounded-lg"
                          />
                        </div>
                        <div className="mt-4 text-center">
                          <p className="fsize20 ms-fsize18 sm-fsize16 text-black cust-textColor font-semibold pb-1">
                            {e.field2}
                          </p>
                          <p
                            className={
                              colorMode === 'light'
                                ? 'fsize14 sm-fsize14 text-gray lineClamp3'
                                : 'fsize14 sm-fsize14 textColor-light lineClamp3'
                            }
                            dangerouslySetInnerHTML={{
                              __html: e.field3,
                            }}
                          ></p>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Services7;
