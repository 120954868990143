import React from 'react';
import { Card, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const AboutSection37 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-5">
      <div>
        <div className="lg:flex md:flex  items-center gap-4">
          <div className="lg:w-1/2 md:w-1/2 lg:py-0 md:py-8 py-4">
            <div>
              <h2
                id="9307698155"
                className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold dynamicStyle"
              >
                {data ? data['9307698155'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="5810008863"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['5810008863']
                    : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                }}
                className="dynamicStyle lg:w-5/6 md:w-5/6 textColor-light lg:mt-4 md:mt-3 mt-2 fsize18 md-fsize14 sm-fsize12 dynamicStyle"
              ></p>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 grid grid-cols-2 lg:gap-8 md:gap-6 gap-4 justify-end w-full lg:py-10 md:py-6 py-4">
            <div className="w-full">
              <div className="">
                <Card className="cursor-pointer theme-bg detailing-card-shadow about37">
                  <div className="w-full flex flex-col items-center justify-center p-4 lg:h-60 md:h-48 h-40 ">
                    <p
                      id="0571283944"
                      className="dynamicStyle dynamicStyle font-semibold md-fsize28 fsize32 sm-fsize20 tracking-wide"
                    >
                      {data ? data['0571283944'] : ' Lorem ipsum'}
                    </p>
                    <p id="1512023915"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['1512023915']
                    : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                }} className="dynamicStyle fsize16 md-fsize12 sm-fsize10 lg:mt-4 md:mt-1 mt-1 textColor-light text-center text-align lg:leading-6 md:leading-4 leading-3">
                      
                    </p>
                  </div>
                  <div class="detailing-overlay w-full lg:h-60 md:h-48 h-40 relative about37-hov">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                      alt="img15"
                      className="w-full h-full rounded-md"
                    />

                    <div className="lg:h-60 md:h-44 h-40 w-full detailing-content flex flex-col justify-center items-center px-4 rounded-md">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        className="lg:h-12 md:h-10 h-8 lg:w-12 md:w-10 w-8 rounded-full css-0"
                      />
                      <h2 className="font-bold fsize20 md-fsize16 sm-fsize14 text-white text-center lg:mt-2.5 md:mt-2.5 mt-1.5 lg:leading-6 md:leading-5 leading-4">
                        About Detailing
                      </h2>

                      <p className="fsize16 md-fsize12 sm-fsize10 text-white text-center lg:mt-3 md:mt-2 mt-2 lg:leading-6 md:leading-5 leading-3">
                        We go above and beyond to exceed your expectations With
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <div className=" lg:mt-8 md:mt-6 mt-4">
                {' '}
                <Card className="cursor-pointer theme-bg detailing-card-shadow about37">
                  <div className="w-full flex flex-col items-center justify-center p-4 lg:h-60 md:h-48 h-40 ">
                    <p id="9738543696" className="dynamicStyle font-semibold md-fsize28 fsize32 sm-fsize20 tracking-wide">
                    {data ? data['9738543696'] : 'Lorem Ipsum'}
                    </p>
                    <p id="0897693696"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['0897693696']
                    : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                }}
                className="dynamicStyle fsize16 md-fsize12 sm-fsize10 lg:mt-4 md:mt-1 mt-1 textColor-light text-center text-align lg:leading-6 md:leading-4 leading-3">
                      
                    </p>
                  </div>
                  <div class="detailing-overlay w-full lg:h-60 md:h-48 h-40 relative about37-hov">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                      alt="img15"
                      className="w-full h-full rounded-md"
                    />

                    <div className="lg:h-60 md:h-44 h-40 w-full detailing-content flex flex-col justify-center items-center px-4 rounded-md">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        className="lg:h-12 md:h-10 h-8 lg:w-12 md:w-10 w-8 rounded-full css-0"
                      />
                      <h2 className="font-bold fsize20 md-fsize16 sm-fsize14 text-white text-center lg:mt-2.5 md:mt-2.5 mt-1.5 lg:leading-6 md:leading-5 leading-4">
                        About Detailing
                      </h2>

                      <p className="fsize16 md-fsize12 sm-fsize10 text-white text-center lg:mt-3 md:mt-2 mt-2 lg:leading-6 md:leading-5 leading-3">
                        We go above and beyond to exceed your expectations With
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="w-full mt-6">
              <div className="">
                {' '}
                <Card className="cursor-pointer theme-bg detailing-card-shadow about37">
                  <div className="w-full flex flex-col items-center justify-center p-4 lg:h-60 md:h-48 h-40 ">
                    <p id="2671494337"
                className="dynamicStyle  font-semibold md-fsize28 fsize32 sm-fsize20 tracking-wide">
                     {data ? data['2671494337'] : 'Lorem Ipsum'}
                    </p>
                    <p id="8715637661"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['8715637661']
                    : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                }} className="dynamicStyle fsize16 md-fsize12 sm-fsize10 lg:mt-4 md:mt-1 mt-1 textColor-light text-center text-align lg:leading-6 md:leading-4 leading-3">
                      
                    </p>
                  </div>
                  <div class="detailing-overlay w-full lg:h-60 md:h-48 h-40 relative about37-hov">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                      alt="img15"
                      className="w-full h-full rounded-md"
                    />

                    <div className="lg:h-60 md:h-44 h-40 w-full detailing-content flex flex-col justify-center items-center px-4 rounded-md">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        className="lg:h-12 md:h-10 h-8 lg:w-12 md:w-10 w-8 rounded-full css-0"
                      />
                      <h2 className="font-bold fsize20 md-fsize16 sm-fsize14 text-white text-center lg:mt-2.5 md:mt-2.5 mt-1.5 lg:leading-6 md:leading-5 leading-4">
                        About Detailing
                      </h2>

                      <p className="fsize16 md-fsize12 sm-fsize10 text-white text-center lg:mt-3 md:mt-2 mt-2 lg:leading-6 md:leading-5 leading-3">
                        We go above and beyond to exceed your expectations With
                      </p>
                    </div>
                  </div>
                </Card>
              </div>

              <div className=" lg:mt-8 md:mt-6 mt-4">
                {' '}
                <Card className="cursor-pointer theme-bg detailing-card-shadow about37">
                  <div className="w-full flex flex-col items-center justify-center p-4 lg:h-60 md:h-48 h-40 ">
                    <p id="0130102364" className="dynamicStyle font-semibold md-fsize28 fsize32 sm-fsize20 tracking-wide">
                    {data ? data['0130102364'] : 'Lorem Ipsum'}
                    </p>
                    <p id="5038786761"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['5038786761']
                    : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                }} className="dynamicStyle fsize16 md-fsize12 sm-fsize10 lg:mt-4 md:mt-1 mt-1 textColor-light text-center text-align lg:leading-6 md:leading-4 leading-3">
                      
                    </p>
                  </div>
                  <div class="detailing-overlay w-full lg:h-60 md:h-48 h-40 relative about37-hov">
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                      alt="img15"
                      className="w-full h-full rounded-md"
                    />

                    <div className="lg:h-60 md:h-44 h-40 w-full detailing-content flex flex-col justify-center items-center px-4 rounded-md">
                      <Image
                        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        className="lg:h-12 md:h-10 h-8 lg:w-12 md:w-10 w-8 rounded-full css-0"
                      />
                      <h2 className="font-bold fsize20 md-fsize16 sm-fsize14 text-white text-center lg:mt-2.5 md:mt-2.5 mt-1.5 lg:leading-6 md:leading-5 leading-4">
                        About Detailing
                      </h2>

                      <p className="fsize16 md-fsize12 sm-fsize10 text-white text-center lg:mt-3 md:mt-2 mt-2 lg:leading-6 md:leading-5 leading-3">
                        We go above and beyond to exceed your expectations With
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection37;
