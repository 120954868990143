import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  useColorMode,
  InputRightElement,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
const NewsletterSection7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div  className={
      colorMode === 'light'
        ? 'bg-f4f5f6 theme-bg'
        : 'theme-bg'
    }>
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="mx-auto flex lg:flex-row md:flex-row flex-col lg:gap-8 md:gap-8 gap-2">
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <p
            id="7269813286"
            className="dynamicStyle fsize32 md-fsize24 sm-fsize20 font-semibold lg:py-3 md:py-3 py-2 text-black cust-textColor"
          >
            {data ? data['7269813286'] : 'Subscribe for the newsletter'} 
          </p>
          <p
            id="9374299254"
            className="dynamicStyle text-gray textColor-light fsize16 md-fsize14 sm-fsize12"
          >
            {data
              ? data['9374299254']
              : 'We are the national Apex body of professionals committed to promoting the People Development'}
            
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full flex items-center lg:gap-3 md:gap-3 gap-2 lg:mt-5 md:mt-4 mt-0">
          <InputGroup size="lg" className="mx-auto lg:my-6 md:my-6 my-3 lg:w-4/5 w-full">
            <Input
              borderRadius={'0px'}
              outline={'0px'}
              pr="4.5rem"
              _focusVisible={"none"}
              size="md"
              className="fsize14 md-fsize14 theme-border-0 theme-bg sm-fsize12 border-0  bg-fff cust-bgColor cust-textColor themeShadow"
            />
            <InputRightElement width="4.5rem">
              <FeatherIcon
              size={"18px"}
                className="cust-textColor search-abs my-0.5"
                icon="search"
              />
            </InputRightElement>
          </InputGroup>

          <Button
            borderRadius={'0px'}
            border={'0'}
            outline={'0px'}
            px={'30px'}
            className="dynamicStyle bg-fff fsize14 md-fsize14 sm-fsize12 cust-textColor cust-bgColor border-0 buttonAnimate theme-btn"
            id="3259375058"
          >
           <span onClick={() => handleClick('3259375058')}>
              {data ? data['3259375058'] : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default NewsletterSection7;
