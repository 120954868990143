import { Avatar, AvatarGroup, Button, Checkbox } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const PollSection2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <h3
          id="0952397629"
          className="dynamicStyle inline-block fsize34 md-fsize28 sm-fsize24 font-semibold text-black cust-textColor"
        >
          {data ? data['0952397629'] : 'Poll Question 2'}
        </h3>
        <p
          className="dynamicStyle fsize14 md-fsize14 sm-fsize12 lg:w-2/3 text-black textColor-light"
          id="3378803519"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['3378803519']
              : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
          }}
        ></p>
        <div className="lg:py-12 md:py-12 py-6 lg:w-1/3 md:w-1/2">
          <div className="border1 py-2 px-6 rounded-full mb-4">
            <p className="fsize14 md-fsize14 fsize14 text-black cust-textColor font-medium">
              2022-23
            </p>
          </div>
          <div className="border1 py-2 px-6 rounded-full mb-4">
            <p className="fsize14 md-fsize14 fsize14 text-black cust-textColor font-medium">
              2022-23
            </p>
          </div>
          <div className="border1 py-2 px-6 rounded-full mb-0">
            <p className="fsize14 md-fsize14 fsize14 text-black cust-textColor font-medium">
              2022-23
            </p>
          </div>
        </div>
        <div className="mt-0 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup size="sm" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text-black textColor-light dynamicStyle"
                id="7105565325"
              >
                {data ? data['7105565325'] : 'Total votes:'}
              </p>
              <p className="fsize12 text-black textColor-light">24</p>
            </div>
          </div>
          {/* <Button
            className="bg-primary cust-bgColor theme-btn clr-fff"
            fontWeight={400}
            borderRadius={2}
          >
            Vote
          </Button> */}
        </div>
      </div>
    </section>
  );
};

export default PollSection2;
