import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useNavigate } from 'react-router-dom';

const NewsletterSection8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className="container mx-auto lg:flex md:flex items-center lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:w-3/12 md:w-3/12">
        <div id="4385194010" className="dynamicStyle2 relative">
          <Image
            className="lg:h-96 md:h-72 h-48 w-full rounded-lg"
            src={data ? getImage(data['4385194010']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
        </div>
      </div>
      <div className="lg:w-6/12 lg:px-12 md:px-5 px-4 md:w-6/12 w-full mx-auto text-center lg:py-0 md:py-0 py-6">
        <h5
          id="0523987505"
          className="dynamicStyle fsize32 md-fsize26 sm-fsize22 font-semibold lg:mb-2 md:mb-2 mb-1 text-black cust-textColor"
        >
         {data ? data['0523987505'] : 'Men'}
        </h5>
        <p
          id="5148623604"
          className="dynamicStyle text-gray fsize16 md-fsize16 sm-fsize14 textColor-light"
        >
          {data ? data['5148623604'] : 'Men'}
        </p>
        <InputGroup
          size="lg"
          className="mx-auto lg:my-6 md:my-6 my-3 w-full shadow-lg rounded-lg border-none"
        >
          <Input placeholder="Enter your email address" />
        </InputGroup>
        <div className="flex justify-center lg:mt-4 md:mt-3 mt-2">
          <Button
            px={8}
            py={4}
            id="4089375841"
            borderRadius="10"
            className={
              colorMode === 'light'
                ? 'theme-btn dynamicStyle bg-primary bdr-0px lg:mt-4 md:mt-4 mt-2 sm-fsize14 clr-fff'
                : 'theme-btn dynamicStyle cust-bgColor bdr-0px lg:mt-4 md:mt-4 mt-2 cust-textColor sm-fsize14 '
            }
          >
            <span onClick={() => handleClick('4089375841')}>
              {data ? data['4089375841'] : 'Discover More'}
            </span>
          </Button>
        </div>
      </div>
      <div className="lg:w-3/12 md:w-3/12">
        <div id="6847549849" className="dynamicStyle2 relative">
          <Image
            className="lg:h-96 md:h-72 h-48 w-full rounded-lg"
            src={data ? getImage(data['6847549849']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection8;
