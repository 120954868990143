import React from 'react';
import Rating from 'react-rating';
import { Input, Image, useColorMode, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';

const Discount2 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 container mx-auto">
      <div className="lg:flex md:flex block items-center">
        <div className="lg:w-7/12 md:w-6/12 w-full">
          <h4
            id="8600681756"
            className="dynamicStyle cust-textColor fsize28 md-fsize22 font-semibold text-left sm-fsize20 "
          >
            {data ? data['8600681756'] : 'Lorem Ipsum'}
          </h4>
          <div id="1734576216" className="dynamicStyle2 lg:mt-5 md:mt-4 mt-2">
            <Image
              src={data ? getImage(data['1734576216']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="w-full lg:h-60 md:h-52 rounded-lg"
              alt="cards"
            />
          </div>
          <div className="mt-4">
            <div className="flex items-center gap-2">
              <Rating
                initialRating={3}
                className="flex_rating"
                readonly={true}
                emptySymbol={
                  <FeatherIcon
                    icon="star"
                    className="textColor-light"
                    size={18}
                  />
                }
                fullSymbol={
                  <FeatherIcon icon="star" className="fillstroke" size={18} />
                }
                fractions={2}
              />
              <p className="fsize14 mt-1 text-gray">4.0 review</p>
            </div>
            <div className="mt-2">
              <h6 className="fsize24 md-fsize20 sm-fsize20 font-semibold text-dark cust-textColor">
                Product
              </h6>
              <p className="fsize16 md-fsize14 sm-fsize13 font-normal text-gray">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 md:w-6/12 w-full lg:pl-10 md:pl-5 lg:mt-0 md:mt-0 mt-4">
          <div className={
                colorMode === 'light'
                  ? 'border-ec rounded-lg lg:px-6 lg:py-6 md:px-3 md:py-3 py-2 px-2'
                  : 'border-black rounded-lg lg:px-6 lg:py-6 md:px-3 md:py-3 py-2 px-2'
              }>
            <div className={colorMode === 'light'
                  ? 'pb-2 px-2 bordblack'
                  : 'pb-2 px-2 bordb'
              }>
              <h6
                id="9153963808"
                className="dynamicStyle fsize18 text-dark cust-textColor font-semibold"
              >
                {data ? data['9153963808'] : 'Lorem Ipsum'}
              </h6>
              <p
                id="8130925930"
                className="dynamicStyle fsize16 md-fsize14 sm-fsize13 text-gray textColor-light mt-1"
              >
                {data ? data['8130925930'] : 'Lorem Ipsum'}
              </p>
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'pb-5 px-2 pt-4 bordblack'
                  : 'pb-5 px-2 pt-4 bordb'
              }
            >
              <div className="flex justify-between lg:mt-3 md:mt-2 mt-2">
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  Cost
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  -
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light ">
                  2000
                </p>
              </div>
              <div className="flex justify-between lg:mt-6 md:mt-4 mt-2">
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  Coupon code
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  -
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light ">
                  2000
                </p>
              </div>
              <div className="flex justify-between lg:mt-6 md:mt-4 mt-2">
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  Tax.
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold ">
                  -
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light ">
                  2000
                </p>
              </div>
            </div>
            <div className="pb-3 px-2 pt-4">
              <div className="flex justify-between">
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold">
                  Total
                </p>
                <p className="fsize16 md-fsize14 sm-fsize13 text-dark textColor-light font-semibold">
                  2000
                </p>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <Input
                  placeholder="coupon code"
                  className="fsize14"
                  size="md"
                />
                <Button
                  id="6662290131"
                  colorScheme="primary"
                  px={8}
                  className="rounded-lg fsize14 clr-fff cust-bgColor dynamicStyle theme-btn"
                >
                  <span onClick={() => handleClick('6662290131')}>Apply</span>
                </Button>
              </div>
              <Button
                id="6662290131"
                colorScheme="primary"
                className="rounded-lg fsize14 clr-fff cust-bgColor dynamicStyle w-full mt-6 theme-btn"
              >
                <span onClick={() => handleClick('6662290131')}>Checkout</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discount2;
