import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  useColorMode,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { handleScroll } from "./fadeeffect/FadeEffect";

const FAQSection6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className="relative h-580px"
    >
      <div id="0949662539"
          className="dynamicStyle2 h-580px w-full">
      <Image
       src={data ? getImage(data['0949662539']) : ''}
       fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="img"
        className="h-580px w-full object-cover"
      />
      </div>
      <div className="absl-faq6 top-0 left-0 w-full h-580px flex items-center justify-center">
        
        <div>
        <div className="container mx-auto">
          <div className="lg:w-2/3 md:w-3/4 w-full mx-auto  text-center lg:my-8 md:my-6 my-4">
            <h2
              id="0927075628"
              className="dynamicStyle textwhite fsize36 md-fsize24 sm-fsize24 font-medium mb-2 cust-textColor theme-textColor"
            >
              {data ? data["0927075628"] : "Questions Thats Comes in your Mind"}
            </h2>
            <p
              id="6192491141"
              className="dynamicStyle textwhite lg:w-full md:w-2/3 w-full mx-auto fsize16 sm-fsize14 textColor-light"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["6192491141"]
                  : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
              }}
            ></p>
          </div>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom container mx-auto lg:py-8 md:py-6 py-4 lg:px-16 md:px-6 px-3 relative  ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <Accordion id="5153191935" className="dynamicStyle" allowToggle>
            {data && data["5153191935"]
              ? data["5153191935"].map((e) => {
                  return (
                    <AccordionItem
                      className={
                        colorMode === "light"
                          ? "border-primary theme-bg bg-fff theme-border-0 themeShadow"
                          : "cust-bgColor theme-bg border-1px"
                      }
                      mb={4}
                    >
                      {({ isExpanded }) => (
                        <>
                          <h2>
                            <AccordionButton>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="cust-textColor theme-textColor"
                                p={2}
                              >
                                {e.field1}
                              </Box>
                              {isExpanded ? (
                                <MinusIcon
                                  fontSize="12px"
                                  className="theme-textColor"
                                />
                              ) : (
                                <AddIcon
                                  fontSize="12px"
                                  className="theme-textColor"
                                />
                              )}
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            pb={4}
                            className="fsize14 textColor-light theme-textColor"
                            dangerouslySetInnerHTML={{
                              __html: e.field2,
                            }}
                          ></AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  );
                })
              : "no-data"}
          </Accordion>
        </div>
        </div>
        
      </div>
    </div>
  );
};

export default FAQSection6;
