import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const NewsletterSection10 = ({pageName}) => {
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    const navigate = useNavigate();
    const link = 'https://example.com';
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
    const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="overflow-hidden w-full lg:flex md:flex lg:h-screen sm-h-screen md-h-600px ">
      <div className="lg:w-2/5 md:w-2/5 dynamicStyle2" id='5215212120'>
        <Image
        src={data ? getImage(data['2344189220']) : '5215212120'}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="object-cover lg:rounded-r-3xl md:rounded-r-3xl h-full"
        />
      </div>
      <div className="lg:w-3/5 md:w-3/5 lg:px-24 relative h-full sm-h-350px  flex items-center lg:px-0 md:px-8 px-5">
        <div className="w-full">
          <p
            className="fsize40 md-fsize28 sm-fsize20 font-semibold text-black cust-textColor dynamicStyle"
            id="5353362141"
          >
            {data ? data['5353362141'] : 'Dont Miss Out!'}
          </p>
          <p id="5159140067"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5159140067']
                  : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
              }}
              className="dynamicStylefsize16 md-fsize16 sm-fsize14 text-gray textColor-light">
           
          </p>
          <div className="flex gap-2 mt-8 lg:pr-16">
            <Input borderRadius={30} className='text-black cust-textColor' />
            <Button
              id="9330400741"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle bg-primary theme-bg themeShadow newsletter10-button'
                  : 'dynamicStyle  cust-bgcolor theme-bg themeShadow newsletter10-button'
              }
              
            >
              <span onClick={() => handleClick('9330400741')}>
                {data ? data['9330400741'] : 'Subscribe'}
              </span>
            </Button>
          </div>
        </div>
        <div className="bg-primary cust-bgcolor theme-bg themeShadow newsletter10-circle absolute rounded-full w-56 h-56 mob-hidden"></div>
      </div>
    </div>
  );
};

export default NewsletterSection10;
