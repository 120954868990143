import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard29 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className={
        colorMode === 'light'
          ? 'lg:py-12 md:py-12 py-8 bg-1c1c1c theme-bg'
          : 'lg:py-12 md:py-12 py-8 theme-bg'
      }
    >
      <div className="lg:flex md:flex gap-4 justify-between container mx-auto lg:px-12 md:px-8 px-5">
        <div className="lg:w-2/5 md:w-1/2">
          <h3
            id="1388858038"
            className="dynamicStyle fsize40 md:fsize32 sm-fsize20 font-semibold clr-fff theme-textColor cust-textColor pb-2"
          >
            {data ? data['1388858038'] : 'Lorem Ipsum'}
          </h3>
        </div>
        <div className="lg:w-2/5 md:w-1/2">
          <p
            id="7546980133"
            dangerouslySetInnerHTML={{
              __html: data ? data['7546980133'] : 'Lorem ipsum generated',
            }}
            className="dynamicStyle clr-fff textColor-light theme-textColor sm-fsize14"
          ></p>
        </div>
      </div>
      <div
        id="9454021530"
        className="dynamicStyle w-full container mx-auto lg:px-12 md:px-8 px-5 py-8"
      >
        <Swiper
          spaceBetween={25}
          slidesPerView={3.2}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className=" lg:p-2 p-1"
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          breakpoints={{
            1536: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 1.8,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 1.8,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
          }}
        >
          {/* <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
            <svg
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
            <svg
              stroke="currentColor"
              color="white"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
          {data && data['9454021530']
            ? data['9454021530'].map((e, index) => {
                return (
                  <SwiperSlide>
                    <div className="rounded-lg flex gap-2 bg-fff theme-bg themeShadow cust-bgColor p-4">
                      <div className="w-1/6">
                        <Image
                          src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="img"
                          className="lg:h-16 md:w-16 w-14 h-14 rounded-full"
                        />
                      </div>
                      <div className="w-5/6">
                        <p className="fsize24 sm-fsize18 font-semibold pb-2 cust-textColor">
                          Lorem, ipsum.
                        </p>
                        <p className="textColor-light">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Fugit delectus hic rem tenetur ipsum id.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'no-card'}
          <SwiperSlide>
            <div className="rounded-lg flex gap-2 bg-fff theme-bg themeShadow cust-bgColor p-4">
              <div className="w-1/6">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="lg:h-16 md:w-16 w-14 h-14 rounded-full"
                />
              </div>
              <div className="w-5/6">
                <p className="fsize24 sm-fsize18 font-semibold pb-2 cust-textColor">
                  Lorem, ipsum.
                </p>
                <p className="textColor-light">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fugit delectus hic rem tenetur ipsum id.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-lg flex gap-2 bg-fff theme-bg themeShadow cust-bgColor p-4">
              <div className="w-1/6">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="lg:h-16 md:w-16 w-14 h-14 rounded-full"
                />
              </div>
              <div className="w-5/6">
                <p className="fsize24 sm-fsize18 font-semibold pb-2 cust-textColor">
                  Lorem, ipsum.
                </p>
                <p className="textColor-light">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fugit delectus hic rem tenetur ipsum id.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="bdr-bottom"></div>
      <div className="lg:py-10 lg:px-16 md:py-8 py-4 md:px-8 flex">
        <div className="w-1/3 flex flex-col justify-center text-center">
          <p id="1275886712"
                  className="dynamicStyle fsize32 sm-fsize20 font-semibold clr-fff theme-textColor">
            {data ? data['1275886712'] : 'Lorem'}
          </p>
          <p  id="2299300016"
                  className="dynamicStyle fsize16 sm-fsize14 clr-d9d9d9 theme-textColor">
            {data ? data['2299300016'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div className="verticalLine1"></div>
        <div className="w-1/3 flex flex-col justify-center text-center">
          <p id="4633370156"
                  className="dynamicStyle fsize32 sm-fsize20 font-semibold clr-fff theme-textColor">
            {data ? data['4633370156'] : 'Lorem'}
          </p>
          <p id="5263240078"
                  className="dynamicStyle fsize16 sm-fsize14 clr-d9d9d9 theme-textColor">
            {data ? data['5263240078'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div className="verticalLine1"></div>
        <div className="w-1/3 flex flex-col justify-center text-center">
          <p id="8377525562"
                  className="dynamicStyle fsize32 sm-fsize20 font-semibold clr-fff theme-textColor">
             {data ? data['8377525562'] : 'Lorem'}
          </p>
          <p id="3932793028"
                  className="dynamicStyle fsize16 sm-fsize14 clr-d9d9d9 theme-textColor">
             {data ? data['3932793028'] : 'Lorem Ipsum'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard29;
