import { Input, useColorMode } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';

const NewsletterSection6 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <div
      className={
        colorMode === 'light'
          ? 'bg-secondary h-400px flex justify-center items-center theme-bg'
          : 'h-400px flex justify-center items-center theme-bg'
      }
    >
      <div className="text-center px-5">
        <h3
          id="6313250779"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff cust-textColor"
        >
          {data ? data['6313250779'] : 'Lorem Ipsum'}
        </h3>
        <p
          id="6866391405"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['6866391405']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize14 font-semibold clr-fff pb-4 textColor-light"
        ></p>
        <div className="relative w-4/5 mx-auto">
          <Input
            placeholder="Enter Your Email"
            className=" theme_insertShadow rounded-100px bg-fff"
          />
          <div className="w-10 h-10 bg-secondary absolute right-0 top-0 flex justify-center items-center rounded-full z-10">
            <FeatherIcon
              className="text-primary cust-textColor  "
              size={20}
              icon="send"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection6;
