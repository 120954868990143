import { Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const Services8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="lg:w-2/3 md:w-2/3 mx-auto text-center lg:mb-10 md:mb-8 mb-6">
        <p
          id="8484529452"
          className="dynamicStyle font-semibold cust-textColor"
        >
          {data ? data['8484529452'] : 'Lorem Ipsum'}
        </p>
        <p
          id="9370133272"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold pb-2 cust-textColor text-primary"
        >
          {data ? data['9370133272'] : 'Lorem Ipsum'}
        </p>
        <p
          id="0800768574"
          dangerouslySetInnerHTML={{
            __html: data ? data['0800768574'] : 'no-data',
          }}
          className={
            colorMode === 'light'
              ? 'dynamicStyle'
              : 'dynamicStyle textColor-light'
          }
        ></p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-6">

        <div id="7320179864" className='dynamicStyle'>
        {data && data['7320179864']
            ? data['7320179864'].map(e => {
                return (
          <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                 src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                {e.field2}
              </p>
              <p
              dangerouslySetInnerHTML={{
                __html: e.field3,
              }}
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
               
              </p>
            </div>
          </div>
          );
        })
      : 'no-data'}
          {/* <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                Lorem Ipsum
              </p>
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis harum eligendi accusantium.
              </p>
            </div>
          </div>
          <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                Lorem Ipsum
              </p>
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis harum eligendi accusantium.
              </p>
            </div>
          </div> */}
        </div>
        <div id="8266198447" className="dynamicStyle2lg:mb-0 mb-6">
          <Image
            src={data ? getImage(data['8266198447']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="w-full h-400px md-h-300px sm-h-250px"
          />
        </div>
        <div id="4124060467" className='dynamicStyle'>
        {data && data['4124060467']
            ? data['4124060467'].map(e => {
                return (
          <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                 src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                {e.field2}
              </p>
              <p
              dangerouslySetInnerHTML={{
                __html: e.field3,
              }}
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                
              </p>
            </div>
          </div>
          );
        })
      : 'no-data'}
          {/* <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                Lorem Ipsum
              </p>
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis harum eligendi accusantium.
              </p>
            </div>
          </div>
          <div className="flex lg:gap-4 md:gap-0 gap-4 lg:mb-10 md:mb-8 mb-6">
            <div className="lg:w-3/12 md:w-2/12 w-2/12">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="lg:w-20 lg:h-20 md:w-20 md:h-20 w-16 h-16 rounded-full"
              />
            </div>
            <div className="lg:w-9/12 md:w-10/12 w-10/12">
              <p className="fsize18 sm-fsize16 lg:pb-2 md:pb-2 pb-1 font-semibold cust-textColor">
                Lorem Ipsum
              </p>
              <p
                className={
                  colorMode === 'light' ? 'fsize14' : 'fsize14 textColor-light'
                }
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis harum eligendi accusantium.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Services8;
