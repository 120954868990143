import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Input, Textarea, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const LeadSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:flex md:flex items-center">
      <div id="6565915141" className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full h-600px md-h sm-h-300px relative">
        <Image
          src={data ? getImage(data['6565915141']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-600px sm-h-300px w-full filter-b4"
        />
        <div className="lg:px-14 md:px-10 px-5 lg:py-20 md:py-12 py-10 absolute bottom-0 left-0">
          <p
            id="2589772058"
            className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-2 cust-textColor clr-fff"
          >
            {data ? data['2589772058'] : 'Lorem Ipsum'}
          </p>
          <p
          id="5545504842"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['5545504842']
              : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
          }}
            className={
              colorMode === 'light'
                ? 'dynamicStyle sm-fsize14 clr-fff'
                : 'dynamicStyle sm-fsize14 textColor-light'
            }
          >
          </p>
        </div>
      </div>
      <div className="lg:w-1/2 md:w-1/2 w-full lg:px-12 md:px-10 px-5 py-8">
        <div className="grid grid-cols-1 lg:gap-6 md:gap-6 gap-4">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-4">
            <div className="w-full">
              <p
                id="3898310766"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['3898310766'] : 'First Name'}
              </p>
              <Input
                type="text"
                placeholder="First name"
                className="theme-border sm-fsize14 bdr-gray-1px"
              />
            </div>
            <div className="">
              <p
                id="6648999728"
                className="dynamicStyle fsize12 pb-1 cust-textColor"
              >
                {data ? data['6648999728'] : 'Last Name'}
              </p>
              <Input
                type="text"
                placeholder="Last name"
                className="theme-border sm-fsize14 bdr-gray-1px"
              />
            </div>
          </div>
          <div className="">
            <p
              id="7195787682"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['7195787682'] : 'Email Id'}
            </p>
            <Input
              type="text"
              placeholder="Email Id"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
          <div className="">
            <p
              id="2843964761"
              className="dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['2843964761'] : 'Phone Number'}
            </p>
            <Input
              type="text"
              placeholder="Phone Number"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
          <div>
            <p
              id="4803893092"
              className="dynamicStyle dynamicStyle fsize12 pb-1 cust-textColor"
            >
              {data ? data['4803893092'] : 'Lorem Ipsum'}
            </p>
            <Textarea
              name="description"
              row={4}
              id=""
              placeholder="Type here"
              className="theme-border sm-fsize14 bdr-gray-1px"
            />
          </div>
        </div>
        <div className="flex lg:mt-8 md:mt-8 mt-6">
          <Button
            px={8}
            py={4}
            className={
              colorMode === 'light'
                ? 'dynamicStyle w-full bg-primary textwhite theme-btn sm-fsize10 '
                : 'dynamicStyle w-full bg-darkprimary textwhite theme-btn sm-fsize10 '
            }
            id="8989820719"
          >
            <span onClick={() => handleClick('8989820719')}>
              {data ? data['8989820719'] : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeadSection3;
