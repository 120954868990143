import { Image } from '@chakra-ui/react';
import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';

const GallerySection13 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  console.log(data[2017369988], 'fds');
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center">
        <h2
          id="6425311576"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary textColor-light"
        >
          {data ? data['6425311576'] : 'Collections'}
        </h2>
        <h4
          id="6823883269"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-semibold "
        >
          {data ? data['6823883269'] : 'Collections'}
        </h4>
      </div>
      <div id="2017369988" className="dynamicStyle lg:mt-12 md:mt-12 mt-6">
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper p-2"
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {data && data['2017369988']
            ? data['2017369988'].map(e => {
                return (
                  <SwiperSlide>
                    <div className="relative gradient h-96 overflow-hidden">
                      <Image
                        src={e.field3}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="gallery"
                        className="object-cover h-96 rounded-lg"
                      />
                      <div className="absolute z-20 bottom-0 left-0 p-4">
                        <h5 className="fsize24 text-white font-semibold">
                          {e.field1}
                        </h5>
                        <div className="flex items-center gap-2 mt-1">
                          <div>
                            <Image
                              src={e.field4}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              alt="gallery"
                              className="w-4 h-4 rounded-full"
                            />
                          </div>
                          <p className="fsize14 text-white font-medium">
                            {e.field2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'no-data'}
        </Swiper>
      </div>
    </div>
  );
};

export default GallerySection13;
