import React, { useEffect, useRef, useState } from "react";
import { Image, Button, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";

const LeftTextRightImg = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  let selectedStyle = useSelector(
    (state) => state.brandDetails.brandProfile.businessThemeVariation
  );
  const { colorMode, toggleColorMode } = useColorMode();
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="bg-white">
      {" "}
      <div className="lg:flex md:flex container mx-auto  sm-reverse  lg:py-16 md:py-12 lg:px-12 md:px-8 px-5">
        <div className="lg:w-1/2 md:w-1/2 lg:py-0 md:py-0 py-6 relative zindex9 flex flex-col justify-center">
          <h2
            id="2491847836"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20  text-primary cust-textColor font-bold"
          >
            {data
              ? data["2491847836"]
              : "FINED YOUR BEST COACH NEAREST TO YOU AND BOOK YOUR SLOT EASILY"}
          </h2>
          <p
            id="8410324712"
            dangerouslySetInnerHTML={{
              __html: data ? data["8410324712"] : "no-data",
            }}
            className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 mt-2 lg:leading-7 md:leading-7 leading-6"
          ></p>
          {/* <Button
            width="180px"
            height="50px"
            className={
              colorMode === "light"
                ? "dynamicStyle  bg-primary lg:mt-8 md:mt-6 mt-4  theme-btn  clr-fff  bdr-2px"
                : "dynamicStyle theme-btn cust-bgColor lg:mt-8 md:mt-6 mt-4 clr-fff buttonAnimate bdr-2px"
            }
            id="8752237435"
          >
            <span onClick={() => handleClick("8752237435")}>
              {" "}
              {data ? data["8752237435"] : "Find Coach"}
            </span>
          </Button> */}
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:pl-12 md:pl-12 lg:mt-0 md:mt-0 mt-12">
          <div
            ref={elementRef}
            className={`fade-right relative zindex9 w-full ${
              isVisible ? "visibleright" : ""
            } `}
          >
            <div id="8498811174" className="dynamicStyle2">
              <Image
                src={data ? getImage(data["8498811174"]) : ""}
                alt="heroImage"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full sm-h250px minh450px object-contain rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftTextRightImg;
