import React, { useEffect, useRef, useState } from 'react';
// import 'swiper/css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules';
import { Image, Card } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import Marquee from 'react-fast-marquee';

const Clients1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
 
  const data = useSelector(state => state.cms.allSections[pageName]);
  console.log(data[4217500907], 'fd');
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);


  return (
    <section>
      <div className="container mx-auto lg:py-8 md:py-8 py-8 lg:px-12 md:px-8 px-5">
        <div
          ref={elementRef}
          className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
        >
          <div id="7724235249" className="dynamicStyle">
          <Marquee loop={0} autoFill={true}>
              {data && data['7724235249']
                ? data['7724235249'].map(e => {
                    return (
                      <Card>
                       
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            className="rounded-md img-ratio lg:h-40 md:h-32 mx-3 h-24"
                          />
                   
                      </Card>
                    );
                  })
                : 'no-data'}
            
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients1;
