import { Badge, Card, Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { handleScroll } from './fadeeffect/FadeEffect';

const Blogs3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
        <div className="">
          <div className="justify-center flex w-full">
            <div>
              <p
                id="4949108031"
                className="dynamicStyle cust-textColor fsize16 md-fsize14 sm-fsize13 text-primary text-center font-semibold"
              >
                {data ? data['4949108031'] : 'Lorem ipsum'}
              </p>
              <h5
                id="5488393521"
                className="dynamicStyle cust-textColor fsize32 text-center md-fsize26 sm-fsize24 font-semibold"
              >
                {data ? data['5488393521'] : 'Lorem ipsum'}
              </h5>
            </div>
          </div>
          <div className="w-full lg:mt-8 md:mt-5 mt-4">
            <Swiper
              spaceBetween={25}
              slidesPerView={3.2}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper lg:p-2"
              autoplay={{
                delay: '1000',
              }}
              loop
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary cust-bgColor rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {allBlog && allBlog.length > 0
                ? allBlog.map(item => (
                    <SwiperSlide>
                      <Card boxShadow='none' background='none' className="cursor-pointer">
                        <div className="w-full">
                          <div className="overflow-hidden rounded-lg">
                            <Image
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              className="w-full lg:h-60 h-40 rounded-lg custimghover"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="py-2">
                          <div className="">
                            <h5 className="fsize20 md-fsize18 sm-fsize16 font-semibold pb-1 cust-textColor">
                              {item.title}
                            </h5>
                            <p
                              className="fsize14 lineClamp3 textColor-light"
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></p>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  ))
                : 'no-data'}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs3;
