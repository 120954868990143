import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProductCategories4 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <section>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="w-full flex justify-between items-center">
          <p
            id="8684278230"
            className="dynamicStyle text-black cust-textColor inline-block text-center fsize34 md-fsize24 sm-fsize20 font-semibold"
          >
            {data ? data['8684278230'] : 'Our Category'}
          </p>
          <Button
            id="0960639460"
            className={
              colorMode === 'light'
                ? 'dynamicStyle text-black bg-transparent fsize14 theme-btn themeShadow'
                : 'dynamicStyle clr-fff cust-bgColor fsize14 theme-btn themeShadow'
            }
          >
            <span onClick={() => handleClick('0960639460')}>
              {data ? data['0960639460'] : 'View All'}
            </span>
          </Button>
        </div>
        <div className="lg:pt-12 md:pt-12 mt-8 grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-8">
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'bg-primary theme-bg themeShadow-insert themeShadow'
                : 'theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
          <Card
            borderRadius={12}
            className={
              colorMode === 'light'
                ? 'box-shadow-none bg-primary theme-bg themeShadow-insert themeShadow'
                : 'box-shadow-none theme-bg themeShadow-insert themeShadow'
            }
          >
            <div className="lg:p-6 md:p-6 p-4 w-full ">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="cat_Img"
                className="w-24 h-24 mx-auto object-cover"
              />
              <p className="lineClamp2 text-center text-black cust-textColor fsize18 md-fsize18 sm-fsize16 pt-3 text-black font-medium">
                Title
              </p>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default ProductCategories4;
