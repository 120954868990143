import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';

const Login2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      {/* change-password */}
      <div className="container mx-auto h-100vh flex justify-center items-center ">
        <div id="2344189220" className="dynamicStyle2 lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5">
          <Image
            src={data ? getImage(data['2344189220']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            className="w-24 h-24 mx-auto rounded-full"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2 id="3890795803"
              className="dynamicStyle text-center fsize36 md-fsize32 sm-fsize24 font-semibold cust-textColor">
              {data ? data['3890795803'] : 'Lorem Ipsum'}
            </h2>
            <p id="8677672691"
              className="dynamicStyle text-center fsize14 textColor-light">
             {data ? data['8677672691'] : 'Lorem Ipsum'}
            </p>
            <div className="mt-8">
              <Input
                placeholder="Enter New Password"
                className="mb-4 cust-textColor"
                py="6"
              />
              <Input
                placeholder="Confirm New Password"
                className="mb-6 cust-textColor"
                py="6"
              />
            </div>

            <Button
              colorScheme="primary"
              py="6"
              className="dynamicStyle w-full bg-primary cust-textColor cust-bgColor"
              id="7579033606"
            >
             <span onClick={() => handleClick('7579033606')}>
                    {data ? data['7579033606'] : 'Lorem Ipsum'}
                  </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login2;
