import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Card, Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard23 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
  return (
    <div className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
        <div className="lg:flex md:flex block justify-center items-center">
          <div className="lg:w-4/12 md:w-4/12 w-full lg:pr-5 md:pr-4 pr-0">
            <h5
              id="8231509598"
              className="dynamicStyle cust-textColor fsize32 md-fsize26 sm-fsize24 font-semibold"
            >
              {data ? data['8231509598'] : 'Lorem Ipsum'}
            </h5>
            <p
              id="0295825076"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0295825076']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 mt-2"
            >
              
            </p>
            <div className="flex items-center gap-2 mt-4">
              <div className="lg:w-10 lg:h-10 w-8 h-8 bg-primary rounded-full flex items-center justify-center">
                <FeatherIcon
                  icon="arrow-right"
                  className="clr-fff flex"
                  size="18"
                />
              </div>
              <p
                id="3333338865"
                className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize13 text-gray uppercase font-semibold"
              >
                {data ? data['3333338865'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>
          <div id="7427041232"
          className="dynamicStyle lg:w-8/12 md:w-8/12 w-full lg:mt-0 md:mt-0 mt-4">
            <Swiper
              spaceBetween={25}
              slidesPerView={3.2}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper lg:p-2"
              autoplay={{
                delay: '1000',
              }}
              loop
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
                <svg
                  stroke="currentColor"
                  color="white"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {data && data['7427041232']
            ? data['7427041232'].map((e, index) => {
                return (
              <SwiperSlide>
                <Card className="cursor-pointer cust-shadow rounded-lg overflow-hidden">
                  <div className="relative feature23">
                    <Image
                      src={e ? getImage(e.field1) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                      className="lg:h-72 md:h-52 h-52 w-full"
                    />
                    <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full features"></div>
                    <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full lg:p-6 mg:p-4 p-3 flex items-end">
                      <div className="">
                        <h3 className="fsize20 md-fsize16 sm-fsize16 text-white font-semibold">
                        {e.field2}
                        </h3>
                        <div className="featurecardhover">
                          <p
                             dangerouslySetInnerHTML={{
                              __html: e.field3,
                            }}
                            className="clr-fff cust-textColor fsize13 md-fsize13 sm-fsize13 mt-2 lineClamp2"
                          >
                            
                          </p>
                          <div className="flex items-center gap-2 mt-2">
                            <p
                              id=""
                              className="clr-fff cust-textColor fsize13 md-fsize13 sm-fsize13"
                            >
                             {e.field4}
                            </p>
                            <FeatherIcon
                              icon="arrow-right"
                              className="clr-fff flex"
                              size="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
               );
              })
            : 'no-card'}
              
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard23;
