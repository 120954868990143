import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection11 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);
  
  return (
    <div 
    className={
        colorMode === 'light'
          ? 'lg:flex md:flex items-center bg-secondary theme-bg'
          : 'lg:flex md:flex items-center theme-bg'
      }>
      <div id="1866920689"
            className="dynamicStyle2 h-400px sm-h250px lg:w-5/12 md:w-5/12 w-full lg:mb-0 md:mb-0 mb-4">
      <Image
              src={data ? getImage(data['1866920689']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
          className="h-400px sm-h250px w-full"
        />
      </div>
      <div className="lg:w-7/12 md:w-7/12 w-full lg:px-10 md:px-8 px-5 py-6">
        <div className='lg:w-10/12 w-full'>
        <div className="lg:mb-12 md:mb-10 mb-6">
          <p id="9622545121"
              className="dynamicStyle fsize32 md-fsize28 sm-fsize20 font-semibold pb-3 clr-fff lg:leading-10 md:leading-8 leading-5 cust-textColor theme-textColor">
            {data ? data['9622545121'] : 'Lorem Ipsum'}
          </p>
          <p
          id="4974429242"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4974429242']
              : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
          }}
          className={
            colorMode === 'light'
              ? 'dynamicStyle clr-fff sm-fsize14 theme-textColor'
              : 'dynamicStyle clr-fff sm-fsize14 theme-textColor textColor-light '
          }>
            
          </p>
        </div>
        <div className="flex gap-4 mt-2">
              <div className='relative w-9/12'>
              <Input
                type="text"
                size="lg"
                placeholder="Email"
                className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
              />
              <FeatherIcon className="absolute top-3 right-6 text-gray cust-textColor" size={20} icon="search" />
              </div>
              <Button
                size="lg"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle w-3/12 bdr-0px bg-fff theme-btn sm-fsize14 md-fsize14 clr-9c9c9c'
                    : 'dynamicStyle w-3/12 bdr-0px cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
                }
                id="6293316404"
              >
                 <span onClick={() => handleClick('6293316404')}>
              {data ? data['6293316404'] : 'Submit'}
            </span>
      
              </Button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection11;
