import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';

const Footer15 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  return (
    <div
      className={
        colorMode === 'light'
          ? 'bg-primary rounded-t-3xl theme-bg'
          : 'cust-bgColor theme-bg rounded-t-3xl'
      }
    >
      <div className="container mx-auto lg:px-12 md:px-8 px-5 py-8">
        <div
          className={
            colorMode === 'light'
              ? 'bg-secondary rounded-lg lg:flex items-center gap-8 px-6 py-8 lg:mt-6 md:mt-6 mt-0 themeShadow theme-bg'
              : 'bg-secondary rounded-lg lg:flex items-center gap-8 px-6 py-8 lg:mt-6 md:mt-6 mt-0 themeShadow theme-bg'
          }
        >
          <div className="lg:w-2/5 ">
            <p
              id="6564610403"
              className="dynamicStyle fsize20 md-fsize18 font-semibold clr-fff theme-textColor lg:pb-0 pb-4"
            >
              {data ? data['6564610403'] : 'Lorem Ipsum'}
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'lg:flex md:flex lg:w-3/5 lg:gap-6 md:gap-6 gap-4 bg-fff theme-bg rounded-lg items-center lg:px-5 md:px-5 px-4 py-4 themeShadow'
                : 'lg:flex md:flex lg:w-3/5 lg:gap-6 md:gap-6 gap-4 cust-bgColor rounded-lg items-center lg:px-5 md:px-5 px-4 py-4 themeShadow'
            }
          >
            <Input
              placeholder="Enter email id"
              className="lg:w-3/5 md:w-3/5 w-2/5 lg:mb-0 md:mb-0 mb-3 theme_insertShadow"
            />
            <Button
              colorScheme="secondary"
              className="theme-radiusfull bg-secondary clr-fff cust-textColor sm-fsize14 theme-btn bdr-2px lg:w-2/5 md:w-2/5 w-3/5 mx-auto"
              size="md"
            >
              Subsribe Now
            </Button>
          </div>
        </div>
        <div className="lg:flex md:flex justify-between py-8">
          <div className="flex justify-center gap-4 lg:pb-0 md:pb-0 pb-4">
            <p className="font-semibold cust-textColor">Home</p>
            <p className="font-semibold cust-textColor">Category</p>
            <p className="font-semibold cust-textColor">About</p>
            <p className="font-semibold cust-textColor">Category</p>
          </div>
          <div className="flex justify-center gap-10">
            <FeatherIcon className="cust-textColor" size={22} icon="facebook" />
            <FeatherIcon className="cust-textColor" size={22} icon="twitter" />
            <FeatherIcon
              className="cust-textColor"
              size={22}
              icon="instagram"
            />
            <FeatherIcon className="cust-textColor" size={22} icon="youtube" />
          </div>
        </div>
        <hr />
        <div className="lg:flex md:flex items-center pt-6 sm-reverse">
          <div className="lg:w-3/12 md:w-3/12">
            <p className="cust-textColor">&#169; 2021, All rights reserved</p>
          </div>
          <div className="lg:w-6/12 md:w-6/12 mob-hidden">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-36 lg:mx-auto md:mx-auto"
            />
          </div>
          <div className="flex lg:justify-end md:lg:justify-end gap-6 lg:w-3/12 md:w-3/12 lg:my-0 md:my-0 my-4">
            <p className="cust-textColor">Terms of service</p>
            <p className="cust-textColor">Privacy Policy</p>
          </div>
          <div className="lg:w-6/12 md:w-6/12 lg:hidden md:hidden ">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-36 lg:mx-auto md:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer15;
