import React, { useState } from 'react';
import { Button, Image, Input, Select } from '@chakra-ui/react';
import BookingPortal from '../../components/Forms/BookingPortal';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
} from '@chakra-ui/react';
import { Calendar } from 'react-calendar';

const BookingPortal5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const [value, onChange] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTimezone, setselectedTimezone] = useState('');

  const oncloseBooking = () => {
    console.log('data');
  };
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className=" w-full bg-f4f5f6 cust-bgColor lg:px-12 md:px-8 px-5">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="bg-primary cust-bgColor">
            <h5 className="text-white fsize17">Please Enter your details</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} color="white" />
          <ModalBody>
            <div className="lg:py-3 py-2">
              <div className="">
                <Input
                  placeholder="Name"
                  className="mt-2 textColor-light"
                  h={12}
                />
                <Input
                  placeholder="Email Id"
                  className="mt-2 textColor-light"
                  h={12}
                />
                <Input
                  placeholder="Phone Number"
                  className="mt-2 textColor-light"
                  h={12}
                />
                <Input
                  placeholder="Time Zone"
                  type="date"
                  className="mt-2 textColor-light"
                  h={12}
                />
                <Input
                  placeholder="Time Zone"
                  type="time"
                  className="mt-2 textColor-light"
                  h={12}
                />
              </div>
              <div className="mt-5">
                <Button
                  colorScheme="primary"
                  className={
                    colorMode === 'light'
                      ? 'w-full sm-fsize13 lg:py-6'
                      : 'w-full sm-fsize13 lg:py-6 bg-glass cust-textColor'
                  }
                >
                  Shedule Appointment
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="container mx-auto">
        <div className="lg:flex md:flex lg:py-16 md:py-16 py-12">
          <div className="lg:w-7/12 md:w-1/2 w-full">
            <div className="text-left lg:pr-10 pr-3 lg:mt-5">
              <h4 id="6245071484"
              className="dynamicStyle fsize28 md-fsize26 sm-fsize23 font-semibold pb-2 bordb cust-textColor">
                {data ? data['6245071484'] : 'Lorem Ipsum'}
              </h4>
              <div
                className={colorMode === 'light' ? 'mt-2' : 'p-2 bg-glass mt-2'}
              >
                <Calendar className="mt-4 w-full" value={value} />
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-1/2 w-full lg:py-0 md:py-0 py-5 lg:pl-0 md:pl-4 pl-0">
            <div
              className={
                colorMode === 'light'
                  ? 'lg:p-8 md:p-6 p-4 lg:mt-3 mt-2 bg-white rounded-lg'
                  : 'lg:p-8 md:p-6 p-4 lg:mt-3 mt-2 bg-glass rounded-lg'
              }
            >
              <BookingPortal
                pageName={pageName}
                formId={'6621069127c2a60012f59d1e'}
                close={oncloseBooking}
                edit={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPortal5;
