import { Avatar, AvatarGroup, Button, Checkbox } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const PollSection3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <h3 id='6103710288' className="dynamicStyle fsize34 md-fsize28 sm-fsize24 font-semibold text-black cust-textColor">
        {data ? data['6103710288'] : 'Poll Question 3'}
        </h3>
        <p id='3279787129' className="dynamicStyle fsize14 md-fsize14 sm-fsize12 lg:w-2/3 text-black textColor-light"
       
         dangerouslySetInnerHTML={{
           __html: data
             ? data['3279787129']
             : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
         }}>
         
        </p>
        <div className="lg:py-12 w-full flex flex-col gap-3 lg:w-1/3 md:w-1/2">
          <div className="border1 py-2 px-6 rounded-full">
            <Checkbox className="text-black textColor-light font-medium">
              2021-22
            </Checkbox>
          </div>
          <div className="border1 py-2 px-6 rounded-full">
            <Checkbox className="text-black textColor-light font-medium">
              2021-22
            </Checkbox>
          </div>
          <div className="border1 py-2 px-6 rounded-full">
            <Checkbox className="text-black textColor-light font-medium">
              2021-22
            </Checkbox>
          </div>
        </div>
        <div className="mt-4 w-full flex justify-between">
          <div className="flex items-center gap-3">
            <AvatarGroup size="sm" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <div className="flex gap-1">
              <p
                className="fsize12 text-black textColor-light dynamicStyle"
                id="1305092389"
              >
                {data ? data['1305092389'] : 'Total votes:'}
              </p>
              <p className="fsize12 text-black textColor-light">24</p>
            </div>
          </div>
          {/* <Button
            className="bg-primary cust-bgColor theme-btn clr-fff"
            fontWeight={400}
            borderRadius={2}
          >
            Vote
          </Button> */}
        </div>
      </div>
    </section>
  );
};

export default PollSection3;
