import { Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const AboutSection31 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto  lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5">
      <div className="lg:flex md:flex md-reverse gap-6 w-full">
        <div className="w-full">
          <p
            className="uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor dynamicStyle"
            id="9186888589"
          >
            {data ? data['9186888589'] : 'Who we are'}
          </p>
          <p
            className="lg:leading-10 fsize36 md-fsize24 sm-fsize20 font-bold cust-textColor dynamicStyle"
            id="3211784218"
          >
            {data
              ? data['3211784218']
              : 'Take you to a better world where everyone drinks Fruit Juice.'}
          </p>
          <p
            id="9137511693"
            className="dynamicStyle fsize14 textColor-light mt-2"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9137511693']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
          ></p>
          <div id='4987262265' className="dynamicStyle w-full lg:mt-6 md:mt-6 mt-3 lg:mb-0 md:mb-0 mb-6 grid grid-cols-1 gap-y-6">
            {data && data['4987262265']
              ? data['4987262265'].map(e => {
                  return (
                    <div className="flex lg:items-center md:items-center items-start p-4 w-full cust-shadow themeShadow theme-bg rounded-md">
                      <Image
                        src={e ? getImage(e.field2) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                       alt="img"
                        className="w-20 h-20"
                      />
                      <div className="ml-4">
                        <p className="cust-textColor fsize20 md-fsize18 sm-fsize16 font-semibold">
                        {e.field2}
                        </p>
                        <p className="fsize14 textColor-light lineClamp3">
                        {e.field3}
                        </p>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
        <div className="w-full dynamicStyle2" id="9920771934">
          <Image
            src={data ? getImage(data['9920771934']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="about"
            className="rounded-md md-h-400px w-full object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection31;
