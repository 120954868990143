import { Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const AboutSection43 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className={colorMode === 'light' ? 'theme-bg' : 'theme-bg'}>
      <div className="container mx-auto lg:px-12 md:px-8 px-4 lg:py-20 md:py-16 py-8">
        <div className="w-full lg:flex md:flex items-center gap-8">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="lg:w-2/3 md:w-2/3">
              <p
                id="7668749890"
                className="dynamicStyle fsize34 md:fsize24 sm-fsize20 font-semibold text-black cust-textColor"
              >
                {data ? data['7668749890'] : 'Easy to integrate, everywhere'}
              </p>
              <p
                id="5572062630"
                className="dynamicStyle fsize16 md-fsize16 fsize14 py-3 text-black textColor-light"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['5572062630']
                    : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
                }}
              ></p>
              <div
                id="9603251879"
                className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-3 grid-cols-3 gap-6 w-full lg:mt-10 md:mt-10 mt-6"
              >
                {data && data['9603251879']
                  ? data['9603251879'].map(e => {
                      return (
                        <Image
                          src={e ? getImage(e.field) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="about_img"
                          className="object-cover w-full h-16"
                        />
                      );
                    })
                  : 'no-data'}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg€;mt-0 md:mt-0 mt-8">
            <div
              id="3034777309"
              className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-16 md:gap-12 gap-6 w-full lg:mt-6 md:mt-6 mt-4"
            >
              {data && data['3034777309']
                ? data['3034777309'].map(e => {
                    return (
                      <div className="">
                        <p className="text-black cust-textColor fsize18 md-fsize18 sm-fsize16 lg:pr-6 font-semibold lg:mb-3 md:mb-2 mb-1">
                          {e.field1}
                        </p>
                        <p
                          className="lineClamp3 fsize14 text-black textColor-light"
                          dangerouslySetInnerHTML={{
                            __html: e.field2,
                          }}
                        ></p>
                      </div>
                    );
                  })
                : 'no-data'}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection43;
