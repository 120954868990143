import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AboutSection42 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-4 lg:py-16 md:py-16 py-8">
      <div className="text-center w-4/5 mx-auto mb-10">
        <h2
          id="1473009227"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold pb-4"
        >
          {data ? data['1473009227'] : 'Lorem ipsum dolor sit amet'}
        </h2>
        <p
          id="0547102792"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['0547102792']
              : 'Experience top-notch detailing services for a pristine shine.',
          }}
          className="dynamicStyle text-gray"
        ></p>
      </div>
      <div id="3636683722" className="dynamicStyle2 h-600px w-full relative">
        <Image
          src={data ? getImage(data['3636683722']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-600px w-full object-contain"
        />
        <div className="flex justify-between w-3/5 about42-top-absl">
          <div className="w-4/12">
            <p
              id="4315624109"
              className="dynamicStyle text-primary font-semibold pb-2"
            >
              {data ? data['4315624109'] : 'Lorem ipsum'}
            </p>
            <p
              id="5080357692"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5080357692']
                  : 'Experience top-notch detailing services for a pristine shine.',
              }}
              className="dynamicStyle fsize12"
            ></p>
          </div>
          <div className="w-4/12">
            <p
              id="4149707008"
              className="dynamicStyle text-primary font-semibold pb-2"
            >
              {data ? data['4149707008'] : 'Lorem ipsum'}
            </p>
            <p
              id="6016473830"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6016473830']
                  : 'Experience top-notch detailing services for a pristine shine.',
              }}
              className="dynamicStyle fsize12"
            ></p>
          </div>
        </div>
        <div className="flex justify-between w-3/5 about42-bot-absl">
          <div className="w-4/12">
            <p
              id="9157659619"
              className="dynamicStyle text-primary font-semibold pb-2"
            >
              {data ? data['9157659619'] : 'Lorem ipsum'}
            </p>
            <p
              id="7999881989"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['7999881989']
                  : 'Experience top-notch detailing services for a pristine shine.',
              }}
              className="dynamicStyle fsize12"
            ></p>
          </div>
          <div className="w-4/12">
            <p
              id="0936366308"
              className="dynamicStyle text-primary font-semibold pb-2"
            >
              {data ? data['0936366308'] : 'Lorem ipsum'}
            </p>
            <p
              id="0950808745"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0950808745']
                  : 'Experience top-notch detailing services for a pristine shine.',
              }}
              className="dynamicStyle fsize12"
            ></p>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default AboutSection42;
