import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Input, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const NewsletterSection13 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto my-4 lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex items-center overflow-hidden gap-8 rounded-lg cust-shadow cust-bgColor bg-white">
        <div className="lg:w-8/12 md:w-1/2 w-full lg:px-12 md:px-6 px-5 lg:py-0 md:py-0 py-5">
          <p
            id="2876255246"
            className="dynamicStyle fsize32 md-fsize24 sm-fsize20 font-semibold pb-2 cust-textColor"
          >
            {data ? data['2876255246'] : 'Lorem Ipsum'}
          </p>
          <p
            id="8456263818"
            className={
              colorMode === 'light'
                ? 'dynamicStyle md-fsize14 sm-fsize14'
                : 'dynamicStyle md-fsize14 textColor-light sm-fsize14'
            }
          >
            {data ? data['8456263818'] : 'Lorem Ipsum'}
          </p>
          <Input
            type="text"
            size="md"
            placeholder="Email"
            className="w-full lg:mt-6 md:mt-3 mt-2 bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
          />
        </div>
        <div className="lg:w-4/12 md:w-1/2 bg-ec w-full lg:px-12 lg:py-12 md:px-8 md:py-8 px-4 py-4">
          <div id="2651013520" className='dynamicStyle'>
            {data && data['2651013520']
              ? data['2651013520'].map(e => {
                  return (
                    <div className="flex items-start gap-1 mb-2">
                      <FeatherIcon icon="check" className="flex" size="22" />
                      <p className="fsize16 sm-fsize14 my-0 text-dark">{e.field1}</p>
                    </div>
                  );
                })
              : 'no-data'}
          </div>

          <Button
            size="lg"
            className={
              colorMode === 'light'
                ? 'dynamicStyle w-full rounded-lg lg:mt-6 md:mt-4 mt-4 bg-primary theme-btn sm-fsize14 md-fsize14'
                : 'dynamicStyle w-full rounded-lg lg:mt-6 md:mt-4 mt-4 cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14'
            }
            id="1849980421"
          >
            <span onClick={() => handleClick('1849980421')}>
              {data ? data['1849980421'] : 'Submit'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection13;
