import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Image,
  Card,
  useColorMode,
} from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FiChevronDown } from 'react-icons/fi';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import { logout } from '../../../redux/auth/auth-action';
import { Avatar } from '@chakra-ui/avatar';
import GoogleTranslate from "../tarnslate/Googletranslate";

const Header1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let menuData = useSelector(state => state.menuState.menu);
  let userData = useSelector(state => state.AuthDetails.user);
  // console.log(userData, 'checkm');
  const { colorMode, toggleColorMode } = useColorMode();
  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      try {
        const data = await dispatch(logout(payload));
        if (data && data.code == 200) {
          Swal.fire({
            title: 'Success',
            text: `Logged out successfully`,
            icon: 'success',
            timer: '2500',
          });
          navigate('/');
        } else {
          if (data.message) {
            Swal.fire({
              title: 'Error',
              text: data.message,
              icon: 'error',
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <section>
      <Card className="neu-header lg:py-4 md:py-4 py-4 lg:px-12 md:px-8 px-5 z-10">
        <div className=" flex justify-between items-center">
          <a href="/">
            <Image
              src={getPrimaryImage(brandProfile)}
              alt="logo"
              className="lg:w-32 w-8 h-8 object-contain"
            />
          </a>

          <div className="lg:flex md:hidden hidden items-center justify-center lg:gap-8 md:gap-4 gap-4">
            {menuData
              ? menuData.map((e, i) => {
                  return (
                    <div key={i}>
                      {e.menutype === 'page' && e.subMenu.length < 1 ? (
                        <NavLink
                          to={`/${e.pageData[0].page}`}
                          onClick={() => setclick(e)}
                          className={
                            colorMode === 'light'
                              ? 'navbartextColor-light  font-medium cursor-pointer'
                              : 'navbartextColor-light fordarkmode font-medium cursor-pointer'
                          }
                        >
                          <p className="md-fsize14">{e.menuName}</p>
                        </NavLink>
                        
                      ) : (
                        ''
                      )}

                      {e.menutype === 'url' && e.subMenu.length < 1 ? (
                        <NavLink
                          // to={`/${e.pageData[0].page}`}
                          onClick={() => setclick(e)}
                        >
                          <p
                            className={
                              navbar === e
                                ? 'menuHover font-semibold cursor-pointer'
                                : 'cust-textColor font-medium cursor-pointer '
                            }
                          >
                            {e.menuName}
                          </p>
                        </NavLink>
                      ) : (
                        ''
                      )}

                      {e.menutype === 'page' && e.subMenu.length > 1 ? (
                        <Menu>
                          <MenuButton
                            px={0}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="0px"
                            className="font-medium navbartextColor-light"
                            // _hover={{ bg: 'gray.400' }}
                            // _expanded={{ bg: 'blue.400' }}
                            // _focus={{ boxShadow: 'outline' }}
                          >
                            {e.menuName} <ChevronDownIcon />
                          </MenuButton>
                          <MenuList>
                            {e.subMenu.map((sub, i) => {
                              return (
                                <NavLink to={`/${sub.pageData[0].page}`}>
                                  <MenuItem
                                    className="navbartextColor-light"
                                    key={i}
                                  >
                                    {sub.menuName}
                                  </MenuItem>
                                </NavLink>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })
              : ''}
          </div>
          <div className="lg:hidden md:block block">
            <Button ref={btnRef} onClick={onOpen}>
              <FaBars />
            </Button>
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton mt={2} color="white" />
                <DrawerHeader
                  className={colorMode === 'light' ? 'bg-primary' : ''}
                >
                  <a href="/">
                    <Image
                      src={getPrimaryImage(brandProfile)}
                      alt="logo"
                      className="lg:w-24 w-8 h-8 object-contain"
                    />
                  </a>
                </DrawerHeader>

                <DrawerBody>
                  <div className=" relative h-full">
                    <div className="pt-2">
                      <a href="/">
                        <div className="flex gap-2 items-center ">
                          <h2 className=" font-semibold fsize16">
                            {/* {userData.firstName}sssss */}
                          </h2>
                        </div>
                      </a>
                      <div className="lg:mt-4 mt-2">
                        {menuData
                          ? menuData.map((e, i) => {
                              return (
                                <div key={i}>
                                  {e.menutype === 'page' &&
                                  e.subMenu.length < 1 ? (
                                    <NavLink
                                      to={`/${e.pageData[0].page}`}
                                      onClick={() => setclick(e)}
                                      className={
                                        colorMode === 'light'
                                          ? 'navbartextColor-light  font-medium cursor-pointer'
                                          : 'navbartextColor-light fordarkmode font-medium cursor-pointer'
                                      }
                                    >
                                      <p className="mb-4">{e.menuName}</p>
                                    </NavLink>
                                  ) : (
                                    ''
                                  )}

                                  {e.menutype === 'page' &&
                                  e.subMenu.length > 1 ? (
                                    <Menu>
                                      <MenuButton
                                        px={0}
                                        py={0}
                                        transition="all 0.2s"
                                        borderRadius="md"
                                        borderWidth="0px"
                                        className="font-medium mb-4 navbartextColor-light"
                                        // _hover={{ bg: 'gray.400' }}
                                        // _expanded={{ bg: 'blue.400' }}
                                        // _focus={{ boxShadow: 'outline' }}
                                      >
                                        {e.menuName} <ChevronDownIcon />
                                      </MenuButton>
                                      <MenuList>
                                        {e.subMenu.map((sub, i) => {
                                          return (
                                            <MenuItem key={i}>
                                              {sub.menuName}
                                            </MenuItem>
                                          );
                                        })}
                                      </MenuList>
                                    </Menu>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            })
                          : ''}
                      </div>
                    </div>
                  </div>

                  {/* {userData && userData._id && userData.token ? (
                    <button
                      onClick={() => handleLogout()}
                      className="flex items-center"
                    >
                      {' '}
                      <FeatherIcon className="mr-2" icon="log-out" size={18} />
                      Logout
                    </button>
                  ) : (
                    <Button
                      px="8"
                      colorScheme="primary"
                      onClick={() => {
                        navigate('/login');
                        onClose();
                      }}
                      className="mt-4 py-2 bg-secondary text-white font-semibold px-8"
                    >
                      Sign In
                    </Button>
                  )} */}

                  {/* <button
                    onClick={() => navigate('/login')}
                    className="mt-4 py-2 bg-secondary text-white font-semibold px-8"
                  >
                    Sign In
                  </button> */}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>
          <div className="lg:flex gap-2 md:hidden hidden">
          <GoogleTranslate />
            {userData && userData._id && userData.token ? (
              <a href="/user-profile" className="flex">
                <div className="flex gap-2 items-center mob-hidden">
                  <Avatar
                    name="profile"
                    size="sm"
                    src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  />
                  <h2 className="font-semibold textColor-light">
                    {userData.firstName}
                  </h2>
                </div>
              </a>
            ) : (
              <>
             
                {/* <Button
                  px="8"
                  colorScheme="primary"
                  variant="outline"
                  onClick={() => {
                    navigate('/login');
                    onClose();
                  }}
                  className=" cust-bgColor text-white textColor-light font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block mob-hidden buttonAnimate bdr-2px"
                >
                  Login
                </Button>
                <Button
                  px="8"
                  colorScheme="primary"
                  onClick={() => {
                    navigate('/register');
                    onClose();
                  }}
                  className="cust-bgColor text-white textColor-light font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block mob-hidden buttonAnimate bdr-2px"
                >
                  Register
                </Button> */}
              </>
            )}
          </div>

          {/* <button
            onClick={() => navigate('/login')}
            className="bg-secondary text-white font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block hidden"
          >
            Sign In
          </button> */}
        </div>
      </Card>
    </section>
  );
};

export default Header1;
