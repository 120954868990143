import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Switch, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useDispatch } from 'react-redux';
import { getPlan } from '../../../redux/custom/index';
import axios from 'axios';

import Select from 'react-select';

const SubscriptionPlan7 = ({ pageName }) => {
  const dispatch = useDispatch();
  const [check, setcheck] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();
  // const getPlanData = useSelector(state => state.plan.Plan)
  const data = useSelector(state => state.cms.allSections[pageName]);
  // console.log(getPlanData, 'getPlanData')

  const [planData, setPlanData] = useState(null);
  const [plan2Data, setPlan2Data] = useState(null);
  const [isMonthly, setIsMonthly] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    value: 'IN',
    label: 'India',
  });

  useEffect(() => {
    axios
      .post('https://cera-api.brained.in/api/masters/plansNew/get')
      .then(response => {
        setPlanData(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .post('https://cera-api.brained.in/api/masters/clientPartnerPlans/get')
      .then(response => {
        let duration = !isMonthly ? 'Monthly' : 'Yearly';
        let filterPlanData = response.data.data.map(plan => {
          let planData = plan.plans.filter(
            pf =>
              pf.duration === duration && pf.location === selectedLocation.value
          );

          plan.plans = planData;
          return plan;
        });
        setPlan2Data(filterPlanData);
        console.log(filterPlanData, 'helloyash');
      })
      .catch(error => {
        console.log(error);
      });
  }, [selectedLocation, isMonthly]);

  console.log(planData, 'planData');
  console.log(plan2Data, 'plan2Data');

  const countryPlans = [
    { value: 'IN', label: 'India' },
    { value: 'SG', label: 'Singapore' },
    { value: 'USD', label: 'USA' },
  ];

  const handleLocation = e => {
    setSelectedLocation(e);
  };
  const handlePrizingPlan = () => {
    setIsMonthly(state => !state);
    console.log(isMonthly, 'isMonthly');
  };

  return (
    <section className="lg:py-16 md:py-12 py-8">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <h5
          id="3799553203"
          className="dynamicStyle fsize40 md-fsize28 sm-fsize22   text-gray text-center textColor-light"
        >
          {data ? data['3799553203'] : 'Lorem Ipsum'}
        </h5>
        <p
          id="1862072124"
          dangerouslySetInnerHTML={{
            __html: data ? data['1862072124'] : 'Lorem ipsum generated',
          }}
          className="dynamicStyle fsize34 md-fsize22 sm-fsize14 mt-2 font-semibold text-center cust-textColor"
        ></p>
        <div className="lg:flex md:flex lg:mt-20 md:mt-8 mt-4">
          <div className="lg:w-1/2 md:w-1/2 lg:pr-6 md:pr-3">
            <div className="border-ec flex items-center gap-2 w-max p-1 rounded-full">
              <p
                onClick={() => setcheck(1)}
                className={
                  check === 1
                    ? 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold bg-primary cust-bgColor text-white rounded-full tab-width py-2'
                    : 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold text-gray rounded-full tab-width py-2'
                }
                id="6588501380"
              >
                {data ? data['6588501380'] : 'Lorem Ipsum'}
              </p>
              <p
                onClick={() => setcheck(2)}
                className={
                  check === 2
                    ? 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold bg-primary cust-bgColor text-white rounded-full tab-width py-2'
                    : 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold text-gray rounded-full tab-width py-2'
                }
                id="6190389616"
              >
                {data ? data['6190389616'] : 'Lorem Ipsum'}
              </p>
              <p
                onClick={() => setcheck(3)}
                className={
                  check === 3
                    ? 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold bg-primary cust-bgColor text-white rounded-full tab-width py-2'
                    : 'dynamicStyle fsize16 md-fsize14 sm-fsize13 cursor-pointer font-semibold text-gray rounded-full tab-width py-2'
                }
                id="3452441594"
              >
                {data ? data['3452441594'] : 'Lorem Ipsum'}
              </p>
            </div>
            <div className="px-2 py-6">
              {check === 1 ? (
                <>
                  <div>
                    <div className="pb-3 border-dash">
                      <h5
                        id="4346891236"
                        className="dynamicStyle fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor"
                      >
                        {data ? data['4346891236'] : 'Free Plan'}
                      </h5>
                      <p
                        id="9649872610"
                        className="dynamicStyle fsize16 md-fsize14 sm-fsize14 lg:pb-1 text-dark textColor-light"
                      >
                        {data ? data['9649872610'] : 'Get a feel for Tomango.'}
                      </p>
                    </div>
                    <div
                      id="3240882542"
                      className="dynamicStyle lg:mt-8 md:mt-6 mt-4 grid grid-cols-1 gap-4"
                    >
                      {data && data['3240882542']
                        ? data['3240882542'].map((e, i) => {
                            return (
                              <div className="flex items-center gap-2">
                                <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                                <p className="fsize14 font-medium text-dark textColor-light">
                                  {e.field1}
                                </p>
                              </div>
                            );
                          })
                        : 'no-data'}
                      {/* <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Courses and certficates
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Privacy Take Down
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">Community Feed</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">Custom domain link</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">
                          Landing Page builder
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">
                          Zoom Premium Metting (2 Year's subscription)
                        </p>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : null}
              {check === 2 ? (
                <>
                  <div>
                    <div className="pb-3 border-dash">
                      <h5
                        id="8826906059"
                        className="dynamicStyle fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor"
                      >
                        {data ? data['8826906059'] : 'Fredom Plan'}
                      </h5>
                      <p
                        id="7730369186"
                        className="dynamicStyle fsize16 sm-fsize14 pb-1 text-dark textColor-light"
                      >
                        {data
                          ? data['7730369186']
                          : 'Starting out? Unlock freedom with this plan.'}
                      </p>
                      {/* <div className="flex items-end gap-2">
                        <h5 className="fsize28 text-dark md-fsize20 sm-fsize20 items-start cust-textColor flex gap-1 fsize20 font-semibold">
                          <span className="fsize13 mt-1">₹</span>25,000
                        </h5>
                        <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                          /Year
                        </p>
                      </div> */}
                    </div>
                    <div
                      id="6563977819"
                      className="lg:mt-8 md:mt-6 mt-4 grid grid-cols-1 gap-4"
                    >
                      {data && data['6563977819']
                        ? data['6563977819'].map((e, i) => {
                            return (
                              <div className="flex items-center gap-2">
                                <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                                <p className="fsize14 font-medium text-black">
                                  {e.field1}
                                </p>
                              </div>
                            );
                          })
                        : 'no-data'}
                      {/* <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-primary">
                          Courses and certficates
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Privacy Take Down
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Privacy Take Down
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">Community Feed</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">Custom domain link</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">
                          Landing Page builder
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 text-gray">
                          Zoom Premium Metting (2 Year's subscription)
                        </p>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : null}
              {check === 3 ? (
                <>
                  <div>
                    <div className="pb-3 border-dash">
                      <h5
                        id="2554565572"
                        className="dynamicStyle fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor"
                      >
                        {data ? data['2554565572'] : 'Enterprise Plan'}
                      </h5>
                      <p
                        id="4798886763"
                        className="dynamicStyle fsize16 sm-fsize14 pb-1 text-dark textColor-light"
                      >
                        {data
                          ? data['4798886763']
                          : 'Recommended for already monetizing businesses'}
                      </p>
                    </div>
                    <div className="lg:mt-8 md:mt-6 mt-4 grid grid-cols-1 gap-4">
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-primary">
                          Landing Page builder
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-primary">
                          Courses and certficates
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-primary">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-primary">
                          Privacy Take Down
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Privacy Take Down
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Community chat room
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                        <p className="fsize14 font-medium text-dark textColor-light">
                          Privacy Take Down
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:pl-6 md:pl-3">
            <div className="px-2">
              {check === 1 ? (
                <>
                  <div>
                    <h4
                      id="3531718137"
                      className="dynamicStyle fsize18 md-fsize16 sm-fsize16 text-dark font-semibold cust-textColor"
                    >
                      {data ? data['3531718137'] : 'Lorem Ipsum'}
                    </h4>
                    <p
                      id="7329725952"
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data['7329725952']
                          : 'Lorem ipsum generated',
                      }}
                      className="dynamicStyle fsize16 md-fsize14 sm-fsize14 mt-1 text-gray textColor-light"
                    ></p>
                    <div className="grid grid-cols-1 gap-5 mt-6">
                      {/* {planData && planData.length > 0
                        ? planData.map(e => {
                            return (
                              <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                                <div className="flex pb-4 border-bottom justify-between">
                                  <div className="">
                                    <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                                      {e.name}
                                    </p>
                                    <div className="flex items-end gap-2 mt-1">
                                      <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                        10%
                                      </h5>
                                      <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                        on commision.
                                      </p>
                                    </div>
                                    <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                                      {e.description}
                                    </p>
                                  </div>
                                  <Button
                                    // colorScheme="primary"
                                    borderRadius="50"
                                    className="md-fsize12 sm-fsize12 btn-translate"
                                  >
                                    Sign Up
                                  </Button>
                                </div>
                                <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                                  {e.features.map(item => {
                                    return (
                                      <>
                                      {
                                        item.type === 'inclusion' && <div className="flex items-center gap-2">
                                        <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                                        <p className="fsize14 font-medium text-dark textColor-light">
                                          {item.text}
                                        </p>
                                      </div>
                                      }
                                      {
                                        item.type === '"exclusion"' && <div className="flex items-center gap-2">
                                        <div className="bg-ec w-3 h-3 rotate-dot"></div>
                                        <p className="fsize14 font-medium text-dark textColor-light">
                                          {item.text}
                                        </p>
                                      </div>
                                      }
                                      
                                      </>
                                    );
                                  })}
                                  
                                </div>
                              </div>
                            );
                          })
                        : 'no-data'} */}
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              Basic
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                10%
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                on commision.
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                              (No Monthly Subscription)
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Sign Up
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cursor-not-allowed">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-gray">
                              PRO
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-gray md-fsize20 sm-fsize20 items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>5,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-gray">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-gray">
                              + 5% commission
                            </p>
                          </div>
                          <Button
                            colorScheme="gray"
                            color="gray"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12"
                          >
                            Contact Us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Landing Page builder
                            </p>
                          </div>

                          {/* <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Privacy Take Down
                            </p>
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cursor-not-allowed">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-gray">
                              ADVANCE
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-gray md-fsize20 sm-fsize20 items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>15,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-gray">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-gray">
                              + 2.5% commission
                            </p>
                          </div>
                          <Button
                            colorScheme="gray"
                            color="gray"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12"
                          >
                            Contact Us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-ec cust-bgColor w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 text-gray">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <p className="fsize16 sm-fsize14 text-dark textColor-light">
                        (Exclusive of payment gateway and whatsapp charges.)
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
              {check === 2 ? (
                <>
                  <div>
                    <h4
                      id="6254078409"
                      className="dynamicStyle fsize18 md-fsize16 sm-fsize16 text-dark font-semibold cust-textColor"
                    >
                      {data ? data['6254078409'] : 'Platform charges:'}
                    </h4>
                    <p
                      id="8818547750"
                      className="dynamicStyle fsize16 md-fsize14 sm-fsize14 mt-1 text-dark textColor-light"
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data['8818547750']
                          : 'Lorem ipsum generated',
                      }}
                    ></p>
                    <div className="flex items-center gap-2 mt-4">
                      <p className="fsize14 text-dark cust-textColor">
                        Monthly
                      </p>
                      <Switch
                        size="sm"
                        colorScheme={colorMode === 'light' ? 'primary' : 'gray'}
                      />
                      <p className="fsize14 text-dark textColor-light">
                        Annually
                      </p>
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-4">
                      {planData && planData.length > 0
                        ? planData.map(e => {
                            return (
                              <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                                <div className="flex pb-4 border-bottom justify-between">
                                  <div className="">
                                    <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                                      {e.name}
                                    </p>
                                    <div className="flex items-end gap-2 mt-1">
                                      {e.plans.map(data => {
                                        return (
                                          <>
                                            {data.duration === 'Monthly' && (
                                              <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                                ${data.price}
                                              </h5>
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                    <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                                      {e.description}
                                    </p>
                                  </div>
                                  <Button
                                    // colorScheme="primary"
                                    borderRadius="50"
                                    className="md-fsize12 sm-fsize12 btn-translate"
                                  >
                                    Sign Up
                                  </Button>
                                </div>
                                <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                                  {e.features.map(item => {
                                    return (
                                      <>
                                        {item.type === 'inclusion' && (
                                          <div className="flex items-center gap-2">
                                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                                            <p className="fsize14 font-medium text-dark textColor-light">
                                              {item.text}
                                            </p>
                                          </div>
                                        )}
                                        {item.type === '"exclusion"' && (
                                          <div className="flex items-center gap-2">
                                            <div className="bg-ec w-3 h-3 rotate-dot"></div>
                                            <p className="fsize14 font-medium text-dark textColor-light">
                                              {item.text}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })
                        : 'no-data'}
                      {/* <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              Basic
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                10%
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                on commision.
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                              (No Monthly Subscription)
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              PRO
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-dark md-fsize20 cust-textColor sm-fsize20 items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>5,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-dark textColor-light">
                              + 5% commission
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact Us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-primary">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          
                          
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              ADVANCE
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-dark md-fsize20 sm-fsize20 cust-textColor items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>15,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-dark textColor-light">
                              + 2.5% commission
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <p className="fsize16 sm-fsize14 text-dark textColor-light">
                        (Exclusive of payment gateway and whatsapp charges.)
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
              {check === 3 ? (
                <>
                  <div>
                    <h4
                      id="4260260904"
                      className="dynamicStyle fsize18 md-fsize16 sm-fsize16 text-dark font-semibold cust-textColor"
                    >
                      {data ? data['4260260904'] : 'Platform charges:'}
                    </h4>
                    <p
                      id="8383329839"
                      className="dynamicStyle fsize16 md-fsize14 sm-fsize14 mt-1 text-dark textColor-light"
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data['8383329839']
                          : 'Lorem ipsum generated',
                      }}
                    ></p>
                    <div className="w-full flex justify-between items-center gap-2 mt-4">
                      <div className="w-7/12 flex items-center gap-2">
                        <p className="fsize14 text-dark cust-textColor">
                          Monthly
                        </p>
                        <Switch
                          isChecked={isMonthly}
                          onChange={handlePrizingPlan}
                          size="sm"
                          id="isMonthly"
                          colorScheme={
                            colorMode === 'light' ? 'primary' : 'gray'
                          }
                        />
                        <p className="fsize14 text-dark textColor-light">
                          Annually
                        </p>
                      </div>
                      <Select
                        value={selectedLocation}
                        onChange={e => handleLocation(e)}
                        className="w-3/12 fsize12 h-30px"
                        options={countryPlans}
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-5 mt-4">
                      {plan2Data && plan2Data.length > 0
                        ? plan2Data.map((e, index) => {
                            return (
                              <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                                <div className="flex pb-4 border-bottom justify-between">
                                  <div className="">
                                    <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                                      {e.name}
                                    </p>
                                    <div className="flex items-end gap-2 mt-1">
                                      {e.plans.map(data => {
                                        return (
                                          <>
                                            <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                              {data.currency} {data.price}
                                            </h5>
                                          </>
                                        );
                                      })}
                                    </div>
                                    <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                                      {e.description}
                                    </p>
                                  </div>
                                  <Button
                                    // colorScheme="primary"
                                    borderRadius="50"
                                    className="md-fsize12 sm-fsize12 btn-translate"
                                  >
                                    Sign Up
                                  </Button>
                                </div>
                                <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                                  {e.features.map(item => {
                                    return (
                                      <>
                                        {item.type === 'inclusion' && (
                                          <div className="flex items-center gap-2">
                                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                                            <p className="fsize14 font-medium text-dark textColor-light">
                                              {item.text}
                                            </p>
                                          </div>
                                        )}
                                        {item.type === '"exclusion"' && (
                                          <div className="flex items-center gap-2">
                                            <div className="bg-ec w-3 h-3 rotate-dot"></div>
                                            <p className="fsize14 font-medium text-dark textColor-light">
                                              {item.text}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                  {/* <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div> */}
                                </div>
                              </div>
                            );
                          })
                        : 'no-data'}
                      {/* <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              Basic
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 md-fsize20 sm-fsize20 font-semibold cust-textColor">
                                10%
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                on commision.
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 text-dark textColor-light">
                              (No Monthly Subscription)
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              PRO
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-dark cust-textColor md-fsize20 sm-fsize20 items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>5,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-dark textColor-light">
                              + 5% commission
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact Us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-primary">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="lg:p-8 md:p-4 p-4 rounded-2xl border-ec cust-bgColor">
                        <div className="flex pb-4 border-bottom justify-between">
                          <div className="">
                            <p className="fsize18 md-fsize16 sm-fsize14 font-semibold text-dark textColor-light">
                              ADVANCE
                            </p>
                            <div className="flex items-end gap-2 mt-1">
                              <h5 className="fsize28 text-dark cust-textColor md-fsize20 sm-fsize20 items-start flex gap-1 fsize20 font-semibold">
                                <span className="fsize13 mt-1">₹</span>15,000
                              </h5>
                              <p className="fsize16 sm-fsize14 pb-1 text-dark textColor-light">
                                /month
                              </p>
                            </div>
                            <p className="fsize14 mt-2 md-fsize13 sm-fsize13 font-semibold text-dark textColor-light">
                              + 2.5% commission
                            </p>
                          </div>
                          <Button
                            // colorScheme="primary"
                            variant="outline"
                            borderRadius="50"
                            className="md-fsize12 sm-fsize12 btn-translate"
                          >
                            Contact us
                          </Button>
                        </div>
                        <div className="lg:mt-6 md:mt-4 mt-2 grid grid-cols-1 gap-4">
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Landing Page builder
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Courses and certficates
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Community chat room
                            </p>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="bg-primary  w-3 h-3 rotate-dot"></div>
                            <p className="fsize14 font-medium text-dark textColor-light">
                              Privacy Take Down
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <p className="fsize16 sm-fsize14 text-dark textColor-light">
                        (Exclusive of payment gateway and whatsapp charges.)
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPlan7;
