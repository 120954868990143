import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "./fadeeffect/FadeEffect";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

const NewsletterSection14 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Newsletter",
        //   text: `Subscribed to newsletter`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Subscribed to newsletter`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="bg-fff">
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-12 px-5 ">
        <div className="lg:w-3/5 md:w-3/5 w-full mx-auto text-center">
          <p
            id="9443669284"
            className="dynamicStyle fsize32 md-fsize28 sm-fsize20 pb-2 font-semibold cust-textColor"
          >
            {data ? data["9443669284"] : "Lorem Ipsum"}
          </p>
          <p
            id="6581398084"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["6581398084"]
                : "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
            }}
            className={
              colorMode === "light"
                ? "dynamicStyle sm-fsize14 text-gray"
                : "dynamicStyle textColor-light sm-fsize14"
            }
          ></p>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-2 mt-4">
              <div className="w-9/12">
                <FormControl
                  isInvalid={!!formik.errors.email && formik.touched.email}
                >
                  <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    size="lg"
                    placeholder="Email"
                    className="w-full bdr-0px theme-border bdr-0px bg-fff sm-fsize14 cust-bgColor cust-textColor theme-border theme-bg"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormErrorMessage mt={1}>
                      {formik.errors.email}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>

              <Button
                size="lg"
                type="submit"
                className={
                  colorMode === "light"
                    ? "dynamicStyle w-3/12 bdr-0px bg-primary theme-btn sm-fsize14 md-fsize14 clr-fff"
                    : "dynamicStyle w-3/12 bdr-0px cust-bgColor cust-textColor theme-btn sm-fsize14 md-fsize14 clr-fff"
                }
                id="1825620230"
              >
                {/* <span onClick={() => handleClick("1825620230")}> */}
                {data ? data["1825620230"] : "Submit"}
                {/* </span> */}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection14;
