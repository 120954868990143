import { Button, Image, Progress, useColorMode } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import ImageGallery from 'react-image-gallery';

const ProductDetails5 = () => {
  const images = [
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto theme-bg lg:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className="flex gap-6">
        <div className="flex gap-4 w-1/2">
          <div className="w-10per">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-16 mb-4"
            />
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-16 mb-4"
            />
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-16 mb-4"
            />
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-20 w-16 mb-4"
            />
          </div>
          <div className="w-90per">
            {/* <img
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="h-550px w-full"
            /> */}
            <ImageGallery
              items={images}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div>
            <p className="cust-textColor">Lorem</p>
            <div
              className={
                colorMode === 'light'
                  ? 'flex justify-between items-center'
                  : 'flex justify-between items-center textColor-light'
              }
            >
              <p className="fsize28 font-semibold">Lorem Ipsum</p>
              <FeatherIcon
                icon="star"
                className="cursor-pointer p-1 bdr-gray-1px rounded-full"
                size={26}
              />
            </div>
            <div className="flex gap-2 pb-4 textColor-light">
              <FeatherIcon icon="star" className="cursor-pointer" size={18} />
              <FeatherIcon icon="star" className="cursor-pointer" size={18} />
              <FeatherIcon icon="star" className="cursor-pointer" size={18} />
              <FeatherIcon icon="star" className="cursor-pointer" size={18} />
              <FeatherIcon icon="star" className="cursor-pointer" size={18} />
            </div>
            <div className="flex items-center gap-2">
              <p className="fsize26 font-semibold cust-textColor">$39.00</p>
              <p
                className={
                  colorMode === 'light'
                    ? 'text-gray strikethrough'
                    : 'text-gray strikethrough textColor-light'
                }
              >
                $59.00
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'bg-red-800 clr-fff fsize12 px-2 rounded-full theme-textColor theme-bg theme_insertShadow'
                    : 'cust-bgColor clr-fff fsize12 px-2 rounded-full cust-textColor theme-textColor theme-bg theme_insertShadow'
                }
              >
                save 50%
              </p>
            </div>
            <div className="flex gap-2 pt-4">
              <FeatherIcon icon="eye" className="textColor-light" size={20} />
              <p className="text-gray textColor-light">
                24 people are viewing this right now
              </p>
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'flex justify-between rounded-sm bg-red-100 gap-2 my-6 py-2 px-4 theme-bg themeShadow'
                  : 'flex justify-between rounded-sm cust-bgColor gap-2 my-6 py-2 px-4 theme-bg themeShadow'
              }
            >
              <p
                className={
                  colorMode === 'light'
                    ? 'text-red-800 font-semibold theme-textColor'
                    : 'font-semibold cust-textColor theme-textColor'
                }
              >
                Hurry up sale ends in:
              </p>
              <p
                className={
                  colorMode === 'light'
                    ? 'text-red-800 font-semibold theme-textColor'
                    : 'font-semibold cust-textColor theme-textColor'
                }
              >
                00 : 05 : : 45 : 54
              </p>
            </div>
            <div className="mb-6">
              <p className="mb-2 cust-textColor">Only 9 left in stock</p>
              <Progress value={20} size="xs" colorScheme="red" />
            </div>
            <div className="">
              <p className="mb-2 font-semibold cust-textColor">Size: M</p>
              <div className="flex gap-2">
                <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light font-semibold ">
                  S
                </p>
                <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px bg-black clr-fff textColor-light font-semibold">
                  M
                </p>
                <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light font-semibold">
                  L
                </p>
                <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light font-semibold">
                  XL
                </p>
                <p className="py-2 px-4 fsize14 rounded-md bdr-d5d2d2-1px text-gray textColor-light font-semibold">
                  XXL
                </p>
              </div>
            </div>
            <div className="my-4">
              <p className="font-semibold pb-2 cust-textColor">Colors</p>
              <div className="flex gap-2">
                <div className="bg-black h-7 w-7 rounded-full"></div>
                <div className="bg-red-800 h-7 w-7 rounded-full"></div>
              </div>
            </div>
            <div className="my-4">
              <p className="font-semibold pb-2 cust-textColor">Quantity</p>
              <div className="flex gap-4 mb-8">
                <div className="flex items-center gap-6 bdr-gray-1px py-2 px-4 rounded-md w-1/5 ">
                  <FeatherIcon
                    icon="minus"
                    className="textColor-light"
                    size={18}
                  />
                  <p className="cust-textColor">2</p>
                  <FeatherIcon
                    icon="plus"
                    className="textColor-light"
                    size={18}
                  />
                </div>
                <div className="w-4/5">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    className={
                      colorMode === 'light'
                        ? 'sm-fsize14 font-semibold w-full lg:py-6 sm-py-6 theme-btn2 theme-border-0'
                        : 'cust-textColor sm-fsize14 font-semibold w-full lg:py-6 sm-py-6 theme-btn2 theme-border-0'
                    }
                  >
                    Add to cart
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex gap-6 cust-textColor">
              <div className="flex gap-2">
                <div className="flex items-center">
                  <FeatherIcon icon="arrow-up" className="" size={20} />
                  <FeatherIcon icon="arrow-down" className="" size={20} />
                </div>
                <p className="font-semibold">Compare</p>
              </div>
              <div className="flex gap-2">
                <FeatherIcon icon="help-circle" className="" size={20} />

                <p className="font-semibold">Ask a Question</p>
              </div>
              <div className="flex gap-2">
                <FeatherIcon icon="share-2" className="" size={20} />

                <p className="font-semibold">share</p>
              </div>
            </div>
            <hr className="bdr-bottom my-3" />
            <div className="flex gap-2 mt-8">
              <FeatherIcon icon="truck" className="cust-textColor" size={20} />

              <p className="textColor-light">
                <span className="font-semibold cust-textColor">
                  Estimated delivery
                </span>{' '}
                jul 30 - Aug 03{' '}
              </p>
            </div>
            <div className="flex gap-2 mt-2">
              <FeatherIcon
                icon="calendar"
                className="cust-textColor"
                size={20}
              />

              <p className="textColor-light">
                <span className="font-semibold cust-textColor">
                  Free Shipping & Returns:{' '}
                </span>{' '}
                on all orders over $75{' '}
              </p>
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'py-4 px-6 mt-8 bg-gray-100 rounded-lg theme-bg themeShadow'
                  : 'py-4 px-6 mt-8 cust-bgColor rounded-lg theme-bg themeShadow'
              }
            >
              <div className="flex gap-2 justify-center">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-10 w-14 mb-4"
                />
              </div>
              <p className="fsize18 font-semibold text-center cust-textColor">
                Guarantee safe and secure checkout
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails5;
