import React, { useEffect, useRef, useState } from 'react';
import { Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const DownloadSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-12 px-5">
      <div className="lg:flex md:flex gap-10">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-10">
          <h2
            id="8306110480"
            className="dynamicStyle mb-2 fsize34 md-fsize24 sm-fsize20 font-semibold text-black cust-textColor"
          >
            {data ? data['8306110480'] : 'Lorem Ipsum'}
          </h2>
          <p
            id="4221517875"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['4221517875']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className="dynamicStyle text-black fsize14 md-fsize14 sm-fsize14 textColor-light"
          ></p>
          <div className="lg:mt-6 md:mt-6 mt-4 flex gap-4 items-center">
            <Button
              px={8}
              className="dynamicStyle bdr-2px theme-btn bg-secondary clr-fff sm-fsize14"
              id="7936009163"
            >
              <span onClick={() => handleClick('7936009163')}>
                {data ? data['7936009163'] : 'Submit'}
              </span>
            </Button>
            <p
              id="8361098483"
              className="dynamicStyle fsize14 font-medium cust-textColor cursor-pointer text-black"
            >
              {data ? data['8361098483'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <h2
            id="0622370334"
            className="dynamicStyle mb-2 fsize34 md-fsize24 sm-fsize20 font-semibold text-black cust-textColor"
          >
            {data ? data['0622370334'] : 'Lorem Ipsum'}
          </h2>
          <p
            id="0743696124"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['0743696124']
                : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            }}
            className="dynamicStyle text-black fsize14 md-fsize14 sm-fsize14 textColor-light"
          ></p>
          <div className="lg:mt-6 md:mt-6 mt-4 mb-6 flex gap-4 items-center">
            <Button
              px={8}
              className="dynamicStyle bdr-2px theme-btn bg-secondary clr-fff sm-fsize14"
              id="0239507018"
            >
              <span onClick={() => handleClick('0239507018')}>
                {data ? data['0239507018'] : 'Submit'}
              </span>
            </Button>
            <p
              id="2626330557"
              className="dynamicStyle fsize14 font-medium cust-textColor cursor-pointer text-black"
            >
              {data ? data['2626330557'] : 'Lorem Ipsum'}
            </p>
          </div>

          <p
            id="7141222080"
            className="dynamicStyle mb-4 text-black textColor-light"
          >
            {data ? data['7141222080'] : 'Lorem Ipsum'}
          </p>
          <p
            id="8199201431"
            className="dynamicStyle mb-4 text-black textColor-light"
          >
            {data ? data['8199201431'] : 'Lorem Ipsum'}
          </p>
          <p
            id="3056310241"
            className="dynamicStyle mb-4 text-black textColor-light"
          >
            {data ? data['3056310241'] : 'Lorem Ipsum'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadSection3;
